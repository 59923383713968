import StaticPageLayout from '../../components/layouts/StaticPageLayout';
import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../store/store';
import GeneralError from '../../components/errors/GeneralError';
import GeneralLoader from '../../components/loaders/GeneralLoader';
import NoResults from './components/NoResults';
import WatchlistCompanyResult from './components/WatchlistCompanyResult';
import useHistory from '../../hooks/useHistory';
import { watchlistPagesize, decodeWatchlistParams, encodeWatchlistParams } from './constants';
import { paths } from '../../utils/constants/routes';
import ReactPaginate from 'react-paginate';
import { useEffect } from 'react';
import { observable, runInAction } from 'mobx';
import CompanyDetails from '../search/ProfilerResults/components/CompanyDetails';
import IconButton, { ICON_BUTTON_TYPES } from '../../components/buttons/IconButton';

const savedState = observable({
  savedScrollY: 0,
  saveScrollY: () => {
    savedState.savedScrollY = window.scrollY;
  }
});

const ProfilerWatchList = observer(() => {
  const { profilerProjectStore, utilsStore } = useStore();
  const { location, navigate } = useHistory();
  const state = useLocalObservable(() => ({
    isRendered: true,
    setIsRendered: (isRendered = false) => (state.isRendered = isRendered),
    params: {},
    setParams: (params = {}) => {
      state.params = params;
    },
    get layout() {
      return utilsStore.windowWidth < 481 ? 'mobile' : 'desktop';
    },
    detailsDisplayed: null,
    displayDetails: (entry, isUnmount = false) => {
      if (isUnmount) {
        runInAction(() => (savedState.savedScrollY = 0));
        return;
      }

      if (entry) {
        savedState.saveScrollY();
      } else {
        setTimeout(() => {
          const savedScrollY = savedState.savedScrollY || 0;
          window.scrollTo({ top: savedScrollY });
          runInAction(() => (savedState.savedScrollY = 0));
        }, 20);
      }
      state.detailsDisplayed = entry;
    },
    isAddRemoveWatchlistPopupOpen: null,
    setIsAddRemoveWatchlistPopupOpen: (company = null) => {
      const open = !state.isAddRemoveWatchlistPopupOpen;
      state.isAddRemoveWatchlistPopupOpen = state.isAddRemoveWatchlistPopupOpen ? null : company;
      utilsStore.lockScroll(open);
    }
  }));

  useEffect(() => {
    return () => {
      state.setIsRendered();
    };
  }, [state]);

  useEffect(() => {
    return () => {
      if (state.detailsDisplayed) {
        state.displayDetails(null, true);
      }
      if (state.isAddRemoveWatchlistPopupOpen) {
        state.setIsAddRemoveWatchlistPopupOpen();
      }
    };
  }, [state]);

  useEffect(() => {
    if (profilerProjectStore.isLoadingWatchlist || profilerProjectStore.initialLoadWatchlist) {
      return;
    }

    const newParams = {};
    let [urlParams, params, shouldNavigate] = [null, {}, false];

    try {
      urlParams = new URLSearchParams(location.search);
      params = decodeWatchlistParams(urlParams.get('params'));
    } catch (ex) {
      shouldNavigate = true;
    }

    if (typeof params !== 'object' || params === null || params === undefined) {
      params = {};
    }

    let listPages = Math.ceil(profilerProjectStore.watchlist.length / watchlistPagesize);
    if (listPages === 0) {
      listPages = 1;
    }

    if (isNaN(params.page) || params.page > listPages || params.page < 1) {
      if (isNaN(params.page) || params.page < 1) {
        newParams.page = 1;
      } else if (params.page > listPages) {
        newParams.page = listPages;
      }
      shouldNavigate = true;
    }

    if (shouldNavigate) {
      navigate(
        paths.PROFILER_WATCH_LIST + '?params=' + encodeWatchlistParams({ ...params, ...newParams })
      );
    }

    state.setParams({ ...params, ...newParams });
  }, [
    state,
    profilerProjectStore,
    profilerProjectStore.watchlist,
    profilerProjectStore.watchlist.length,
    profilerProjectStore.isLoadingWatchlist,
    location.search
  ]);

  const showError =
    profilerProjectStore.loadWatchlistError && !profilerProjectStore.watchlist.length;

  if (showError) {
    return (
      <StaticPageLayout page="profiler-watch-list" hideMobileFooter>
        {showError && (
          <GeneralError
            errorMessage={profilerProjectStore.loadWatchlistError}
            actionMessage="You may want to:"
            withHomePageButton={true}
            actionButtontext="Try again"
            onActionButtonClick={profilerProjectStore.loadWatchlist}
            withLogoutButton
          />
        )}
      </StaticPageLayout>
    );
  }

  if (
    profilerProjectStore.initialLoadWatchlist ||
    (profilerProjectStore.isLoadingWatchlist && !profilerProjectStore.watchlist.length)
  ) {
    return (
      <StaticPageLayout page="profiler-watch-list" hideMobileFooter>
        <GeneralLoader />
      </StaticPageLayout>
    );
  }

  const showNoResults = !profilerProjectStore.watchlist.length;
  const showResults = !showNoResults;
  const showPagination = profilerProjectStore.watchlist.length > watchlistPagesize;
  const pages = Math.ceil(profilerProjectStore.watchlist.length / watchlistPagesize) || 1;
  const page = (state.params.page || 1) - 1;

  const companyToRemove = state.isAddRemoveWatchlistPopupOpen;

  if (state.detailsDisplayed) {
    return (
      <StaticPageLayout page="profiler-watch-list" hideMobileFooter>
        <CompanyDetails
          company={state.detailsDisplayed}
          displayDetails={state.displayDetails}
          isFromWatchList
          // style={state.layout === 'desktop' ? { marginTop: '-40px' } : {}}
        />
      </StaticPageLayout>
    );
  }

  return (
    <>
      {!!state.isAddRemoveWatchlistPopupOpen && (
        <>
          <div
            className="add-remove-watchlist-popup-closer"
            onClick={() => state.setIsAddRemoveWatchlistPopupOpen()}
          />
          <div className="add-remove-watchlist-popup">
            <div className="title">{`Are you sure you want to remove ${companyToRemove.name} from watchlist?`}</div>
            <div className="buttons-container">
              <IconButton
                innerText="Cancel"
                onClick={() => state.setIsAddRemoveWatchlistPopupOpen()}
              />
              <IconButton
                innerText="Remove from watchlist"
                type={ICON_BUTTON_TYPES.RED}
                filled
                onClick={() => {
                  state.setIsAddRemoveWatchlistPopupOpen();
                  profilerProjectStore.addToWatchlist(companyToRemove);
                }}
              />
            </div>
          </div>
        </>
      )}
      <StaticPageLayout page="profiler-watch-list" hideMobileFooter>
        <div className="watchlist-title">My watchlist</div>
        <div className="listing-layout">
          <div className="results">
            <div className="results-header">
              <div className="cols">
                <div className="col company-name">Company name</div>
                <div className="col">Company HQ</div>
                <div className="col">Industry</div>
                <div className="col">Value chain</div>
                <div className="col">Products</div>
                <div className="col right-align company-revenue">Revenue (€M)</div>
                <div className="col right-align company-employees">Employees</div>
              </div>
              <div className="actions watchlist" />
            </div>

            {showNoResults && <NoResults />}

            {showResults &&
              profilerProjectStore.watchlist
                .slice(page * watchlistPagesize, (page + 1) * watchlistPagesize)
                .map((company) => (
                  <WatchlistCompanyResult
                    key={company.id}
                    company={company}
                    displayDetails={state.displayDetails}
                    setIsAddRemoveWatchlistPopupOpen={state.setIsAddRemoveWatchlistPopupOpen}
                  />
                ))}

            {showPagination && (
              <ReactPaginate
                onPageChange={({ selected }) => {
                  navigate(
                    paths.PROFILER_WATCH_LIST +
                      '?params=' +
                      encodeWatchlistParams({ ...state.params, page: selected + 1 })
                  );
                  setTimeout(() => {
                    if (state.layout === 'mobile') {
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                      return;
                    }

                    window.scrollTo({ top: 24 + 23, behavior: 'smooth' });
                  }, 100);
                }}
                forcePage={page}
                pageCount={pages}
                pageRangeDisplayed={state.layout === 'mobile' ? 1 : watchlistPagesize}
                marginPagesDisplayed={state.layout === 'mobile' ? 1 : watchlistPagesize}
                renderOnZeroPageCount={null}
                breakLabel="..."
                nextLabel="Next"
                previousLabel="Prev"
                containerClassName="pagination-container"
                pageClassName="page"
                pageLinkClassName="pageLink"
                nextClassName="next"
                nextLinkClassName="nextLink"
                previousClassName="prev"
                previousLinkClassName="prevLink"
                breakClassName="break"
                breakLinkClassName="breakLink"
              />
            )}
          </div>
        </div>
      </StaticPageLayout>
    </>
  );
});

export default ProfilerWatchList;
