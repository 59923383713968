import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../store/store';
import { ICON_BUTTON_ICONS } from '../buttons/IconButton';
import { useEffect } from 'react';

const ConnectionError = observer(() => {
  const { webSocketStore } = useStore();
  const state = useLocalObservable(() => ({
    showDisconnected: false,
    showReconnected: false,
    refreshConnectionStatusDisplay: () => {
      const connected = webSocketStore.connected;

      let [showDisconnected, showReconnected] = [false, false];

      if (connected && webSocketStore.initialDisconnect) {
        showReconnected = true;
      }

      if (!connected && webSocketStore.initialConect) {
        showDisconnected = true;
      }

      [state.showDisconnected, state.showReconnected] = [showDisconnected, showReconnected];
    }
  }));

  useEffect(() => {
    state.refreshConnectionStatusDisplay();
  }, [state, webSocketStore.connected]);

  if (state.showDisconnected) {
    return (
      <div className="connection-error-disconnected" key="connection-error-disconnected">
        <img src={ICON_BUTTON_ICONS.CLOUD_SLASH} />
        <div className="connection-message">Internet connection lost.</div>
      </div>
    );
  }

  if (state.showReconnected) {
    return (
      <div className="connection-error-reconnected" key="connection-error-reconnected">
        <img src={ICON_BUTTON_ICONS.CLOUD_CHECK} />
        <div className="connection-message">Internet connection re-established</div>
      </div>
    );
  }

  return (
    <div className="connection-error-icon-preloader">
      <img src={ICON_BUTTON_ICONS.CLOUD_SLASH} />
      <img src={ICON_BUTTON_ICONS.CLOUD_CHECK} />
    </div>
  );
});

export default ConnectionError;
