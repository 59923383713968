import { observer } from 'mobx-react';
import { Fragment, useEffect } from 'react';
import useHistory from '../../../../hooks/useHistory';

const SearchFormMobileSection = observer(({ section = null, onBack = null, fields = {} }) => {
  const { navigate, location } = useHistory();

  useEffect(() => {
    navigate(location.pathname + location.search);
    addEventListener('popstate', onBack);
    return () => {
      removeEventListener('popstate', onBack);
    };
  }, []);

  return (
    <div>
      <button className="backlink" onClick={() => navigate(-1)}>
        Search
      </button>
      <div className="mobile-fields">
        {Object.values(fields[section]).map((field, idx) => (
          <Fragment key={idx}>{field}</Fragment>
        ))}
      </div>
      <div className="mobile-fields-mandatory-row">*mandatory fields</div>
    </div>
  );
});

export default SearchFormMobileSection;
