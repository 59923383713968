import { observer } from 'mobx-react';
import IconButton, {
  ICON_BUTTON_ICONS,
  ICON_BUTTON_TYPES
} from '../../../components/buttons/IconButton';

const SearchBulkFormMobile = observer(() => {
  return (
    <form onSubmit={() => {}} className="mobile">
      <div className="search-form-header">Company bulk search</div>
      <div className="buttons-row">
        <IconButton
          type={ICON_BUTTON_TYPES.BLUE}
          icon={ICON_BUTTON_ICONS.ARROW_DOWN}
          outerText="Download template"
          disabled
        />
        <IconButton
          type={ICON_BUTTON_TYPES.BLUE}
          icon={ICON_BUTTON_ICONS.PAPERCLIP}
          outerText="Upload company name list"
          isPDF
          disabled
        />
      </div>
      <button type="submit" className="btn btn-primary disable-fade-primary" disabled={true}>
        Search
      </button>
    </form>
  );
});

export default SearchBulkFormMobile;
