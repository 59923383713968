import { Store } from '../store'; // eslint-disable-line no-unused-vars
import { makeAutoObservable, runInAction } from 'mobx';
import { API_ENDPOINTS } from '../../api/endpoints';
import { mapData } from '../../api/dataMappers';
import { PROJECT_FIELDS, SEARCH_FIELDS } from '../../utils/constants/fields';
import { ACCOUNT_TYPES, ACCOUNT_VERIF_TYPES } from '../../utils/constants/auth';
import { HEADER_MESSAGE_TYPES } from '../../utils/constants/header';
import pubSub from '../../utils/pubsub';
import { WSS_EVENTS, WSS_ACTIONS } from './WebSocketStore';

class ProjectsStore {
  /**
   * @param {Store} root
   */
  constructor(root) {
    makeAutoObservable(this);
    this.root = root;
  }

  reset = () => {
    this.wssQuery = [];
    this.allProjects = [];
    this.loadProjectsError = null;
    this.isLoadingProjects = false;
    this.initialLoadProjects = true;
    this.isRequestingProjectFeedback = false;
    this.isResolvingProjectFeedback = false;
    this.isChangingProjectName = false;
    this.isArchivingProject = false;
    this.isDeletingProjects = false;
  };

  wssQuery = [];
  allProjects = [];
  loadProjectsError = null;
  isLoadingProjects = false;
  initialLoadProjects = true;
  isRequestingProjectFeedback = false;
  isResolvingProjectFeedback = false;
  isChangingProjectName = false;
  isArchivingProject = false;
  isDeletingProjects = false;

  _wss_onData = (jsData = {}) => {
    if (this.isLoadingProjects || this.initialLoadProjects || this.loadProjectsError) {
      return;
    }

    if (jsData.for === WSS_EVENTS.AGENT_PROJECTS_STORE.AGENT_PROJECT) {
      if ([WSS_ACTIONS.CREATE, WSS_ACTIONS.UPDATE].includes(jsData?.action)) {
        jsData.data = {
          ...jsData.data,
          ...mapData(jsData.data, PROJECT_FIELDS),
          ...(jsData.data.searchFields
            ? { fields: mapData(jsData.data.searchFields, SEARCH_FIELDS) }
            : {})
        };
      }
      runInAction(() => {
        if (jsData?.action === WSS_ACTIONS.CREATE) {
          this._wss_onCreateProject(jsData);
        } else if (jsData?.action === WSS_ACTIONS.UPDATE) {
          this._wss_onUpdateProject(jsData);
        } else if (jsData?.action === WSS_ACTIONS.DELETE) {
          this._wss_onDeleteProject(jsData);
        }
      });
    } else if (
      jsData.for === WSS_EVENTS.AGENT_PROJECTS_STORE.AGENT_PROJECT_OWNER_LAST_CHECKED_DATE_UPDATE
    ) {
      runInAction(() => {
        this._wss_onOwnerLastCheckedDateUpdate(jsData);
      });
    }
  };

  _wss_onCreateProject = (jsData) => {
    const project = jsData.data;

    const foundProject = this.allProjects.find((p) => p.id === project.id);
    if (foundProject) {
      Object.entries(project).forEach(([prop, value]) => {
        foundProject[prop] = value;
      });
    } else {
      this.allProjects.push(project);
    }
  };

  _wss_onUpdateProject = (jsData) => {
    const project = jsData.data;

    const foundProject = this.allProjects.find((p) => p.id === project.id);
    if (foundProject) {
      let shouldUpdateLastCheckedDate = false;
      if (foundProject.ownerId === this.root.authStore.currentUserId) {
        const foundProjectLastResolvedDate =
          foundProject?.sharedUsers?.sort?.((a, b) => b.dateResolved - a.dateResolved)?.[0]
            ?.dateResolved || 0;
        const updatedProjectDataLastResolvedDate =
          project?.sharedUsers?.sort?.((a, b) => b.dateResolved - a.dateResolved)?.[0]
            ?.dateResolved || 0;
        if (
          updatedProjectDataLastResolvedDate > foundProjectLastResolvedDate &&
          updatedProjectDataLastResolvedDate > foundProject.ownerLastCheckedDate
        ) {
          shouldUpdateLastCheckedDate = true;
        }
      }
      Object.entries(project).forEach(([prop, value]) => {
        foundProject[prop] = value;
      });
      if (shouldUpdateLastCheckedDate) {
        setTimeout(() => {
          pubSub.publish({ event: 'update-project-last-checked-date', projectId: project.id });
        }, 0);
      }
    }
  };

  _wss_onDeleteProject = (jsData) => {
    const projectId = jsData.data?.id;

    const foundProjectIndex = this.allProjects.findIndex((p) => p.id === projectId);
    if (foundProjectIndex !== -1) {
      this.allProjects.splice(foundProjectIndex, 1);
    }
  };

  _wss_onOwnerLastCheckedDateUpdate = (jsData) => {
    const projectId = jsData.data?.projectId;

    const foundProjectIndex = this.allProjects.findIndex((p) => p.id === projectId);
    if (foundProjectIndex !== -1) {
      this.allProjects[foundProjectIndex].ownerLastCheckedDate = jsData.data.date;
    }
  };

  // active
  get projects() {
    const currentUserId = this.root.authStore.currentUserId;

    if (!currentUserId) {
      return [];
    }

    return this.allProjects.filter((p) => p.ownerId === currentUserId && p.status === 'ACTIVE');
  }

  // archived
  get archivedProjects() {
    const currentUserId = this.root.authStore.currentUserId;

    if (!currentUserId) {
      return [];
    }

    return this.allProjects.filter((p) => p.ownerId === currentUserId && p.status === 'ARCHIVED');
  }

  get pendingSharedProjects() {
    const currentUserId = this.root.authStore.currentUserId;

    if (!currentUserId) {
      return [];
    }

    return this.allProjects.filter((p) => {
      const foundUser = p.sharedUsers?.find?.((user) => user.id === currentUserId);
      if (!foundUser) {
        return false;
      }
      return foundUser.status === 'PENDING';
    });
  }

  get resolvedSharedProjects() {
    const currentUserId = this.root.authStore.currentUserId;

    if (!currentUserId) {
      return [];
    }

    return this.allProjects.filter((p) => {
      const foundUser = p.sharedUsers?.find?.((user) => user.id === currentUserId);
      if (!foundUser) {
        return false;
      }
      return foundUser.status === 'RESOLVED';
    });
  }

  get hasNewProjectFeedback() {
    const currentUserId = this.root.authStore.currentUserId;

    return !!this.allProjects.filter((p) => {
      if (p.ownerId !== currentUserId || p.status === 'ARCHIVED') {
        return false;
      }

      return this.hasProjectNewFeedback(p);
    }).length;
  }

  hasProjectNewFeedback = (project) => {
    const ownerLastCheckedDate = project.ownerLastCheckedDate || 0;
    if (
      project.sharedUsers?.findIndex?.(
        (u) => !!u.dateResolved && u.dateResolved > ownerLastCheckedDate
      ) !== -1
    ) {
      return true;
    }

    return false;
  };

  get isLoadingProjectsData() {
    return this.isLoadingProjects || this.initialLoadProjects;
  }

  loadProjects = () => {
    if (
      this.isLoadingProjects ||
      this.root.authStore.userVerificationStatus !== ACCOUNT_VERIF_TYPES.APPROVED ||
      this.root.authStore.userAccountType !== ACCOUNT_TYPES.AGENT
    ) {
      return;
    }

    this.isLoadingProjects = true;
    this.loadProjectsError = null;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.GET_PROJECTS,
      onSuccess: (response) => {
        this.allProjects = response.map((p) => ({
          ...p,
          ...mapData(p, PROJECT_FIELDS),
          fields: mapData(p.searchFields, SEARCH_FIELDS)
        }));
      },
      onError: (errorMessage) => {
        this.loadProjectsError = errorMessage || 'Failed to obtain projects.';
      },
      onFinally: () => {
        this.isLoadingProjects = false;
        if (this.initialLoadProjects) {
          this.initialLoadProjects = false;
        }
      }
    });
  };

  saveProject = (
    searchId = '',
    name = '',
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.SAVE_PROJECT,
      body: { searchId, name },
      onSuccess: (response) => {
        this.root.utilsStore.setHeaderMessage(
          `You have successfully saved project with name '${name}'.`
        );
        onSuccess(response);
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          `Failed to save project with name '${name}'.`,
          HEADER_MESSAGE_TYPES.ERROR
        );
        onError(errorMessage);
      },
      onFinally
    });
  };

  archiveProject = (
    projectId = '',
    archive = true,
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isArchivingProject) {
      return;
    }

    this.isArchivingProject = true;

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.ARCHIVE_PROJECT,
      body: { projectId, archive },
      onSuccess: (response) => {
        this.root.utilsStore.setHeaderMessage(
          `Successfully ${archive ? 'archived' : 'unarchived'} project.`
        );
        onSuccess();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          `Failed to ${archive ? 'archive' : 'unarchive'} project.`,
          HEADER_MESSAGE_TYPES.ERROR
        );
        onError(errorMessage);
      },
      onFinally: () => {
        this.isArchivingProject = false;
        onFinally();
      }
    });
  };

  deleteProjects = (
    projectIds = [],
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isDeletingProjects) {
      return;
    }

    this.isDeletingProjects = true;

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.DELETE_PROJECTS,
      body: { projectIds },
      onSuccess: () => {
        this.root.utilsStore.setHeaderMessage(
          `Successfully deleted ${projectIds.length ? 'projects' : 'project'}.`
        );
        onSuccess();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          `Failed to delete ${projectIds.length ? 'projects' : 'project'}.`,
          HEADER_MESSAGE_TYPES.ERROR
        );
        onError(errorMessage);
      },
      onFinally: () => {
        this.isDeletingProjects = false;
        onFinally();
      }
    });
  };

  requestProjectFeedback = (
    projectId = '',
    askUserIds = [],
    note = '',
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isRequestingProjectFeedback) {
      return;
    }

    this.isRequestingProjectFeedback = true;

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.REQUEST_PROJECT_FEEDBACK,
      body: { projectId, askUserIds, note },
      onSuccess: (response) => {
        this.root.utilsStore.setHeaderMessage('Successfully requested feedback.');
        onSuccess();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          'Failed to request feedback.',
          HEADER_MESSAGE_TYPES.ERROR
        );
        onError(errorMessage);
      },
      onFinally: () => {
        this.isRequestingProjectFeedback = false;
        onFinally();
      }
    });
  };

  resolveProjectFeedback = (
    projectId = '',
    comment = '',
    feedbacks = [],
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isResolvingProjectFeedback) {
      return;
    }

    this.isResolvingProjectFeedback = true;

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.RESOLVE_PROJECT_FEEDBACK,
      body: { projectId, comment, feedbacks },
      onSuccess: (response) => {
        this.root.utilsStore.setHeaderMessage('Successfully resolved feedback.');
        onSuccess();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          'Failed to resolve feedback.',
          HEADER_MESSAGE_TYPES.ERROR
        );
        onError(errorMessage);
      },
      onFinally: () => {
        this.isResolvingProjectFeedback = false;
        onFinally();
      }
    });
  };

  changeProjectName = (
    projectId = '',
    name = '',
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isChangingProjectName) {
      return;
    }

    this.isChangingProjectName = true;

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.UPDATE_PROJECT_NAME,
      body: { projectId, name },
      onSuccess: (response) => {
        this.root.utilsStore.setHeaderMessage('Successfully changed project name.');
        onSuccess();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          'Failed to change project name.',
          HEADER_MESSAGE_TYPES.ERROR
        );
        onError(errorMessage);
      },
      onFinally: () => {
        this.isChangingProjectName = false;
        onFinally();
      }
    });
  };

  updateProjectLastCheckedDate = (
    projectId = '',
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    const foundProject = this.allProjects.find((p) => p.id === projectId);
    if (!foundProject) {
      return;
    }

    const ownerLastCheckedDate = foundProject.ownerLastCheckedDate || 0;
    const lastResolvedDate =
      foundProject.sharedUsers
        ?.filter?.((u) => !!u.dateResolved)
        ?.sort?.((a, b) => b.dateResolved - a.dateResolved)?.[0]?.dateResolved || 0;

    if (!lastResolvedDate || ownerLastCheckedDate > lastResolvedDate) {
      return;
    }

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.UPDATE_PROJECT_LAST_CHECKED_DATE,
      body: { projectId, date: lastResolvedDate + 1 },
      onSuccess: (response) => {
        onSuccess(response);
      },
      onError: (errorMessage) => {
        onError(errorMessage);
      },
      onFinally: () => {
        onFinally();
      }
    });
  };
}

export default ProjectsStore;
