import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { Link } from 'react-router-dom';
import { paths } from '../../../utils/constants/routes';
import { formatNumber, formatNumberRange, isElementVisibleOnScreen } from '../../../utils/utils';
import { FUND_FIELDS } from '../../../utils/constants/fields';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import { PUNCTUATION } from '../../../utils/constants/notation';
import { useEffect, Fragment } from 'react';
import IconButton, {
  ICON_BUTTON_ICONS,
  ICON_BUTTON_TYPES
} from '../../../components/buttons/IconButton';
import useHistory from '../../../hooks/useHistory';

const FundsList = observer(() => {
  const { fundsStore, utilsStore, authStore, outreachStore } = useStore();
  const { navigate } = useHistory();
  const state = useLocalObservable(() => ({
    deleteConfirmations: {},
    toggleDeleteConfirmation: (id) => {
      state.deleteConfirmations[id] = !state.deleteConfirmations[id];
      if (state.deleteConfirmations[id]) {
        setTimeout(() => {
          const deleteBtnDOM = document.getElementById(`delete-fund-list-entry-${id}`);
          if (!deleteBtnDOM) {
            return;
          }

          const isVisible = isElementVisibleOnScreen(deleteBtnDOM);
          if (isVisible) {
            return;
          }

          const entryDOM = document.getElementById(`fund-list-entry-${id}`);
          if (!entryDOM) {
            return;
          }

          entryDOM.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
        }, 10);
      }
    }
  }));

  useEffect(() => {
    window.scrollTo({ top: 0 });
    authStore.loadCompanyUsers();
    fundsStore.loadCompanyFunds();
  }, [authStore]);

  const disableDeleteBtns = authStore.isLoadingCompanyUsers || fundsStore.isLoadingCompanyFunds;

  return (
    <>
      {fundsStore.funds.length !== 0 && (
        <div className="table-responsive">
          <div className="header">
            <div className="item">Fund name</div>
            <div className="item">Year</div>
            <div className="item">Volume (€M)</div>
            <div className="item">Geo focus</div>
            <div className="item">Industries</div>
            <div className="item">EV ticket (€M)</div>
          </div>
          {fundsStore.funds.map((entry) => {
            const isLoading = fundsStore.fundsInAction[entry.id];
            const showDeleteConfirm = !isLoading && state.deleteConfirmations[entry.id];
            const showActionButtons = !isLoading && !showDeleteConfirm;
            const portfolios = fundsStore.companyPortfolios.filter(
              (p) => p.affiliateFund === entry.id
            );
            const isAffilate = portfolios.length > 0;
            const disableDeleteBtn = disableDeleteBtns || isAffilate;
            const portfolioUsers = authStore.companyUsers.filter(
              (u) => !!portfolios.find((p) => p.ownerId === u.id)
            );
            const usersPortfolioMap = Object.fromEntries(
              portfolioUsers.map((u) => [
                u.id,
                {
                  name: `${u.firstName} ${u.lastName}`,
                  portfolios: portfolios
                    .filter((p) => p.ownerId === u.id)
                    .map((p) => p.name)
                    .join(', ')
                }
              ])
            );
            const affilateTooltipMsg = `This fund is linked with ${
              portfolios.length > 1 ? 'portfolio companies: ' : 'portfolio company '
            }${Object.values(usersPortfolioMap)
              .map(({ name, portfolios }) => `${portfolios}, key contact ${name}`)
              .join('; ')}. ${
              portfolios.length > 1 ? 'They ' : 'It '
            }must be re-allocated to a different fund before deletion of the fund.`;

            const linkedOutreaches = outreachStore.outreaches.filter(
              (o) => o.objectData.id === entry.id
            );

            return (
              <Fragment key={entry.id}>
                <div className="row" id={`fund-list-entry-${entry.id}`}>
                  <div className="item strong">
                    <div className="label">Fund name</div>
                    <div className="value fund-name">{entry[FUND_FIELDS.NAME.NAME]}</div>
                  </div>
                  <div className="item">
                    <div className="label">Year</div>
                    <div className="value">
                      {entry[FUND_FIELDS.VINTAGE.NAME] || PUNCTUATION.EMPTY_VALUE}
                    </div>
                  </div>
                  <div className="item">
                    <div className="label">Volume (€M)</div>
                    <div className="value">{formatNumber(entry[FUND_FIELDS.VOLUME.NAME])}</div>
                  </div>
                  <div className="item">
                    <div className="label">Geo focus</div>
                    <div className="value">
                      {utilsStore
                        .shortenList(utilsStore.getOptionName(entry, FUND_FIELDS.GEOGRAPHIES), 3)
                        .join(', ')}
                    </div>
                  </div>
                  <div className="item">
                    <div className="label">Industries</div>
                    <div className="value">
                      {utilsStore
                        .shortenList(utilsStore.getOptionName(entry, FUND_FIELDS.INDUSTRIES), 3)
                        .join(', ')}
                    </div>
                  </div>
                  <div className="item">
                    <div className="label">EV ticket (€M)</div>
                    <div className="value">
                      {formatNumberRange(
                        entry[FUND_FIELDS.ENTERPRISE_RANGE_MIN.NAME],
                        entry[FUND_FIELDS.ENTERPRISE_RANGE_MAX.NAME]
                      )}
                    </div>
                  </div>
                  <div className="actions">
                    {isLoading && <GeneralLoader />}
                    {showActionButtons && (
                      <>
                        <IconButton
                          type={ICON_BUTTON_TYPES.BLUE}
                          innerText="Edit"
                          filled={true}
                          onClick={() => {
                            navigate(paths.FUNDS_EDIT + '?fundId=' + entry.id);
                          }}
                          wrapClassName="listing-action-text-btn"
                        />
                        <IconButton
                          icon={ICON_BUTTON_ICONS.TRASH}
                          withBorder={false}
                          disabled={disableDeleteBtn}
                          onClick={() => state.toggleDeleteConfirmation(entry.id)}
                          tooltipText={isAffilate ? affilateTooltipMsg : ''}
                          tooltipClassName="tooltip-delete-list-fund"
                          wrapClassName="listing-action-delete-btn"
                        />
                      </>
                    )}
                    {showDeleteConfirm && (
                      <>
                        <IconButton
                          innerText="Cancel"
                          onClick={() => state.toggleDeleteConfirmation(entry.id)}
                          wrapClassName="listing-action-text-btn"
                        />
                        <IconButton
                          id={`delete-fund-list-entry-${entry.id}`}
                          type={ICON_BUTTON_TYPES.RED}
                          filled
                          innerText="Delete"
                          onClick={() => fundsStore.deleteFund(entry)}
                          wrapClassName="listing-action-text-btn"
                        />
                      </>
                    )}
                  </div>
                </div>
                {/* {showDeleteConfirm && !!linkedOutreaches.length && (
                  <div className="outreach-deletion-row">
                    <div className="outreach-deletion-row-text">
                      This fund has {linkedOutreaches.length} outreach(es). They will be deleted
                      after the confirmation.
                    </div>
                    <IconButton
                      icon={ICON_BUTTON_ICONS.INFO_CIRCLE}
                      type={ICON_BUTTON_TYPES.RED}
                      withBorder={false}
                      withFill={false}
                    />
                  </div>
                )} */}
              </Fragment>
            );
          })}
        </div>
      )}
      <Link className="create-fund-panel" to={paths.FUNDS_CREATE}>
        <div className="icon-add"></div>
        <div className="text">Create new fund</div>
      </Link>
    </>
  );
});

export default FundsList;
