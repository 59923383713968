import StaticPageLayout from '../../../components/layouts/StaticPageLayout';

const TermsAndConditions = () => {
  return (
    <StaticPageLayout page="tos">
      <h1>Terms and Conditions</h1>
      <div className="static-content">
        <div className="content-header">
          General Terms & Conditions for the use of the transact.digital solution
          <br />
          Effective as of August 24th, 2023
        </div>

        <div className="content-body">
          <p>
            The provided General Terms & Conditions (hereinafter referred to as &quot;GTC&quot;)
            pertain to the legal relationship between transact.digital GmbH, Neue Rothofstrasse
            13-19, 60313 Frankfurt am Main, Germany, e-mail: info@transact.digital (hereinafter
            referred to as &quot;transact.digital&quot;) and its clients such as natural persons,
            legal entities, or partnerships (hereinafter referred to as &quot;Users&quot;) utilizing
            the website or similar services (hereinafter referred to as the &quot;Solution&quot;).
            These terms encompass the extent of transact.digital&apos;s offerings, including
            websites, applications, or related services, and are applicable to all Users.
          </p>
          <p>
            By using the Solution, the User declares to agree to the application of these GTC and
            this fact can be recorded by confirming with the corresponding button during the
            registration process. The User therefore accepts the application of the GTC of
            transact.digital within the legal relationship between the User and transact.digital
            concerning the use of the Solution. Use of the Solution will not be permitted if the
            User refuses the GTC. The User can read the GTC via the respective Solution.
          </p>
          <p className="section">§ 1 Enrollment and eligible parties</p>
          <ol>
            <li>
              The utilization of the Solution&apos;s services may necessitate verification on an
              individual basis during the registration process.
            </li>
            <li>
              Upon registration, it is mandatory for a Solution User to specify their User group
              affiliation and confirm this by furnishing the requisite information as prompted.
              transact.digital retains the prerogative to reject any registration application and to
              evaluate and adjust the allocation to a specific User group. Furthermore,
              transact.digital maintains the right to modify the count and nomenclature of User
              groups.
            </li>
            <li>
              During the registration procedure, all data solicited from the User must be accurate,
              truthful, and comprehensive. Any registration of a legal person or a company must be
              made by a natural person authorized to represent this entity or company. Throughout
              the course of the legal relationship, the User commits to keeping the information
              provided to transact.digital during registration constantly up-to-date and informing
              transact.digital promptly of any modifications through via the applicable solution
              interface.
            </li>
            <li>
              transact.digital reserves the authority to request satisfactory documentation from the
              User as substantiation for the provided data, either during or subsequent to the
              registration process on the Solution. In the event of non-provision of the requested
              evidence or doubts regarding the precision of User-supplied information,
              transact.digital reserves the right to eliminate the corresponding subscription and
              discontinue supplementary services.
            </li>
          </ol>
          <p className="section">§ 2 Purpose and scope of use of the solution</p>
          <ol>
            <li>
              transact.digital provides the User with information in the open or registered areas of
              the Solution on the basis that this does not constitute the basis of any obligation
              and without any warranties regarding completeness.
            </li>
            <li>
              The use of services provided by the Solution is generally free of charge. Access to
              individual areas of the Solution may be subject to additional requirements or payment
              of fees.
            </li>
            <li>
              transact.digital hereby informs that availability and use of the Solution is subject
              to maintenance work or required maintenance duration. Users do not have ongoing access
              to the Solution or additional services at all times. In particular, transact.digital
              is not responsible for interruptions of access to the Solution whose sole or primary
              cause is beyond the control of transact.digital and/or in the event of a force majeure
              event being sole or primary cause and/or transact.digital is not responsible for
              interruption of access to the Solution.
            </li>
          </ol>
          <p className="section">§ 3 Enrollment and use of protected pages and services</p>

          <ol>
            <li>
              Certain areas of the Solution may be protected from access. Access to these spaces is
              limited to transact.digital approved Registered Users. There is no right to register
              with transact.digital. In particular, transact.digital reserves the right to make
              areas of the Solution that were previously free to access subject to registration and
              approval. transact.digital may revoke access or use at any time without giving reason
              by blocking access data, especially if the User
              <ul>
                <li>has provided false information during registration;</li>
                <li>
                  has failed to comply with the conditions of use or obligations regarding the
                  processing of access data;
                </li>
                <li>has violated applicable law by accessing or using the Solution; or</li>
                <li>
                  is no longer recognized by transact.digital as an official partner or is no longer
                  active for transact.digital; or
                </li>
                <li>has not used the Solution for a long time, i.e., 12 months.</li>
              </ul>
            </li>
            <li>
              The User shall ensure that the User data is not accessible to any third party and the
              User shall be responsible for all activities performed in connection with such User
              data. In particular, the User shall be liable for any abuse of the Solution services
              or any violations of relevant laws by third parties in the event of the disclosure or
              provision of the User&apos;s data. If the User becomes aware that a third party is
              abusing the User&apos;s data, he must immediately notify transact.digital in writing.
              Upon receipt of this notice, transact.digital will block access to the password
              protected area with this User data. This blocking can only be removed after the User
              has specifically requested this from transact.digital or after a new registration.
            </li>
          </ol>

          <p className="section">§ 4 Right to use; right of withdrawal</p>

          <ol>
            <li>
              Use of any information, software or materials available on the Solution is subject to
              these GTC as amended from time to time. Any separately agreed terms and conditions
              (licenses) will prevail over these GTC.
            </li>
            <li>
              transact.digital grants the User a free, non-exclusive, non-transferable,
              non-sublicensable, appropriate - subject to the terms of the use relationship –
              limited right to use information, software, services, functions, content and materials
              made available on the Solution for a fixed term and for the scope and purpose agreed
              upon between the parties or, if not otherwise agreeable, consistent with the purpose
              that transact.digital pursues. transact.digital reserves the right to unilaterally
              revoke any right of use if, in transact.digital&apos;s opinion, the requirements of
              transact.digital are violated.
            </li>
            <li>
              The software is provided free of charge in machine readable form only. No source code
              disclosure. Insofar as any license conditions of open-source software take precedence
              over these GTC and stipulate the disclosure of a source code, transact.digital shall
              make the source code available in return for the reimbursement of costs.
            </li>
            <li>
              Except as permitted by mandatory provisions of law, the User may not modify, reverse
              engineer or decompile the software or its documentation, or delete any part thereof.
            </li>
            <li>
              Information, software and materials are protected by applicable copyright laws as well
              as intellectual property laws. Users must comply with such laws and, in particular,
              may not remove any alphanumeric identifiers, trademarks, or copyright notices from the
              information, software, documents, or any copies thereof.
            </li>
            <li>
              Hereby, transact.digital advises that any copying, modification, distribution or
              storage of text, portions of text, images, photographs or other photographic and video
              materials beyond simple browsing or caching of such content by the User for his own
              purposes requires the prior express consent of transact.digital and/or the respective
              partners of the relevant transact.digital Solution.
            </li>
            <li>
              The applicable provisions of copyright law regarding the protection of software are
              not affected.
            </li>
          </ol>
          <p className="section">§ 5 Obligations related to use</p>
          <ol>
            <li>
              While engaging with the Solution, the User shall refrain from, in particular:
              <ul>
                <li>
                  inflicting harm upon any individual or other Solution Users, and refraining from
                  violating related privacy rights.
                </li>
                <li>
                  violating public moral standards, infringing upon applicable laws, or violating
                  stipulated usage requirements.
                </li>
                <li>
                  transmitting or conveying content containing viruses or any other form of
                  programming that may cause software damage.
                </li>
                <li>
                  entering, storing, or transmitting content and links without proper authorization,
                  particularly if such content and links contravene confidentiality obligations or
                  legality.
                </li>
                <li>
                  disseminating unsolicited emails or advertisements, false virus alerts,
                  malfunctions, or similar misrepresentations, or encouraging involvement in
                  lotteries, pyramid schemes, chain letters, snowball systems, or similar endeavors.
                </li>
                <li>
                  employing any means to hinder or impede others&apos; use of the Solution, or
                  through actions causing harm or potential liability to transact.digital, its
                  Users, or any other parties.
                </li>
                <li>
                  utilizing the Solution in a manner that could disable, overload, impair, or damage
                  the Solution itself or its utilization by third parties.
                </li>
              </ul>
            </li>
            <li>
              At any given moment, transact.digital reserves the right to restrict access to the
              Solution and disallow participation in supplementary services, particularly if the
              User violates its responsibilities as outlined within these GTC.
            </li>
            <li>
              In situations where the User neglects to fulfill its obligations and commitments as
              per these GTC, the User is obligated to indemnify transact.digital upon initial
              request against any claims asserted by third parties against transact.digital,
              irrespective of their legal basis, nature, or monetary value.
            </li>
          </ol>
          <p className="section">§ 6 Intellectual property</p>
          <ol>
            <li>
              Unless stated differently in § 4 of these GTC, the information, trademarks, and all
              other content within the Solution must not be altered, duplicated, replicated, sold,
              leased, or in any way utilized or shared with third parties, or exploited in any
              manner without prior written consent from transact.digital.
            </li>
            <li>
              Apart from the explicitly granted right of use and other rights mentioned herein, the
              User is not authorized to confer any other forms of rights, especially concerning
              company names or any proprietary rights within specific industries such as patents,
              utility models, or trademarks. transact.digital is under no obligation to grant such
              rights.
            </li>
          </ol>
          <p className="section">§ 7 Duration of use, termination and blocking of relationships</p>
          <ol>
            <li>The duration of this legal relationship shall remain indefinite.</li>
            <li>
              At any point in time, transact.digital retains the authority to cease the entire
              Solution.
            </li>
            <li>
              Either party may terminate the legal relationship at any time without notice in
              written form to info@transact.digital or through the Solution; Extraordinary
              termination rights remain unaffected. In particular, transact.digital has the right to
              exercise the right to extraordinary terminate the legal relationship due to
              User&apos;s violation of § 4 or § 5.
            </li>
            <li>
              If the relationship is terminated by either party, transact.digital reserves the right
              to block the User&apos;s access to the registered area of the Solution when the
              termination becomes effective. User data and other stored personal data of the User
              will be deleted by transact.digital as soon as they are no longer needed. Additional
              services provided by transact.digital will end upon termination.
            </li>
            <li>
              In the event transact.digital terminates the authorization for use, the User must
              cease all activities authorized by this GTC, including the use of all services, and
              the User must delete all Solutions and stop access by all devices they own and delete
              all copies immediately.
            </li>
            <li>
              These GTC will continue to apply to the handling of the legal relationship after
              termination.
            </li>
          </ol>

          <p className="section">§ 8 Export regulations</p>
          <p>
            Transfers of information, software or documents may be subject to applicable export
            control laws, in particular the laws of a respective country, the European Union or EU
            member states or the United States, due to its nature, intended use or final
            destination. The User confirms that it will comply with all applicable export control
            regulations.
          </p>

          <p className="section">§ 9 Citations and Hyperlinks</p>
          <p>
            In the Solution, references and hyperlinks to third-party websites might exist.
            transact.digital is not liable for the content on these websites. Note that
            transact.digital doesn&apos;t endorse these websites&apos; content. This is because
            transact.digital lacks control over linked information and isn&apos;t responsible for
            linked sites&apos; accuracy. If the Solution uses third-party content, it will clearly
            indicate and disclose the parties involved. Any interaction with linked websites is at
            the User&apos;s own risk.
          </p>

          <p className="section">§ 10 Content rights and data protection</p>
          <ol>
            <li>
              Users of the Solution keep full rights to the content they upload to the Solution.
              Upon submitting content, Users grant transaction.digital the necessary permissions for
              content processing and management. The User is obliged to secure permissions from
              relevant third parties if applicable.
            </li>
            <li>
              transact.digital is granted the right to analyze, copy, rate, review and use content
              for improvement and further development of its Solution and other services. Data from
              User interactions can also be utilized for these purposes. The User affirms and
              guarantees to have the authority to grant such a license.
            </li>
            <li>
              The User further acknowledges that the content provided does not breach or contravene
              any confidentiality obligations, patents, copyrights, trademarks, trade secrets,
              domain names, or other (intellectual) property rights of transact.digital or any other
              third party.
            </li>
            <li>
              When collecting, using and processing the User&apos;s personal data, transact.digital
              undertakes to comply with the applicable provisions of data protection laws, viewable
              on the Solution.
            </li>
          </ol>

          <p className="section">§ 11 Exclusion of liability</p>
          <ol>
            <li>
              For anything provided free of charge, transact.digital excludes liability for quality,
              accuracy, and legal ownership issues, unless there&apos;s gross negligence or
              intentional misconduct. transact.digital disclaims all warranties, explicit or
              implied, including merchantability and fitness for specific purposes.
            </li>
            <li>
              transact.digital&apos;s responsibility for quality and title defects is governed by
              §11 no. 1 and no. 2 of these GTC. Additional liability is excluded, except when
              mandated by law (e.g., statutory requirements, product liability), intentional
              misconduct, gross negligence, personal injury, guaranteed characteristics, defect
              concealment, or breach of key contract terms. However, compensation for breaches of
              fundamental contractual obligations will be restricted to the foreseeable and typical
              damages related to the contract, unless such damages are caused deliberately or due to
              serious negligence.
            </li>
            <li>
              transact.digital aims to keep the Solution virus-free, but transact.digital cannot
              guarantee that the Solution is virus-free. Before downloading and/or using the
              Solution, information, software and materials, Users must ensure for themselves that
              appropriate anti-virus software and security devices are used to protect themselves
              and to prevent viruses on the Solution.
            </li>
            <li>The foregoing does not imply a reversal of the burden of proof.</li>
            <li>
              To the extent that transact.digital&apos;s liability is excluded under the foregoing,
              this also applies in favor of transact.digital&apos;s constituent agencies, employees
              and agents.
            </li>
            <li>
              In all cases, transact.digital&apos;s total liability is limited to one thousand euros
              (1,000 euros).
            </li>
            <li>
              The User knows that the services provided on the Solution are only meant to facilitate
              the search for information and/or search results in the relevant segment and do not
              release the User from its own due diligence obligations.
            </li>
          </ol>

          <p className="section">§ 12 Force majeure</p>
          <p>
            transact.digital will not be held accountable for any inability or delay in carrying out
            its duties outlined in these terms, if such situations are caused by factors beyond its
            reasonable control. These factors include acts of nature, circumstances classified as
            &quot;acts of God,&quot; or any other cause that falls outside its sphere of influence.
            Such causes comprise but are not limited to failures in public or private communication
            networks, interruptions to business operations, fires, natural catastrophes and adverse
            weather conditions, war and acts of terrorism, outbreaks of diseases, delays in securing
            necessary official authorizations or permits, or actions taken by governmental bodies or
            authorities.
          </p>
          <p>
            In the event that such factors occur and impact the fulfillment of transact.digital’s
            obligations as per these GTC:
          </p>
          <p>
            (i) transact.digital will temporarily suspend its responsibilities as outlined in these
            terms, and the timeframe for fulfilling these obligations will be extended for the
            duration of the factors.
          </p>
          <p>
            (ii) transact.digital will make reasonable efforts to identify a resolution that allows
            the fulfillment of its obligations as per these GTC, despite the occurrence of such
            factors.
          </p>

          <p className="section">§ 13 Amendment of GTC</p>
          <ol>
            <li>
              transact.digital can modify the GTC at its discretion without prior notice. This
              includes adding, updating, or removing provisions (&quot;amendments&quot;). When such
              changes occur, transact.digital will update the GTC&apos;s effective date and share
              the updated version. Users will confirm agreement through ongoing use or can object,
              leading to a halt in further use.
            </li>
            <li>
              The legal relationship with the User may be structured in individual cases by
              additional conditions or agreements (e.g. providing services), additionally, modify or
              replace these General Terms & Conditions.
            </li>
          </ol>

          <p className="section">§ 14 Claims and warranty disclaimer</p>
          <ol>
            <li>
              Any legal proceedings arising from the use of the Solution or these General Terms and
              Conditions must be commenced within one year of the corresponding claim and all rights
              Subsequent claims are excluded.
            </li>
            <li>
              Except as expressly provided herein, the Solution is provided &quot;as is&quot; and
              &quot;as available&quot; and without warranties of any kind, express or implied.
              Except as required by mandatory law, transaction.digital expressly excludes all
              warranties, expressed or implied, arising out of the transaction or commercial use,
              including any warranties of merchantability. Merchantability, fitness for a particular
              purpose, non-infringement of rights, warranties regarding the reliability or
              availability of the Solution, or uninterrupted or error-free use of the Solution,
              warranties regarding the completeness, accuracy of Solution content or any warranties
              as to the timeliness of Solution content.
            </li>
          </ol>

          <p className="section">§ 15 Exemptions</p>
          <p>
            Any failure by transact.digital to exercise any right or enforce any obligation under
            these GTC or to take any action for any breach by the User or others shall not
            constitute a waiver of that right, in respect of this obligation or in connection with
            such breach. No waiver of any right, obligation or breach shall be effective unless in
            writing and signed by a duly authorized representative of transact.digital. Furthermore,
            no waiver granted in any particular case shall constitute a waiver in any other case.
          </p>

          <p className="section">
            § 16 Transfer; prohibition of collective action and non-personal representation and
            support
          </p>
          <ol>
            <li>
              Users may not assign, transfer, delegate or sublicense any of their rights or
              obligations under these GTC without the express prior written consent of
              transact.digital, which may be granted or withdrawn at transact.digital&apos;s sole
              discretion. Any assignment, transfer, authorization or sub-licensing without the
              foregoing consent will be void. transact.digital may assign, transfer, authorize and /
              or sublicense any of its rights or obligations under these GTC, in whole or in part,
              without limitation, in its sole discretion.
            </li>
            <li>
              The User agrees that it may assert any claim against transact.digital only on an
              individual basis and hereby waives any right to participate as a plaintiff, claimant
              or class member in any class action or representation proceeding unless prohibited by
              applicable law. Furthermore, unless User and transact.digital otherwise agree in
              writing, courts may not consolidate or join claims of more than one person or
              otherwise decide any form of class action, representative or class proceedings.
            </li>
          </ol>

          <p className="section">§ 17 Collateral agreements, jurisdiction, applicable law</p>
          <ol>
            <li>
              Any additional or ancillary agreements to these GTC, including any amendments to these
              terms, must be in writing.
            </li>
            <li>
              If the User is a merchant, a legal entity under public law, a special fund under
              public law or has no place of residence or registered office in the country in which
              transact.digital has its registered office, the place of jurisdiction shall be
              Frankfurt am Main, Germany.
            </li>
            <li>
              The solution is operated by transact.digital. The solution takes into account the
              requirements of the respective country where transact.digital has a registered office.
              transact.digital is not responsible for the possibility that the Solution&apos;s
              information, software and/or materials may also be accessed or downloaded from
              locations outside of their respective countries. If the User accesses the Solution
              from a location outside the respective country, the User is solely responsible for
              complying with the relevant regulations under the relevant national laws of that other
              location. In this case and if the User wishes to enter into a business relationship
              with transact.digital, the User needs to contact the representative of
              transact.digital in the relevant country.
            </li>
            <li>
              These GTC and the use of the Solution are governed by the laws of the registered
              office of transact.digital, i.e., the laws of the Federal Republic of Germany. The
              rules of conflict of laws and the provisions of the United Nations Convention on
              Contracts for the International Sale of Goods do not apply.
            </li>
          </ol>

          <p className="section">§18 Assets and content to promote transact.digital</p>
          <ol>
            <li>
              Company logos, images, animations, videos, product renderings, trademarks, text,
              stickers, poster templates, design elements and similar marketing materials (“Assets”)
              may be made available to Users on the Solution.
            </li>
            <li>
              Users may use content under conditions of § 4 to promote transact.digital solutions
              and services and to cooperate with transact.digital. The costs incurred by these
              measures are borne by the User. transact.digital expressly reserves the right to
              assert other rights.
            </li>
            <li>
              Assets and the User&apos;s use of them are subject to specific requirements of the
              &quot;transact.digital Design Guidelines&quot;. More information on the design guide
              will be made available to Users upon request. In case of doubt, the final
              determination of the compatibility of the intended use of the User&apos;s Assets with
              the &quot;transact.digital Design Guidelines&quot; shall be the sole responsibility of
              transact.digital. Unless authorized in the Solution itself, Users may not modify, add,
              remove or render assets unrecognizable or combine them with their own logos,
              watermarks, trademarks or similar.
            </li>
            <li>
              Unless permitted in the Platform itself, the User may not alter, add to, deface or
              make unrecognizable the Assets and/or content or combine such with its own logos,
              watermarks, trademarks or the like.
            </li>
            <li>
              Upon request, the User will provide transact.digital without delay, free of charge to
              transact.digital and in writing, information regarding current, past and/or planned
              future uses of the Assets available on the Solution.
            </li>
          </ol>

          <p className="section">§ 19 Feedbacks</p>
          <ol>
            <li>
              transact.digital may request User feedbacks. Such feedbacks may be subject to their
              own terms of participation in addition to the following:
            </li>
            <li>
              All persons under the age of 18, employees of transact.digital, relatives, spouses,
              registered partners and partners with whom the participants live in a non-marital
              partnership may not participate under any circumstances. transact.digital may
              disqualify any User from a feedback if the respective User interferes with the
              feedback, violating these GTC or any separate terms of participation.
            </li>
          </ol>

          <p className="section">§ 20 Severability clause</p>
          <p>
            Should an arbitrator or competent court deem any provision in these GTC invalid or
            unenforceable, that provision will be enforced to the maximum extent possible without
            affecting the validity and enforceability of the remaining provisions.
          </p>
        </div>
        {/* <div className="content-footer">
          By using the Investor-to-Business Matchmaking Platform, you agree to abide by these Terms
          and Conditions.
        </div> */}
      </div>
    </StaticPageLayout>
  );
};

export default TermsAndConditions;
