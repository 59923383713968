import { observer, useLocalObservable } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import {
  selectedProjectTabPageSize,
  SELECTED_PROJECT_TABS,
  encodeProjectsParams
} from '../constants';
import SearchResultsTabEntry from './SearchResultsTabEntry';
import ReactPaginate from 'react-paginate';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';
import { useStore } from '../../../store/store';
import { observable } from 'mobx';
import agentSearchSortOptions from '../../search/SearchResults/constants/agentSearchSortOptions';
import MultiSelect from '../../../components/inputs/MultiSelect';
import { FIELDS_TYPES } from '../../../utils/constants/fields';

export const observableAgentProjectResultsSorts = observable({
  selectedSort: 'relevance'
});

const SearchResultsTabView = observer(
  ({
    project = {},
    results = [],
    forTab = SELECTED_PROJECT_TABS.RESULTS,
    params = {},
    excludeResult = () => {},
    isExcludingResult = false,
    displayMessagePopup
  }) => {
    const { utilsStore } = useStore();
    const { navigate } = useHistory();

    const state = useLocalObservable(() => ({
      desktopActionsWidth: 0,
      setDesktopActionsWidth: (value) => {
        const newWidth = value + 'px';
        if (newWidth !== state.desktopActionsWidth) {
          state.desktopActionsWidth = value + 'px';
        }
      },
      forTab: forTab,
      setForTab: (value = '') => (state.forTab = value),
      selectSortOption: (_, [value]) => {
        observableAgentProjectResultsSorts.selectedSort = value;
        navigate(
          paths.PROJECTS +
            '?params=' +
            encodeProjectsParams({ ...params, selectedProjectTabPage: 1 })
        );
        setTimeout(() => {
          const el = document.getElementById('project-view-tab-1');
          const header = document.querySelector('header');
          if (el) {
            const headerHeight = header?.clientHeight || 0;
            window.scrollTo({ top: el.offsetTop - headerHeight, behavior: 'smooth' });
          }
        }, 100);
      },
      get sortOptions() {
        return agentSearchSortOptions.filter(
          (o) => !o.onlyForSearchType || o.onlyForSearchType === project.searchType
        );
      },
      get currentResults() {
        const sortedResults =
          state.forTab === SELECTED_PROJECT_TABS.RESULTS
            ? results.filter((r) => !r.isExcluded)
            : results.filter((r) => r.isExcluded);

        if (state.forTab === SELECTED_PROJECT_TABS.RESULTS) {
          if (observableAgentProjectResultsSorts.selectedSort === 'name-asc') {
            sortedResults.sort((a, b) =>
              (a.name || a.companyName || '').localeCompare(b.name || b.companyName || '', 'en', {
                sensitivity: 'base'
              })
            );
          } else if (observableAgentProjectResultsSorts.selectedSort === 'name-desc') {
            sortedResults.sort((a, b) =>
              (b.name || b.companyName || '').localeCompare(a.name || a.companyName || '', 'en', {
                sensitivity: 'base'
              })
            );
          } else if (observableAgentProjectResultsSorts.selectedSort !== 'relevance') {
            const [propKey, typeSort] = observableAgentProjectResultsSorts.selectedSort.split('-');
            const sorts = {
              asc: (propKey) => (a, b) =>
                +(a[propKey] || Number.MAX_SAFE_INTEGER) - +(b[propKey] || Number.MAX_SAFE_INTEGER),
              desc: (propKey) => (a, b) =>
                +(b[propKey] || -Number.MAX_SAFE_INTEGER / 3) -
                +(a[propKey] || -Number.MAX_SAFE_INTEGER / 3)
            };
            sortedResults.sort(sorts[typeSort](propKey));
          }
        }

        return sortedResults;
      }
    }));

    useEffect(() => {
      state.setForTab(forTab);
    }, [state, forTab]);

    const isFund = project.searchType === 'funds';
    const isPortfolio = project.searchType === 'portfolios';
    const isProfile = project.searchType === 'investprofiles';

    const showPagination = state.currentResults.length > selectedProjectTabPageSize;
    const pages = Math.ceil(state.currentResults.length / selectedProjectTabPageSize) || 1;
    const page = (params.selectedProjectTabPage || 1) - 1;
    const isNonExcludedResults = forTab === SELECTED_PROJECT_TABS.RESULTS;

    return (
      <div className="table-responsive">
        {state.currentResults.length === 0 ? (
          <div className="no-data">No items for this tab</div>
        ) : (
          <>
            <div className="header">
              <div className="item">
                {isFund
                  ? 'Fund name'
                  : isPortfolio
                  ? 'Portfolio name'
                  : isProfile
                  ? 'Profile name'
                  : 'Entry name'}
              </div>
              <div className="item">Industry focus</div>
              <div className="item">Geo focus</div>
              <div className="item text-align-right-desktop">Enterprise value (€M)</div>
              <div className="item text-align-right-desktop">Equity value (€M)</div>
              <div className="item feedback-item">Feedback results</div>
              <div style={{ width: state.desktopActionsWidth }}></div>
              {isNonExcludedResults && (
                <div className="project-sort-wrapper ">
                  <MultiSelect
                    field={{ NAME: 'sort', ID: 'srtv-sort-d', TYPE: FIELDS_TYPES.TYPE_SELECT }}
                    value={[observableAgentProjectResultsSorts.selectedSort]}
                    values={state.sortOptions}
                    setFieldValue={state.selectSortOption}
                  />
                </div>
              )}
            </div>

            {isNonExcludedResults && utilsStore.windowWidth < 481 && (
              <MultiSelect
                field={{ NAME: 'sort', ID: 'srtv-sort-m', TYPE: FIELDS_TYPES.TYPE_SELECT }}
                value={[observableAgentProjectResultsSorts.selectedSort]}
                values={state.sortOptions}
                setFieldValue={state.selectSortOption}
                inputWrap={{ enable: true, className: 'srtv-wrap' }}
              />
            )}

            {state.currentResults
              .slice(
                (params.selectedProjectTabPage - 1) * selectedProjectTabPageSize,
                params.selectedProjectTabPage * selectedProjectTabPageSize
              )
              .map((entry, idx) => (
                <SearchResultsTabEntry
                  key={entry.id}
                  entry={entry}
                  project={project}
                  type={project.searchType}
                  forTab={forTab}
                  excludeResult={excludeResult}
                  isExcludingResult={isExcludingResult}
                  displayMessagePopup={displayMessagePopup}
                  setDesktopActionsWidth={idx === 0 ? state.setDesktopActionsWidth : undefined}
                />
              ))}

            {showPagination && (
              <ReactPaginate
                onPageChange={({ selected }) => {
                  navigate(
                    paths.PROJECTS +
                      '?params=' +
                      encodeProjectsParams({ ...params, selectedProjectTabPage: selected + 1 })
                  );
                  setTimeout(() => {
                    const el = document.getElementById('project-view-tab-1');
                    const header = document.querySelector('header');
                    if (el) {
                      const headerHeight = header?.clientHeight || 0;
                      window.scrollTo({ top: el.offsetTop - headerHeight, behavior: 'smooth' });
                    }
                  }, 100);
                }}
                forcePage={page}
                pageCount={pages}
                pageRangeDisplayed={utilsStore.windowWidth < 481 ? 1 : selectedProjectTabPageSize}
                marginPagesDisplayed={utilsStore.windowWidth < 481 ? 1 : selectedProjectTabPageSize}
                renderOnZeroPageCount={null}
                breakLabel="..."
                nextLabel="Next"
                previousLabel="Prev"
                containerClassName="pagination-container"
                pageClassName="page"
                pageLinkClassName="pageLink"
                nextClassName="next"
                nextLinkClassName="nextLink"
                previousClassName="prev"
                previousLinkClassName="prevLink"
                breakClassName="break"
                breakLinkClassName="breakLink"
              />
            )}
          </>
        )}
      </div>
    );
  }
);

export default SearchResultsTabView;
