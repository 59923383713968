import { observer } from 'mobx-react';
import Input from '../../../components/inputs/Input';
import { FIELDS_TYPES } from '../../../utils/constants/fields';

const SearchNameFormMobile = observer(() => {
  return (
    <form onSubmit={() => {}} className="mobile">
      <div className="search-form-header">Search by company name</div>
      <Input
        field={{
          NAME: 'companyName',
          ID: 'profiler_company_name',
          PLACEHOLDER: 'Provide company name & legal form',
          REQUIRED: true,
          TYPE: FIELDS_TYPES.TYPE_TEXT
        }}
        value={''}
        setFieldValue={() => {}}
        showOnSubmitErrorState={true}
        messages={[]}
        disabled
      />
      <button type="submit" className="btn btn-primary disable-fade-primary" disabled={true}>
        Search
      </button>
    </form>
  );
});

export default SearchNameFormMobile;
