import { PROFILER_SEARCH_CATEGORIES } from '../../../../utils/constants/searchCategories';
import { PROFILER_SEARCH_FIELDS } from '../../../../utils/constants/fields';
import Input from '../../../../components/inputs/Input';
import RangeInput from '../../../../components/inputs/RangeInput';

export const getResultSearchFields = (state) => {
  const disableInputsByLoader = state.isLoading || state.isInitialLoading;

  return {
    [PROFILER_SEARCH_CATEGORIES.INDUSTRY]: {
      [PROFILER_SEARCH_FIELDS.INDUSTRY.ID]: (
        <Input
          field={PROFILER_SEARCH_FIELDS.INDUSTRY}
          value={state.fields[PROFILER_SEARCH_FIELDS.INDUSTRY.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={disableInputsByLoader}
          messages={state.validationFields.messages}
          showOnSubmitErrorState={state.onSubmitErrorState}
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [PROFILER_SEARCH_FIELDS.VALUE_CHAIN.ID]: (
        <Input
          field={PROFILER_SEARCH_FIELDS.VALUE_CHAIN}
          value={state.fields[PROFILER_SEARCH_FIELDS.VALUE_CHAIN.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={disableInputsByLoader}
          messages={state.validationFields.messages}
          showOnSubmitErrorState={state.onSubmitErrorState}
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [PROFILER_SEARCH_FIELDS.PRODUCT_PORTFOLIO.ID]: (
        <Input
          field={PROFILER_SEARCH_FIELDS.PRODUCT_PORTFOLIO}
          value={state.fields[PROFILER_SEARCH_FIELDS.PRODUCT_PORTFOLIO.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={disableInputsByLoader}
          messages={state.validationFields.messages}
          showOnSubmitErrorState={state.onSubmitErrorState}
          inputWrap={{ enable: true, className: 'col' }}
        />
      )
    },
    [PROFILER_SEARCH_CATEGORIES.FINANCIALS]: {
      [PROFILER_SEARCH_FIELDS.REVENUE_MIN.ID + PROFILER_SEARCH_FIELDS.REVENUE_MAX.ID]: (
        <RangeInput
          fieldMin={PROFILER_SEARCH_FIELDS.REVENUE_MIN}
          fieldMax={PROFILER_SEARCH_FIELDS.REVENUE_MAX}
          valueMin={state.fields[PROFILER_SEARCH_FIELDS.REVENUE_MIN.NAME]}
          valueMax={state.fields[PROFILER_SEARCH_FIELDS.REVENUE_MAX.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={disableInputsByLoader}
          messages={state.validationFields.messages}
          showOnSubmitErrorState={state.onSubmitErrorState}
          wrapClass={'col'}
        />
      ),
      [PROFILER_SEARCH_FIELDS.EMPLOYEES_MIN.ID + PROFILER_SEARCH_FIELDS.EMPLOYEES_MAX.ID]: (
        <RangeInput
          fieldMin={PROFILER_SEARCH_FIELDS.EMPLOYEES_MIN}
          fieldMax={PROFILER_SEARCH_FIELDS.EMPLOYEES_MAX}
          valueMin={state.fields[PROFILER_SEARCH_FIELDS.EMPLOYEES_MIN.NAME]}
          valueMax={state.fields[PROFILER_SEARCH_FIELDS.EMPLOYEES_MAX.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={disableInputsByLoader}
          messages={state.validationFields.messages}
          showOnSubmitErrorState={state.onSubmitErrorState}
          wrapClass={'col'}
        />
      )
      // [PROFILER_SEARCH_FIELDS.EBITDA_MIN.ID + PROFILER_SEARCH_FIELDS.EBITDA_MAX.ID]: (
      //   <RangeInput
      //     fieldMin={PROFILER_SEARCH_FIELDS.EBITDA_MIN}
      //     fieldMax={PROFILER_SEARCH_FIELDS.EBITDA_MAX}
      //     valueMin={state.fields[PROFILER_SEARCH_FIELDS.EBITDA_MIN.NAME]}
      //     valueMax={state.fields[PROFILER_SEARCH_FIELDS.EBITDA_MAX.NAME]}
      //     setFieldValue={state.setFieldValue}
      //     disabled={disableInputsByLoader}
      //     messages={state.validationFields.messages}
      //     showOnSubmitErrorState={state.onSubmitErrorState}
      //     wrapClass={'col'}
      //   />
      // ),
      // [PROFILER_SEARCH_FIELDS.EBITDA_MARGIN.ID]: (
      //   <Input
      //     field={PROFILER_SEARCH_FIELDS.EBITDA_MARGIN}
      //     value={state.fields[PROFILER_SEARCH_FIELDS.EBITDA_MARGIN.NAME]}
      //     setFieldValue={state.setFieldValue}
      //     disabled={disableInputsByLoader}
      //     messages={state.validationFields.messages}
      //     showOnSubmitErrorState={state.onSubmitErrorState}
      //     inputWrap={{ enable: true, className: 'col' }}
      //   />
      // )
    }
  };
};
