import { observer, useLocalObservable } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import {
  selectedProjectTabPageSize,
  SELECTED_PROJECT_TABS,
  encodeProjectsParams
} from '../constants';
import ProfilerSearchResultsTabEntry from './ProfilerSearchResultsTabEntry';
import ReactPaginate from 'react-paginate';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';
import { useStore } from '../../../store/store';

const ProfilerSearchResultsTabView = observer(
  ({
    project = {},
    results = [],
    forTab = SELECTED_PROJECT_TABS.RESULTS,
    params = {},
    excludeResult = () => {},
    isExcludingResult = false,
    displayDetails = () => {}
  }) => {
    const { utilsStore } = useStore();
    const { navigate } = useHistory();

    const state = useLocalObservable(() => ({
      forTab: forTab,
      setForTab: (value = '') => (state.forTab = value),
      get currentResults() {
        return state.forTab === SELECTED_PROJECT_TABS.RESULTS
          ? results.filter((r) => !r.isExcluded)
          : results.filter((r) => r.isExcluded);
      }
    }));

    useEffect(() => {
      state.setForTab(forTab);
    }, [state, forTab]);

    const showPagination = state.currentResults.length > selectedProjectTabPageSize;
    const pages = Math.ceil(state.currentResults.length / selectedProjectTabPageSize) || 1;
    const page = (params.selectedProjectTabPage || 1) - 1;

    return (
      <div className="table-responsive">
        {state.currentResults.length === 0 ? (
          <div className="no-data">No items for this tab</div>
        ) : (
          <>
            <div className="header profiler-search-results-tab-view-header">
              <div className="item company-name-item ">Company name</div>
              <div className="item">Company HQ</div>
              <div className="item">Industry</div>
              <div className="item">Value chain</div>
              <div className="item">Products</div>
              <div className="item text-align-right-desktop revenue-item">Revenue (€M)</div>
              <div className="item text-align-right-desktop employees-item">Employees</div>
              <div className="item feedback-item-profiler">Feedback results</div>
              <div className="item actions-profiler" />
            </div>

            {state.currentResults
              .slice(
                (params.selectedProjectTabPage - 1) * selectedProjectTabPageSize,
                params.selectedProjectTabPage * selectedProjectTabPageSize
              )
              .map((entry) => (
                <ProfilerSearchResultsTabEntry
                  key={entry.id}
                  entry={entry}
                  project={project}
                  forTab={forTab}
                  excludeResult={excludeResult}
                  isExcludingResult={isExcludingResult}
                  displayDetails={displayDetails}
                />
              ))}

            {showPagination && (
              <ReactPaginate
                onPageChange={({ selected }) => {
                  navigate(
                    paths.PROFILER_PROJECTS +
                      '?params=' +
                      encodeProjectsParams({ ...params, selectedProjectTabPage: selected + 1 })
                  );
                  setTimeout(() => {
                    const el = document.getElementById('project-view-tab-1');
                    const header = document.querySelector('header');
                    if (el) {
                      const headerHeight = header?.clientHeight || 0;
                      window.scrollTo({ top: el.offsetTop - headerHeight, behavior: 'smooth' });
                    }
                  }, 100);
                }}
                forcePage={page}
                pageCount={pages}
                pageRangeDisplayed={utilsStore.windowWidth < 481 ? 1 : selectedProjectTabPageSize}
                marginPagesDisplayed={utilsStore.windowWidth < 481 ? 1 : selectedProjectTabPageSize}
                renderOnZeroPageCount={null}
                breakLabel="..."
                nextLabel="Next"
                previousLabel="Prev"
                containerClassName="pagination-container"
                pageClassName="page"
                pageLinkClassName="pageLink"
                nextClassName="next"
                nextLinkClassName="nextLink"
                previousClassName="prev"
                previousLinkClassName="prevLink"
                breakClassName="break"
                breakLinkClassName="breakLink"
              />
            )}
          </>
        )}
      </div>
    );
  }
);

export default ProfilerSearchResultsTabView;
