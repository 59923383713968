import StaticPageLayout from '../../../components/layouts/StaticPageLayout';

const Imprint = () => {
  return (
    <StaticPageLayout page="tos">
      <h1>Imprint</h1>
      <div className="static-content">
        <div className="content-body">
          <div className="content-large">
            Information pursuant to Sect. 5 German Telemedia Act (TMG)
          </div>
        </div>

        <div className="content-body">
          transact.digital GmbH
          <br />
          Neue Rothofstrasse 13-19
          <br />
          60313 Frankfurt am Main
          <br />
          <br />
          Commercial register: HRB 132251
          <br />
          Registration court: Frankfurt am Main
          <br />
        </div>
        <div className="content-body">
          <div className="content-large">Managing Director:</div>
          Hadi Manesh
        </div>
        <div className="content-body">
          <div className="content-large">Contact</div>
          Phone: +49 69 66779969 <br />
          E-mail: info@transact.digital
        </div>

        <div className="content-body">
          <div className="content-large">VAT ID</div>
          <div className="content-large">
            Sales tax identification number according to Sect. 27 a of the Sales Tax Law:
          </div>
          DE363568400
        </div>

        <div className="content-body">
          <div className="content-large">Person responsible for editorial</div>
          transact.digital GmbH
        </div>

        <div className="content-body">
          Neue Rothofstrasse 13-19
          <br />
          60313 Frankfurt am Main
          <br />
          Germany
        </div>

        <div className="content-body">
          <div className="content-large">
            Dispute resolution proceedings in front of a consumer arbitration board
          </div>
          We are not willing or obliged to participate in dispute resolution proceedings in front of
          a consumer arbitration board.
        </div>
      </div>
    </StaticPageLayout>
  );
};

export default Imprint;
