import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { paths } from '../../../utils/constants/routes';
import { API_ENDPOINTS } from '../../../api/endpoints';
import { useEffect } from 'react';
import StaticPageLayout from '../../../components/layouts/StaticPageLayout';
import MobileFilters from './components/MobileFilters';
import {
  SEARCH_FIELDS,
  getErrorFields,
  PORTFOLIO_FIELDS,
  FUND_FIELDS,
  compareFields,
  trimStateFields,
  isEmptyValue,
  INVEST_PROFILE_FIELDS
} from '../../../utils/constants/fields';
import {
  getFilledSearchCategoriesCount,
  mapFieldsToState,
  formatNumber
} from '../../../utils/utils';
import { mapData } from '../../../api/dataMappers';
import FundResult from './components/FundResult';
import PortfolioResult from './components/PortfolioResult';
import { UI_OPTIONS } from '../../../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../../../utils/constants/uiOptionKeys';
import { SEARCH_CATEGORIES } from '../../../utils/constants/searchCategories';
import { runInAction } from 'mobx';
import { DETAILS_POUP_TABS } from './constants/tabs';
import DetailsPopup from './components/DetailsPopup';
import MessagePopup from './components/MessagePopup';
import DesktopFilters from './components/DesktopFilters';
import { getResultSearchFields } from './constants/resultSearchFields';
import SaveSearchPopup from './components/SaveSearchPopup';
import NoResults from './components/NoResults';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import GeneralError from '../../../components/errors/GeneralError';
import useHistory from '../../../hooks/useHistory';
import { eventDownloadSearch } from '../../../ga4/ga4';
import InvestProfileResult from './components/InvestProfileResult';
import ReactPaginate from 'react-paginate';
import IconButton, {
  ICON_BUTTON_ICONS,
  ICON_BUTTON_TYPES
} from '../../../components/buttons/IconButton';
import { HEADER_MESSAGE_TYPES } from '../../../utils/constants/header';
import MassOutreachPopup from './components/MassOutreachPopup';
import agentSearchSortOptions from './constants/agentSearchSortOptions';

const pageSize = 8;

const SearchResults = observer(() => {
  const { navigate, location } = useHistory();
  const { projectStore, outreachStore, utilsStore, makeRequest, authStore } = useStore();

  const state = useLocalObservable(() => {
    const urlParams = new URLSearchParams(location.search);
    let searchType;
    try {
      searchType = JSON.parse(decodeURIComponent(urlParams.get(SEARCH_FIELDS.SEARCH_TYPE.NAME)));
    } catch (err) {
      // w/e
    }

    if (!['funds', 'portfolios', 'investprofiles'].includes(searchType?.[0])) {
      searchType = null;
    }

    return {
      isRendered: false,
      setIsRendered: (value = false) => (state.isRendered = value),
      searchType: searchType || [],
      loadingSearchType: searchType || [],
      results: [],
      page: 1,
      pages: 1,
      searchId: null,
      paginatedSearchId: null,
      isLoading: false,
      isInitialLoading: true,
      isMobileFiltersDisplayed: false,
      toggleMobileFilters: () => {
        state.isMobileFiltersDisplayed = !state.isMobileFiltersDisplayed;
        if (state.isMobileFiltersDisplayed) {
          window.scrollTo({ top: 0 });
        }
      },
      detailsPopupDisplayed: null,
      displayDetailsPopup: (entry) => {
        if (entry) {
          utilsStore.lockScroll(true);
        } else {
          utilsStore.lockScroll(false);
        }
        state.detailsPopupDisplayed = entry;
      },
      detailsPopupSelectedTab: DETAILS_POUP_TABS.GENERAL,
      setDetailsPopupSelectedTab: (value) => {
        state.detailsPopupSelectedTab = value;
      },
      messagePopupDisplayed: null,
      displayMessagePopup: (entry) => {
        if (entry) {
          utilsStore.lockScroll(true);
        } else {
          utilsStore.lockScroll(false);
        }
        state.messagePopupDisplayed = entry;
      },
      saveSearchPopupDisplayed: null,
      toggleSaveProjectPopup: (entry) => {
        state.saveSearchPopupDisplayed = entry;
        if (entry) {
          utilsStore.lockScroll(true);
        } else {
          utilsStore.lockScroll(false);
        }
      },
      massOutreachPopupDisplayed: false,
      toggleMassOutreachPopup: (toggle = false) => {
        state.massOutreachPopupDisplayed = toggle;
        if (toggle) {
          utilsStore.lockScroll(true);
        } else {
          utilsStore.lockScroll(false);
        }
      },
      resultsError: null,
      failedReqParams: null,
      getResults: (searchFields = {}, searchId = null) => {
        state.isLoading = true;
        state.resultsError = false;
        state.searchId = null;
        state.loadingSearchType = searchFields[SEARCH_FIELDS.SEARCH_TYPE.NAME];
        const body = { ...mapData(searchFields, SEARCH_FIELDS, true) };
        if (['number', 'string'].includes(typeof searchId)) {
          body.searchId = searchId;
        }

        makeRequest({
          endpoint: API_ENDPOINTS.GET_SEARCH_RESULTS,
          body,
          onSuccess: ({ results, searchId: newSearchId }) => {
            if (!state.isRendered) {
              return;
            }

            const mapFields =
              state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
                ? FUND_FIELDS
                : state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']
                ? PORTFOLIO_FIELDS
                : state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Corporates
                ? INVEST_PROFILE_FIELDS
                : 'unknown';

            state.currentSearch = JSON.parse(JSON.stringify(searchFields));
            state.searchType = searchFields[SEARCH_FIELDS.SEARCH_TYPE.NAME];
            state.failedReqParams = null;
            state.results = mapData(results, mapFields);
            state.searchId = newSearchId;
          },
          onError: (errorMessage) => {
            if (!state.isRendered) {
              return;
            }

            state.resultsError = errorMessage || 'Failed to obtain search results';
            state.failedReqParams = JSON.parse(JSON.stringify([searchFields, searchId]));
          },
          onFinally: () => {
            if (!state.isRendered) {
              return;
            }

            state.isLoading = false;
            if (state.isInitialLoading) {
              state.isInitialLoading = false;
            }
          }
        });
      },
      fundsInAction: {},
      portfoliosInAction: {},
      investProfilesInAction: {},
      currentSearch: {},
      fields: mapFieldsToState(SEARCH_FIELDS),
      setFieldValue: (field = {}, value) => {
        state.fields[field.NAME] = value;
      },
      get fieldsCount() {
        return getFilledSearchCategoriesCount(state.fields);
      },
      searchSectionsToggle: Object.fromEntries(
        Object.values(SEARCH_CATEGORIES).map((section) => [section, false])
      ),
      toggleSearchSection: (section) => {
        state.searchSectionsToggle[section] = !state.searchSectionsToggle[section];
        if (state.searchSectionsToggle[section]) {
          setTimeout(() => {
            const sectionDOM = document.getElementById(`desktop-filter-category-${section}`);
            if (sectionDOM && state.searchSectionsToggle[section]) {
              const parentDOM = sectionDOM.parentElement;
              parentDOM.scrollTo({ behavior: 'smooth', top: sectionDOM.offsetTop + 1 });
            }
          }, 10);
        }
      },
      onSubmitErrorState: false,
      setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
      isSavingSearch: false,
      get projectId() {
        return projectStore.allProjects.find((p) => p.searchId === state.searchId)?.id;
      },
      get validationFields() {
        return getErrorFields(Object.values(SEARCH_FIELDS), state.fields);
      },
      get isSearchDisabled() {
        return (
          state.isLoading ||
          state.isInitialLoading ||
          state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
          (state.onSubmitErrorState && state.validationFields.invalidFields.length) ||
          !compareFields(state.fields, state.currentSearch)
        );
      },
      onSearch: (e) => {
        e?.preventDefault?.();

        trimStateFields(state.fields);
        if (state.validationFields.invalidFields.length) {
          if (!state.onSubmitErrorState) {
            state.setOnSubmitErrorState(true);
          }
          return;
        }

        if (state.isMobileFiltersDisplayed) {
          state.toggleMobileFilters();
        }

        state.selectedSort = 'relevance';

        const fieldsParams = Object.entries(state.fields)
          .filter((field) => field[1].length)
          .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
          .join('&');
        navigate(paths.SEARCH_RESULTS + `?page=1&${fieldsParams}`);
        document.getElementsByClassName('page-search-listing')[0].scrollTo({ top: 0 });
      },
      onSubmitMessage: (id, subject, message, documents, onSuccess, onError) => {
        const isFund = state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds;
        const isPortfolio =
          state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'];
        const actionObjKey = isFund
          ? 'fundsInAction'
          : isPortfolio
          ? 'portfoliosInAction'
          : 'investProfilesInAction';
        const actionObj = state[actionObjKey];

        actionObj[id] = true;
        outreachStore.sendOutreach(
          state.projectId,
          [id],
          subject,
          message,
          documents,
          onSuccess,
          onError,
          () => {
            actionObj[id] = false;
          }
        );
      },
      onMassOutreach: (ids = [], subject, message, documents, onSuccess, onError) => {
        outreachStore.sendOutreach(
          state.projectId,
          ids,
          subject,
          message,
          documents,
          onSuccess,
          onError
        );
      },
      onSaveSearch: (searchName, onSuccess, onError, onFinally) => {
        state.isSavingSearch = true;
        projectStore.saveProject(state.searchId, searchName, onSuccess, onError, () => {
          state.isSavingSearch = false;
          onFinally();
        });
      },
      excludingResults: {},
      onExcludeResult: (entry, exclude = true) => {
        runInAction(() => (state.excludingResults[entry.id] = true));
        makeRequest({
          endpoint: API_ENDPOINTS.EXCLUDE_PROJECT_RESULT,
          body: {
            ...(state.projectId ? { projectId: state.projectId } : { searchId: state.searchId }),
            resultId: entry.id,
            exclude
          },
          onSuccess: () => {
            entry.isExcluded = exclude;
          },
          onError: (errorMessage) => {
            utilsStore.setHeaderMessage(
              errorMessage ||
                `Failed to ${exclude ? 'exclude' : 'restore'} result with name ${entry.name}.`,
              HEADER_MESSAGE_TYPES.ERROR
            );
          },
          onFinally: () => {
            state.excludingResults[entry.id] = false;
          }
        });
      },
      isExcludedExpanded: false,
      toggleExcludedExpanded: () => (state.isExcludedExpanded = !state.isExcludedExpanded),
      uiFormState: {
        isEBITDAFieldFocused: false,
        isEBITDAMarginFieldFocused: false,
        get hasRevenueValue() {
          return !!state.fields[SEARCH_FIELDS.REVENUE.NAME]?.length;
        },
        get isEBITDAFieldDisabled() {
          return !state.uiFormState.hasRevenueValue || state.uiFormState.isEBITDAMarginFieldFocused;
        },
        get isEBITDAMarginFieldDisabled() {
          return !state.uiFormState.hasRevenueValue || state.uiFormState.isEBITDAFieldFocused;
        },
        onRevenueChange: (revenue) => {
          const hasValue = !!revenue?.length;
          if (hasValue) {
            const EBITDAValue = state.fields[SEARCH_FIELDS.EBITDA.NAME];
            const EBITDAMarginValue = state.fields[SEARCH_FIELDS.EBITDA_MARGIN.NAME];
            if (EBITDAValue) {
              state.fields[SEARCH_FIELDS.EBITDA_MARGIN.NAME] = formatNumber(
                ((+EBITDAValue / +revenue) * 100).toFixed(2),
                ''
              );
            } else if (EBITDAMarginValue) {
              state.fields[SEARCH_FIELDS.EBITDA.NAME] = formatNumber(
                ((+revenue * +EBITDAMarginValue) / 100).toFixed(2),
                ''
              );
            }
          }
        },
        onEBITDAChange: (EBITDA) => {
          const hasValue = !!EBITDA?.length;
          if (hasValue) {
            const revenue = state.fields[SEARCH_FIELDS.REVENUE.NAME];
            state.fields[SEARCH_FIELDS.EBITDA_MARGIN.NAME] = formatNumber(
              ((+EBITDA / +revenue) * 100).toFixed(2),
              ''
            );
          }
        },
        onEBITDAFocus: () => {
          state.uiFormState.isEBITDAFieldFocused = true;
        },
        onEBITDABlur: () => {
          state.uiFormState.isEBITDAFieldFocused = false;
        },
        onEBITDAMarginChange: (EBITDAMargin) => {
          const hasValue = !!EBITDAMargin?.length;
          if (hasValue) {
            const revenue = state.fields[SEARCH_FIELDS.REVENUE.NAME];
            state.fields[SEARCH_FIELDS.EBITDA.NAME] = formatNumber(
              ((+revenue * +EBITDAMargin) / 100).toFixed(2),
              ''
            );
          }
        },
        onEBITDAMarginFocus: () => {
          state.uiFormState.isEBITDAMarginFieldFocused = true;
        },
        onEBITDAMarginBlur: () => {
          state.uiFormState.isEBITDAMarginFieldFocused = false;
        }
      },
      selectedSort: 'relevance',
      selectSortOption: (value = '') => {
        state.selectedSort = value;
        const fieldsParams = Object.entries(state.currentSearch)
          .filter((field) => field[1].length)
          .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
          .join('&');
        navigate(
          paths.SEARCH_RESULTS +
            `?page=${1}&${state.searchId ? `searchId=${state.searchId}&` : ''}${fieldsParams}`
        );
        setTimeout(() => {
          if (
            utilsStore.windowWidth > 480 &&
            window.scrollY &&
            Math.floor(window.scrollY) > 24 + 23 - 16
          ) {
            window.scrollTo({ top: 24 + 23 - 16, behavior: 'smooth' });
          }
        }, 100);
      },
      isSortToggled: false,
      toggleSort: () => (state.isSortToggled = !state.isSortToggled),
      get sortOptions() {
        return agentSearchSortOptions.filter(
          (o) => !o.onlyForSearchType || o.onlyForSearchType === state.searchType?.[0]
        );
      },
      get sortedResults() {
        const results = state.results.slice().filter((entry) => !entry.isExcluded);

        if (state.selectedSort === 'name-asc') {
          results.sort((a, b) =>
            (a.name || a.companyName || '').localeCompare(b.name || b.companyName || '', 'en', {
              sensitivity: 'base'
            })
          );
        } else if (state.selectedSort === 'name-desc') {
          results.sort((a, b) =>
            (b.name || b.companyName || '').localeCompare(a.name || a.companyName || '', 'en', {
              sensitivity: 'base'
            })
          );
        } else if (state.selectedSort !== 'relevance') {
          const [propKey, typeSort] = state.selectedSort.split('-');
          const sorts = {
            asc: (propKey) => (a, b) =>
              +(a[propKey] || Number.MAX_SAFE_INTEGER) - +(b[propKey] || Number.MAX_SAFE_INTEGER),
            desc: (propKey) => (a, b) =>
              +(b[propKey] || -Number.MAX_SAFE_INTEGER / 3) -
              +(a[propKey] || -Number.MAX_SAFE_INTEGER / 3)
          };
          results.sort(sorts[typeSort](propKey));
        }

        return results;
      }
    };
  });

  useEffect(() => {
    state.setIsRendered(true);
    return () => {
      state.setIsRendered(false);
    };
  }, [state]);

  useEffect(() => {
    return () => {
      // close all the popups when exiting the page so the lock scroll can be released
      if (state.detailsPopupDisplayed) {
        state.displayDetailsPopup(null);
      }

      if (state.messagePopupDisplayed) {
        state.displayMessagePopup(null);
      }

      if (state.massOutreachPopupDisplayed) {
        state.toggleMassOutreachPopup(null);
      }

      if (state.saveSearchPopupDisplayed) {
        state.toggleSaveProjectPopup(null);
      }

      if (state.isMobileFiltersDisplayed) {
        state.toggleMobileFilters();
      }
    };
  }, [state]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    let hasInvalidParam = false;
    let searchFields = null;
    try {
      searchFields = Object.values(SEARCH_FIELDS).reduce((fields, f) => {
        fields[f.NAME] = JSON.parse(decodeURIComponent(urlParams.get(f.NAME)));
        if (f.REQUIRED && isEmptyValue(fields[f.NAME])) {
          hasInvalidParam = true;
        }
        return fields;
      }, {});
    } catch (err) {
      hasInvalidParam = true;
    }

    if (hasInvalidParam) {
      runInAction(() => {
        state.resultsError = 'Invalid search params were found in the URL.';
        if (state.isInitialLoading) {
          state.isInitialLoading = false;
        }
      });
      return;
    } else {
      if (!state.fields[SEARCH_FIELDS.SEARCH_TYPE.NAME][0]) {
        runInAction(() => {
          const initial = mapFieldsToState(SEARCH_FIELDS);
          Object.entries(searchFields).forEach(([fieldName, fieldValue]) => {
            state.fields[fieldName] = fieldValue || initial[fieldName];
          });
        });
      }

      const initial = mapFieldsToState(SEARCH_FIELDS);
      const queryFields = Object.fromEntries(
        Object.entries(searchFields).map(([fieldName, fieldValue]) => [
          fieldName,
          fieldValue || initial[fieldName]
        ])
      );

      if (compareFields(queryFields, state.currentSearch)) {
        const foundSearchId = urlParams.get('searchId');
        state.getResults(searchFields, foundSearchId);
      }
    }
  }, [state, location.search]);

  useEffect(() => {
    if (state.isLoading || state.isInitialLoading || !Object.values(state.currentSearch).length) {
      return;
    }

    const fieldsParams = Object.entries(state.currentSearch)
      .filter((field) => field[1].length)
      .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
      .join('&');

    let pages = Math.ceil(state.results.filter((r) => !r.isExcluded).length / pageSize) || 1;
    const urlParams = new URLSearchParams(location.search);
    const foundPage = urlParams.get('page');
    let page = foundPage ? +foundPage : 1;
    if (isNaN(page) || page < 1 || page > pages) {
      if (isNaN(page) || page < 1) {
        page = 1;
        navigate(
          paths.SEARCH_RESULTS +
            `?page=1&${state.searchId ? `searchId=${state.searchId}&` : ''}${fieldsParams}`
        );
      } else if (page > pages) {
        page = pages || 1;
        navigate(
          paths.SEARCH_RESULTS +
            `?page=${pages}&${state.searchId ? `searchId=${state.searchId}&` : ''}${fieldsParams}`
        );
      }
    } else {
      if (state.page !== page || state.pages !== pages) {
        runInAction(() => {
          state.page = page || 1;
          state.pages = pages;
        });
      }
    }

    if (state.paginatedSearchId !== state.searchId) {
      runInAction(() => (state.paginatedSearchId = state.searchId));
      navigate(
        paths.SEARCH_RESULTS +
          `?page=${page}&${state.searchId ? `searchId=${state.searchId}&` : ''}${fieldsParams}`
      );
    }
  }, [state, location.search, state.searchId, state.results.filter((r) => !r.isExcluded).length]);

  useEffect(() => {
    const handleClickOutsideSort = (event) => {
      const isClickInsideSortMenu = event.target.closest('.sort-menu-wrap');
      const isClickInsideSortButtonDesktop = event.target.closest('#sort-btn-desktop');
      const isClickInsideSortButtonMobile = event.target.closest('#sort-btn-mobile');

      if (
        isClickInsideSortMenu ||
        isClickInsideSortButtonDesktop ||
        isClickInsideSortButtonMobile
      ) {
        // do nothing
      } else {
        if (state.isSortToggled) {
          state.toggleSort();
        }
      }
    };

    document.addEventListener('click', handleClickOutsideSort);

    return () => {
      document.removeEventListener('click', handleClickOutsideSort);
    };
  }, [state]);

  const isTypeSelected = !!state.fields[SEARCH_FIELDS.SEARCH_TYPE.NAME][0];
  const fields = getResultSearchFields(state, isTypeSelected, state.uiFormState);

  const isLoading = state.isLoading || state.isInitialLoading;
  const hasResults = !!state.results.length;
  const hasError = !!state.resultsError;
  const showDetailsPopup = !!state.detailsPopupDisplayed;
  const showMessagePopup = !!state.messagePopupDisplayed;
  const showMassOutreachPopup = !!state.massOutreachPopupDisplayed;
  const showSaveSearchPopup = !!state.saveSearchPopupDisplayed;
  const showNoResults = !isLoading && !hasError && !hasResults;
  const showResults = !isLoading && !hasError && hasResults;
  const showPaging = showResults && state.results.length > pageSize;
  const canDownload = ['number', 'string'].includes(typeof state.searchId) && hasResults;
  const disableSaveSearch = !canDownload || projectStore.isLoadingProjects;
  const canSaveSearch = canDownload && !state.projectId;
  const fillSaveSearchIcon = !!state.projectId;
  const disableMassOutreach =
    !state.projectId ||
    !hasResults ||
    projectStore.isLoadingProjects ||
    outreachStore.isSendingOutreach;

  const excludedResults = state.results.filter((entry) => entry.isExcluded);
  const currentSearchType = state.loadingSearchType?.[0] || state.searchType?.[0];

  return (
    <StaticPageLayout page="search-listing" hideMobileFooter={true}>
      {showDetailsPopup && (
        <DetailsPopup
          searchType={state.searchType}
          detailsPopupDisplayed={state.detailsPopupDisplayed}
          detailsPopupSelectedTab={state.detailsPopupSelectedTab}
          setDetailsPopupSelectedTab={state.setDetailsPopupSelectedTab}
          displayDetailsPopup={state.displayDetailsPopup}
        />
      )}
      {showMessagePopup && (
        <MessagePopup
          messagePopupDisplayed={state.messagePopupDisplayed}
          displayMessagePopup={state.displayMessagePopup}
          submitMessage={state.onSubmitMessage}
          actionObj={
            state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
              ? state.fundsInAction
              : state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']
              ? state.portfoliosInAction
              : state.investProfilesInAction
          }
          forSearchType={state.searchType[0]}
        />
      )}
      {showMassOutreachPopup && (
        <MassOutreachPopup
          toggleMassOutreachPopup={state.toggleMassOutreachPopup}
          submitMessage={state.onMassOutreach}
          projectId={state.projectId}
          entries={state.results.filter((r) => !r.isExcluded)}
        />
      )}
      {showSaveSearchPopup && (
        <SaveSearchPopup
          toggleSaveProjectPopup={state.toggleSaveProjectPopup}
          onSaveSearch={state.onSaveSearch}
        />
      )}
      {state.isMobileFiltersDisplayed && (
        <MobileFilters
          onToggle={state.toggleMobileFilters}
          fields={fields}
          isSearchDisabled={state.isSearchDisabled}
          onSearch={state.onSearch}
        />
      )}
      {!state.isMobileFiltersDisplayed && (
        <div className="listing-layout">
          <DesktopFilters
            fields={fields}
            fieldsCount={state.fieldsCount}
            searchSectionsToggle={state.searchSectionsToggle}
            toggleSearchSection={state.toggleSearchSection}
            onSearch={state.onSearch}
            isSearchDisabled={state.isSearchDisabled}
          />
          <div className="results">
            <div className="mobile-filters">
              <div className="total-number">
                {!state.isInitialLoading && !state.isLoading
                  ? `${state.results.length} results`
                  : ' '}
              </div>
              <div className="filters-download-container">
                <div className="filters-btn" onClick={state.toggleMobileFilters}>
                  Filters
                </div>
                <div className="actions">
                  <IconButton
                    icon={ICON_BUTTON_ICONS.FLOPPY_2_FILL}
                    type={ICON_BUTTON_TYPES.BLUE}
                    filled={fillSaveSearchIcon}
                    onClick={() => {
                      if (canSaveSearch && !state.isSavingSearch) {
                        state.toggleSaveProjectPopup(true);
                      }
                    }}
                    disabled={disableSaveSearch || state.isSavingSearch}
                    tooltipText={state.projectId ? 'Search saved as project' : 'Save search'}
                  />
                  <IconButton
                    icon={ICON_BUTTON_ICONS.CHAT_RIGHT_DOTS}
                    type={ICON_BUTTON_TYPES.BLUE}
                    tooltipText={
                      !state.projectId
                        ? 'Save search result to initiate mass outreach'
                        : 'Mass outreach'
                    }
                    disabled={disableMassOutreach}
                    onClick={() => state.toggleMassOutreachPopup(true)}
                  />
                  <IconButton
                    icon={ICON_BUTTON_ICONS.DOWNLOAD}
                    type={ICON_BUTTON_TYPES.BLUE}
                    disabled={!canDownload || !authStore.userInfo.isCognitoExportSearch}
                    onClick={() => {
                      if (authStore.userInfo.isCognitoExportSearch) {
                        eventDownloadSearch();
                        window.open(
                          `${process.env.REACT_APP_API_BASEURL}/public/downloadSearch?searchId=${state.searchId}`,
                          '_blank',
                          'noopener, noreferrer'
                        );
                      }
                    }}
                    tooltipText={
                      authStore.userInfo.isCognitoExportSearch
                        ? 'Download search'
                        : 'Download feature is disabled. Please contact us: info@transact.digital'
                    }
                  />
                  <IconButton
                    id="sort-btn-mobile"
                    icon={
                      state.selectedSort !== 'relevance'
                        ? ICON_BUTTON_ICONS.OCTI_LIST_ORDERED
                        : ICON_BUTTON_ICONS.OCTI_LIST_UNORDERED
                    }
                    type={ICON_BUTTON_TYPES.BLUE}
                    tooltipText={
                      !isLoading && hasResults
                        ? state.selectedSort === 'relevance'
                          ? 'Sort'
                          : agentSearchSortOptions.find((o) => o.value === state.selectedSort)
                              ?.name || 'Sort'
                        : 'Sort is unavailable at this moment'
                    }
                    disabled={isLoading || !hasResults}
                    onClick={state.toggleSort}
                    filled={state.isSortToggled || state.selectedSort !== 'relevance'}
                  />
                  {state.isSortToggled && (
                    <div className="sort-menu-wrap">
                      {state.sortOptions.map((option) => {
                        return (
                          <IconButton
                            key={option.value}
                            hoverType={ICON_BUTTON_TYPES.BLUE}
                            withBorder={false}
                            innerText={option.shortName || option.name}
                            onClick={() =>
                              state.selectSortOption(option.value) & state.toggleSort()
                            }
                            disabled={state.selectedSort === option.value}
                            filled={state.selectedSort === option.value}
                            {...(option.icon ? { iconOnRight: true, icon: option.icon } : {})}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="results-header">
              <div className="cols">
                <div className="col">
                  {currentSearchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
                    ? 'Fund name'
                    : currentSearchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']
                    ? 'Portfolio name'
                    : currentSearchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Corporates
                    ? 'Invest profile name'
                    : 'Entry name'}
                </div>
                <div className="col">Industry focus</div>
                <div className="col">Geo focus</div>
                <div className="col right-align">Enterprise value (€M)</div>
                <div className="col right-align">Equity value (€M)</div>
              </div>
              <div className="actions default-search-buttons">
                <div className="total-num">
                  {!state.isInitialLoading && !state.isLoading
                    ? `${state.results.length} results`
                    : ' '}
                </div>
                <div className="buttons">
                  <IconButton
                    icon={ICON_BUTTON_ICONS.FLOPPY_2_FILL}
                    type={ICON_BUTTON_TYPES.BLUE}
                    filled={fillSaveSearchIcon}
                    onClick={() => {
                      if (canSaveSearch && !state.isSavingSearch) {
                        state.toggleSaveProjectPopup(true);
                      }
                    }}
                    disabled={disableSaveSearch || state.isSavingSearch}
                    tooltipText={state.projectId ? 'Search saved as project' : 'Save search'}
                  />
                  <IconButton
                    icon={ICON_BUTTON_ICONS.CHAT_RIGHT_DOTS}
                    type={ICON_BUTTON_TYPES.BLUE}
                    tooltipText={
                      !state.projectId
                        ? 'Save search result to initiate mass outreach'
                        : 'Mass outreach'
                    }
                    disabled={disableMassOutreach}
                    onClick={() => state.toggleMassOutreachPopup(true)}
                  />
                  <IconButton
                    icon={ICON_BUTTON_ICONS.DOWNLOAD}
                    type={ICON_BUTTON_TYPES.BLUE}
                    disabled={!canDownload || !authStore.userInfo.isCognitoExportSearch}
                    onClick={() => {
                      if (authStore.userInfo.isCognitoExportSearch) {
                        eventDownloadSearch();
                        window.open(
                          `${process.env.REACT_APP_API_BASEURL}/public/downloadSearch?searchId=${state.searchId}`,
                          '_blank',
                          'noopener, noreferrer'
                        );
                      }
                    }}
                    tooltipText={
                      authStore.userInfo.isCognitoExportSearch
                        ? 'Download search'
                        : 'Download feature is disabled. Please contact us: info@transact.digital'
                    }
                  />
                  <IconButton
                    id="sort-btn-desktop"
                    icon={
                      state.selectedSort !== 'relevance'
                        ? ICON_BUTTON_ICONS.OCTI_LIST_ORDERED
                        : ICON_BUTTON_ICONS.OCTI_LIST_UNORDERED
                    }
                    type={ICON_BUTTON_TYPES.BLUE}
                    tooltipText={
                      !isLoading && hasResults
                        ? state.selectedSort === 'relevance'
                          ? 'Sort'
                          : agentSearchSortOptions.find((o) => o.value === state.selectedSort)
                              ?.name || 'Sort'
                        : 'Sort is unavailable at this moment'
                    }
                    disabled={isLoading || !hasResults}
                    onClick={state.toggleSort}
                    filled={state.isSortToggled || state.selectedSort !== 'relevance'}
                  />
                  {state.isSortToggled && (
                    <div className="sort-menu-wrap">
                      {state.sortOptions.map((option) => {
                        return (
                          <IconButton
                            key={option.value}
                            hoverType={ICON_BUTTON_TYPES.BLUE}
                            withBorder={false}
                            innerText={option.shortName || option.name}
                            onClick={() =>
                              state.selectSortOption(option.value) & state.toggleSort()
                            }
                            disabled={state.selectedSort === option.value}
                            filled={state.selectedSort === option.value}
                            {...(option.icon ? { iconOnRight: true, icon: option.icon } : {})}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {isLoading && <GeneralLoader />}

            {hasError && (
              <GeneralError
                errorMessage={state.resultsError}
                actionMessage="You may want to:"
                {...(state.failedReqParams
                  ? {
                      actionButtontext: 'Try again',
                      onActionButtonClick: () => state.getResults(...state.failedReqParams)
                    }
                  : { withHomePageButton: true })}
              />
            )}

            {showNoResults && <NoResults />}

            {showResults &&
              state.sortedResults
                .slice((state.page - 1) * pageSize, state.page * pageSize)
                .map((entry) =>
                  state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds ? (
                    <FundResult
                      key={entry.id}
                      entry={entry}
                      displayMessagePopup={state.displayMessagePopup}
                      displayDetailsPopup={state.displayDetailsPopup}
                      projectId={state.projectId}
                      searchId={state.searchId}
                      excludeResult={state.onExcludeResult}
                      isInExcludeAction={!!state.excludingResults[entry.id]}
                    />
                  ) : state.searchType[0] ===
                    UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'] ? (
                    <PortfolioResult
                      key={entry.id}
                      entry={entry}
                      displayMessagePopup={state.displayMessagePopup}
                      displayDetailsPopup={state.displayDetailsPopup}
                      projectId={state.projectId}
                      searchId={state.searchId}
                      excludeResult={state.onExcludeResult}
                      isInExcludeAction={!!state.excludingResults[entry.id]}
                    />
                  ) : (
                    <InvestProfileResult
                      key={entry.id}
                      entry={entry}
                      displayMessagePopup={state.displayMessagePopup}
                      displayDetailsPopup={state.displayDetailsPopup}
                      projectId={state.projectId}
                      searchId={state.searchId}
                      excludeResult={state.onExcludeResult}
                      isInExcludeAction={!!state.excludingResults[entry.id]}
                    />
                  )
                )}

            {showResults && !!excludedResults.length && (
              <div className="excluded-results-wrap">
                <div className="excluded-results-box">
                  <div
                    className={`excluded-results-header${
                      state.isExcludedExpanded ? ' expanded' : ''
                    }`}>
                    <div className="excluded-results-header-text">
                      Excluded results ({excludedResults.length})
                    </div>
                    <IconButton
                      type={ICON_BUTTON_TYPES.DEFAULT}
                      icon={
                        state.isExcludedExpanded
                          ? ICON_BUTTON_ICONS.CHEVRON_UP
                          : ICON_BUTTON_ICONS.CHEVRON_DOWN
                      }
                      innerText={state.isExcludedExpanded ? 'Collapse' : 'Expand  '}
                      iconOnRight
                      withBorder={false}
                      onClick={state.toggleExcludedExpanded}
                      hoverType={ICON_BUTTON_TYPES.BLUE}
                    />
                  </div>
                  {state.isExcludedExpanded && (
                    <div>
                      {excludedResults.map((entry) =>
                        state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds ? (
                          <FundResult
                            key={entry.id}
                            entry={entry}
                            displayMessagePopup={state.displayMessagePopup}
                            displayDetailsPopup={state.displayDetailsPopup}
                            projectId={state.projectId}
                            excludeResult={state.onExcludeResult}
                            isInExcludeAction={!!state.excludingResults[entry.id]}
                          />
                        ) : state.searchType[0] ===
                          UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'] ? (
                          <PortfolioResult
                            key={entry.id}
                            entry={entry}
                            displayMessagePopup={state.displayMessagePopup}
                            displayDetailsPopup={state.displayDetailsPopup}
                            projectId={state.projectId}
                            excludeResult={state.onExcludeResult}
                            isInExcludeAction={!!state.excludingResults[entry.id]}
                          />
                        ) : (
                          <InvestProfileResult
                            key={entry.id}
                            entry={entry}
                            displayMessagePopup={state.displayMessagePopup}
                            displayDetailsPopup={state.displayDetailsPopup}
                            projectId={state.projectId}
                            excludeResult={state.onExcludeResult}
                            isInExcludeAction={!!state.excludingResults[entry.id]}
                          />
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            {showPaging && (
              <ReactPaginate
                onPageChange={({ selected }) => {
                  const fieldsParams = Object.entries(state.currentSearch)
                    .filter((field) => field[1].length)
                    .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
                    .join('&');
                  navigate(
                    paths.SEARCH_RESULTS +
                      `?page=${selected + 1}&${
                        state.searchId ? `searchId=${state.searchId}&` : ''
                      }${fieldsParams}`
                  );
                  setTimeout(() => {
                    if (utilsStore.windowWidth < 481) {
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                      return;
                    }

                    window.scrollTo({ top: 24 + 23 - 16, behavior: 'smooth' });
                  }, 100);
                }}
                forcePage={state.page - 1}
                pageRangeDisplayed={utilsStore.windowWidth < 481 ? 1 : pageSize}
                marginPagesDisplayed={utilsStore.windowWidth < 481 ? 1 : pageSize}
                pageCount={state.pages}
                renderOnZeroPageCount={null}
                breakLabel="..."
                nextLabel="Next"
                previousLabel="Prev"
                containerClassName="pagination-container"
                pageClassName="page"
                pageLinkClassName="pageLink"
                nextClassName="next"
                nextLinkClassName="nextLink"
                previousClassName="prev"
                previousLinkClassName="prevLink"
                breakClassName="break"
                breakLinkClassName="breakLink"
              />
            )}
          </div>
        </div>
      )}
    </StaticPageLayout>
  );
});

export default SearchResults;
