import Input from '../../../../components/inputs/Input';
import {
  USER_PROFILE_FIELDS,
  compareFields,
  getErrorFields
} from '../../../../utils/constants/fields';
import { useStore } from '../../../../store/store';
import { useLocalObservable } from 'mobx-react';
import { useEffect } from 'react';
import { runInAction } from 'mobx';
import { mapFieldsToState } from '../../../../utils/utils';
import MultiSelect from '../../../../components/inputs/MultiSelect';

const useUserTab = () => {
  const { authStore } = useStore();
  const state = useLocalObservable(() => ({
    fields: mapFieldsToState(USER_PROFILE_FIELDS),
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    onSubmitErrorState: false,
    setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
    get validationFields() {
      return getErrorFields(Object.values(USER_PROFILE_FIELDS), state.fields, [
        USER_PROFILE_FIELDS.PHONE_NUMBER
      ]);
    },
    get isRestoreDisabled() {
      if (!authStore.userInfo) {
        return true;
      }

      return !compareFields(state.fields, authStore.userInfo) || authStore.isUpdatingUserInfo;
    },
    get isSaveDisabled() {
      return (
        state.isRestoreDisabled ||
        state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
        (state.onSubmitErrorState && state.validationFields.invalidFields.length)
      );
    },
    onRestore: () => {
      const initial = mapFieldsToState(USER_PROFILE_FIELDS);
      Object.keys(state.fields).forEach((fieldName) => {
        state.fields[fieldName] = authStore.userInfo[fieldName] || initial[fieldName];
      });
    },
    onSave: () => {
      if (state.validationFields.invalidFields.length) {
        if (!state.onSubmitErrorState) {
          state.setOnSubmitErrorState(true);
        }
        return;
      }

      authStore.updateUserInfo(state.fields);
    }
  }));

  useEffect(() => {
    if (authStore.userInfo) {
      runInAction(() => {
        Object.entries(state.fields).forEach(([fieldName, fieldValue]) => {
          state.fields[fieldName] = authStore.userInfo[fieldName] || fieldValue;
        });
      });
    }
  }, [state, authStore.userInfo]);

  const { isRestoreDisabled, isSaveDisabled, onRestore, onSave } = state;

  const view = (
    <div className="form-body form-row-layout" key="tab-user">
      <div className="row">
        <div className="col">
          <Input
            field={USER_PROFILE_FIELDS.FIRST_NAME}
            value={state.fields[USER_PROFILE_FIELDS.FIRST_NAME.NAME]}
            setFieldValue={state.setFieldValue}
            disabled={authStore.isUpdatingUserInfo}
            messages={state.validationFields.messages}
            showOnSubmitErrorState={true}
          />
        </div>
        <div className="col">
          <Input
            field={USER_PROFILE_FIELDS.LAST_NAME}
            value={state.fields[USER_PROFILE_FIELDS.LAST_NAME.NAME]}
            setFieldValue={state.setFieldValue}
            disabled={authStore.isUpdatingUserInfo}
            messages={state.validationFields.messages}
            showOnSubmitErrorState={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Input
            field={USER_PROFILE_FIELDS.EMPLOYER}
            value={state.fields[USER_PROFILE_FIELDS.EMPLOYER.NAME]}
            setFieldValue={state.setFieldValue}
            disabled={true}
          />
        </div>
        <div className="col">
          <Input
            field={USER_PROFILE_FIELDS.JOB_POSITION}
            value={state.fields[USER_PROFILE_FIELDS.JOB_POSITION.NAME]}
            setFieldValue={state.setFieldValue}
            disabled={authStore.isUpdatingUserInfo}
            messages={state.validationFields.messages}
            showOnSubmitErrorState={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Input
            field={USER_PROFILE_FIELDS.EMAIL}
            value={state.fields[USER_PROFILE_FIELDS.EMAIL.NAME]}
            setFieldValue={state.setFieldValue}
            disabled={true}
          />
        </div>
        <div className="col">
          <Input
            field={USER_PROFILE_FIELDS.PHONE_NUMBER}
            value={state.fields[USER_PROFILE_FIELDS.PHONE_NUMBER.NAME]}
            setFieldValue={state.setFieldValue}
            disabled={authStore.isUpdatingUserInfo}
            showOnSubmitErrorState={state.onSubmitErrorState}
            messages={state.validationFields.messages}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Input
            field={USER_PROFILE_FIELDS.CITY}
            value={state.fields[USER_PROFILE_FIELDS.CITY.NAME]}
            setFieldValue={state.setFieldValue}
            disabled={authStore.isUpdatingUserInfo}
            messages={state.validationFields.messages}
            showOnSubmitErrorState={true}
          />
        </div>
        <div className="col">
          <MultiSelect
            field={USER_PROFILE_FIELDS.COUNTRY}
            value={state.fields[USER_PROFILE_FIELDS.COUNTRY.NAME]}
            setFieldValue={state.setFieldValue}
            disabled={authStore.isUpdatingUserInfo}
            withSingleClear
          />
        </div>
      </div>
    </div>
  );

  return { view, onSave, onRestore, isSaveDisabled, isRestoreDisabled };
};

export default useUserTab;
