import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { paths } from '../../../utils/constants/routes';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  FUNDS_VIEW_MODE,
  INVEST_PROFILES_VIEW_TABS,
  INVEST_PROFILES_VIEW_ANALYTICS_TABS_EVENTS
} from '../constants';
import {
  INVEST_PROFILE_FIELDS,
  getErrorFields,
  compareFields,
  trimStateFields,
  COMPANY_PROFILE_FIELDS,
  FIELDS_TYPES
} from '../../../utils/constants/fields';
import { runInAction } from 'mobx';
import Input from '../../../components/inputs/Input';
import TextArea from '../../../components/inputs/TextArea';
import MultiSelect from '../../../components/inputs/MultiSelect';
import RangeInput from '../../../components/inputs/RangeInput';
import { mapFieldsToState } from '../../../utils/utils';
import useHistory from '../../../hooks/useHistory';
import { eventPageviewInvestProfileCreate, eventPortfolioCoCreationStep } from '../../../ga4/ga4'; // TODO: Change
import { mapData } from '../../../api/dataMappers';
import { GA_EVENTS } from '../../../ga4/constants';

// TODO: Analytics events and field configs

const InvestProfileView = observer(({ mode = FUNDS_VIEW_MODE.CREATE, profile = null }) => {
  const { fundsStore, utilsStore, authStore } = useStore();
  const { goBack } = useHistory();
  const state = useLocalObservable(() => ({
    profileId: '',
    setProfileId: (id = '') => (state.profileId = id),
    fields: {
      ...mapFieldsToState(INVEST_PROFILE_FIELDS),
      [INVEST_PROFILE_FIELDS.NAME.NAME]:
        mode === FUNDS_VIEW_MODE.CREATE
          ? `Invest profile ${fundsStore.investProfiles.length + 1}`
          : ''
    },
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    prevTab: null,
    tab: INVEST_PROFILES_VIEW_TABS.INFORMATION,
    setTab: (value = '', id = '') => {
      [state.tab, state.prevTab] = [value, state.tab];
      const tabDOM = document.getElementById(id);
      if (tabDOM) {
        setTimeout(() => {
          tabDOM.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }, 0);
      }
    },
    onSubmitErrorState: false,
    setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
    get validationFields() {
      return getErrorFields(Object.values(INVEST_PROFILE_FIELDS), state.fields);
    },
    get isRestoreDisabled() {
      const hasDiff =
        mode === FUNDS_VIEW_MODE.CREATE
          ? !!Object.entries(state.fields).find(([fieldName, fieldValue]) => {
              if (fieldName === INVEST_PROFILE_FIELDS.NAME.NAME) {
                return fieldValue !== `Invest profile ${fundsStore.investProfiles.length + 1}`;
              }
              return Array.isArray(fieldValue) ? !!fieldValue.length : fieldValue !== '';
            })
          : compareFields(state.fields, profile);

      return (
        !hasDiff ||
        (mode === FUNDS_VIEW_MODE.CREATE && fundsStore.isCreatingInvestProfie) ||
        (mode === FUNDS_VIEW_MODE.EDIT && fundsStore.investProfilesInAction[profile.id])
      );
    },
    get isSaveDisabled() {
      return (
        state.isRestoreDisabled ||
        state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
        (state.onSubmitErrorState && state.validationFields.invalidFields.length)
      );
    },
    restore: () => {
      if (mode === FUNDS_VIEW_MODE.CREATE) {
        state.fields = {
          ...mapFieldsToState(INVEST_PROFILE_FIELDS),
          [INVEST_PROFILE_FIELDS.NAME.NAME]:
            mode === FUNDS_VIEW_MODE.CREATE
              ? `Invest profile ${fundsStore.investProfiles.length + 1}`
              : ''
        };
      } else {
        const initial = mapFieldsToState(INVEST_PROFILE_FIELDS);
        Object.entries(initial).forEach(([fieldName, initialFieldValue]) => {
          state.fields[fieldName] = profile[fieldName] || initialFieldValue;
        });
      }
    },
    getAnalyticsFormData: () => {
      const data = mapData(state.fields, INVEST_PROFILE_FIELDS, true);
      return Object.fromEntries(
        Object.values(INVEST_PROFILE_FIELDS).map((fieldConfig) => {
          const fieldName = fieldConfig.NAME;
          const fieldValue = fieldConfig.VALUES_KEY
            ? utilsStore.getOptionName(data[fieldName], fieldConfig)
            : data[fieldName];
          return [fieldName, fieldValue];
        })
      );
    }
  }));

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [fundsStore]);

  useEffect(() => {
    if (mode === FUNDS_VIEW_MODE.CREATE) {
      // TODO: Change
      // eventPageviewInvestProfileCreate();
    }
  }, [mode]);

  useEffect(() => {
    if (mode !== FUNDS_VIEW_MODE.CREATE || !state.prevTab) {
      return;
    }

    // TODO: Change
    // eventPortfolioCoCreationStep(
    //   INVEST_PROFILES_VIEW_ANALYTICS_TABS_EVENTS[state.prevTab],
    //   state.getAnalyticsFormData()
    // );
  }, [mode, state, state.prevTab]);

  useEffect(() => {
    if (mode === FUNDS_VIEW_MODE.CREATE) {
      return;
    }
    if (profile.id === state.profileId) {
      return;
    }

    if (mode === FUNDS_VIEW_MODE.EDIT && profile) {
      const initial = mapFieldsToState(INVEST_PROFILE_FIELDS);
      runInAction(() => {
        Object.keys(state.fields).forEach((fieldName) => {
          state.fields[fieldName] = profile[fieldName] || initial[fieldName];
        });
      });
    }
    state.setProfileId(profile.id);
  }, [state, mode, profile]);

  const goToFirstErroredTab = () => {
    setTimeout(() => {
      const firstErroredTabDOM = document.querySelector('div.tabs > div.tab.error-tab');
      const tab = tabConfig.find((t) => t.id === firstErroredTabDOM?.id);
      if (!tab || tab.path === state.tab) {
        return;
      }

      state.setTab(tab.path, tab.id);
    }, 10);
  };

  const tabConfig = [
    {
      path: INVEST_PROFILES_VIEW_TABS.INFORMATION,
      desktopLabel: 'Invest profile information',
      id: 'IPV_INF_TAB'
    },
    {
      path: INVEST_PROFILES_VIEW_TABS.GEO_INDUSTRIES,
      desktopLabel: 'Geo & Industries',
      id: 'IPV_GEO_TAB'
    },
    {
      path: INVEST_PROFILES_VIEW_TABS.FINANCIAL_METRICS,
      desktopLabel: 'Financials',
      id: 'IPV_FIN_TAB'
    },
    {
      path: INVEST_PROFILES_VIEW_TABS.TRANSACTION_TYPES,
      desktopLabel: 'Transaction',
      id: 'IPV_TRA_TAB'
    },
    { path: INVEST_PROFILES_VIEW_TABS.VALUATION, desktopLabel: 'Valuation', id: 'IPV_VAL_TAB' }
  ];

  const createProfile = () => {
    // TODO: Change
    // eventPortfolioCoCreationStep(
    //   INVEST_PROFILES_VIEW_ANALYTICS_TABS_EVENTS[state.tab],
    //   state.getAnalyticsFormData()
    // );

    trimStateFields(state.fields);
    if (state.validationFields.invalidFields.length) {
      if (!state.onSubmitErrorState) {
        state.setOnSubmitErrorState(true);
      }
      goToFirstErroredTab();
      return;
    }

    fundsStore.createInvestProfile(state.fields, () => {
      // TODO: Change
      // eventPortfolioCoCreationStep(
      //   GA_EVENTS.portfolio_co_create_complete,
      //   state.getAnalyticsFormData()
      // );
      goBack(paths.INVEST_PROFILES);
    });
  };

  const editProfile = () => {
    if (state.validationFields.invalidFields.length) {
      if (!state.onSubmitErrorState) {
        state.setOnSubmitErrorState(true);
      }
      goToFirstErroredTab();
      return;
    }

    fundsStore.editInvestProfile(profile, state.fields, () => {
      goBack(paths.INVEST_PROFILES);
    });
  };

  const onBackClick = (e) => {
    e?.preventDefault?.();
    goBack(paths.INVEST_PROFILES);
  };

  const erroredFields = Object.fromEntries(
    state.validationFields.invalidFields.map(({ name }) => [name, true])
  );

  const tabsInfo = [];
  tabConfig.forEach(({ path }) => {
    const tabInfo = { hasErrorField: false };
    tabsInfo.push(tabInfo);

    if (!state.onSubmitErrorState) {
      return;
    }

    const fields = Object.values(INVEST_PROFILE_FIELDS).filter(
      (f) => f.INVEST_PROFILE_VIEW_TAB === path
    );
    fields.forEach((fieldConfig) => {
      if (erroredFields[fieldConfig.NAME]) {
        tabInfo.hasErrorField = true;
      }
    });
  });

  const disableAllFields =
    mode === FUNDS_VIEW_MODE.CREATE
      ? fundsStore.isCreatingInvestProfie
      : fundsStore.investProfilesInAction[profile.id];

  return (
    <>
      <div className="header-backlink">
        <div className="gray-title-panel">
          <div className="title-section">
            <h4 className={state.fields[INVEST_PROFILE_FIELDS.NAME.NAME] ? '' : 'empty'}>
              {authStore?.userProfileCompany?.[COMPANY_PROFILE_FIELDS.COMPANY_NAME.NAME]}{' '}
              {state.fields[INVEST_PROFILE_FIELDS.NAME.NAME] || 'Invest profile name'}
            </h4>
          </div>
          <h6>Create and manage your invest profiles</h6>
          <div className="actions">
            <button
              className="btn btn-transparent btn-short disable-fade-transparent"
              disabled={state.isRestoreDisabled}
              onClick={state.restore}>
              Restore
            </button>
            <button
              className="btn btn-primary btn-short disable-fade-primary"
              disabled={state.isSaveDisabled}
              onClick={mode === FUNDS_VIEW_MODE.CREATE ? createProfile : editProfile}>
              Save
            </button>
          </div>
        </div>
        <Link to={paths.INVEST_PROFILES} className="backlink" onClick={onBackClick}>
          Back to my invest profiles
        </Link>
      </div>
      <div className="tabs">
        {tabConfig.map(({ path, desktopLabel, mobileLabel = '', id }, i) => {
          const isActive = state.tab === path;
          const isErrored = tabsInfo[i].hasErrorField;
          const tabClasNames = ['tab'];

          if (isActive) {
            tabClasNames.push('active');
          }

          if (isErrored && !isActive) {
            tabClasNames.push('error-tab');
          }

          return (
            <div
              key={path}
              id={id}
              className={tabClasNames.join(' ')}
              onClick={() => state.setTab(path, id)}>
              <div className="desktop-text">{desktopLabel}</div>
              <div className="mobile-text">{mobileLabel || desktopLabel}</div>
            </div>
          );
        })}
      </div>
      {state.tab === INVEST_PROFILES_VIEW_TABS.INFORMATION && (
        <div className="form-body form-row-layout">
          <div className="row">
            <Input
              field={{
                NAME: 'companyName',
                ID: 'company_name',
                LABEL: 'Company name',
                LABEL_DESCRIPTION: 'Official name of your company',
                TYPE: FIELDS_TYPES.TYPE_TEXT
              }}
              value={authStore?.userProfileCompany?.[COMPANY_PROFILE_FIELDS.COMPANY_NAME.NAME]}
              setFieldValue={() => {}}
              disabled={true}
              inputWrap={{ enable: true, className: 'col' }}
            />
            <Input
              field={INVEST_PROFILE_FIELDS.NAME}
              value={state.fields[INVEST_PROFILE_FIELDS.NAME.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              inputWrap={{ enable: true, className: 'col' }}
              showOnSubmitErrorState={true}
              messages={state.validationFields.messages}
            />
          </div>
          <div className="row">
            <Input
              field={INVEST_PROFILE_FIELDS.REVENUE}
              value={state.fields[INVEST_PROFILE_FIELDS.REVENUE.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              inputWrap={{ enable: true, className: 'col' }}
            />
            <Input
              field={INVEST_PROFILE_FIELDS.EBITDA}
              value={state.fields[INVEST_PROFILE_FIELDS.EBITDA.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              inputWrap={{ enable: true, className: 'col' }}
            />
          </div>
          <div className="row">
            <TextArea
              field={INVEST_PROFILE_FIELDS.DESCRIPTION}
              value={state.fields[INVEST_PROFILE_FIELDS.DESCRIPTION.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              messages={state.validationFields.messages}
              showOnSubmitErrorState={true}
              inputWrap={{ enable: true, className: 'col' }}
            />
          </div>
        </div>
      )}
      {state.tab === INVEST_PROFILES_VIEW_TABS.GEO_INDUSTRIES && (
        <div className="form-body form-row-layout">
          <div className="row">
            <MultiSelect
              field={INVEST_PROFILE_FIELDS.GEOGRAPHIES}
              value={state.fields[INVEST_PROFILE_FIELDS.GEOGRAPHIES.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              inputWrap={{ enable: true, className: 'col' }}
            />
            <MultiSelect
              field={INVEST_PROFILE_FIELDS.INDUSTRIES}
              value={state.fields[INVEST_PROFILE_FIELDS.INDUSTRIES.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              inputWrap={{ enable: true, className: 'col' }}
            />
          </div>
        </div>
      )}
      {state.tab === INVEST_PROFILES_VIEW_TABS.FINANCIAL_METRICS && (
        <div className="form-body form-row-layout">
          <div className="row">
            <RangeInput
              fieldMin={INVEST_PROFILE_FIELDS.REVENUE_RANGE_MIN}
              fieldMax={INVEST_PROFILE_FIELDS.REVENUE_RANGE_MAX}
              valueMin={state.fields[INVEST_PROFILE_FIELDS.REVENUE_RANGE_MIN.NAME]}
              valueMax={state.fields[INVEST_PROFILE_FIELDS.REVENUE_RANGE_MAX.NAME]}
              disabled={disableAllFields}
              setFieldValue={state.setFieldValue}
              messages={state.validationFields.messages}
              showOnSubmitErrorState={state.onSubmitErrorState}
              wrapClass={'col'}
            />
          </div>
          <div className="row">
            <RangeInput
              fieldMin={INVEST_PROFILE_FIELDS.EBITDA_RANGE_MIN}
              fieldMax={INVEST_PROFILE_FIELDS.EBITDA_RANGE_MAX}
              valueMin={state.fields[INVEST_PROFILE_FIELDS.EBITDA_RANGE_MIN.NAME]}
              valueMax={state.fields[INVEST_PROFILE_FIELDS.EBITDA_RANGE_MAX.NAME]}
              disabled={disableAllFields}
              setFieldValue={state.setFieldValue}
              messages={state.validationFields.messages}
              showOnSubmitErrorState={state.onSubmitErrorState}
              wrapClass={'col'}
            />
            <Input
              field={INVEST_PROFILE_FIELDS.EBITDA_MARGIN}
              value={state.fields[INVEST_PROFILE_FIELDS.EBITDA_MARGIN.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              inputWrap={{ enable: true, className: 'col' }}
            />
          </div>
          <div className="row">
            <RangeInput
              fieldMin={INVEST_PROFILE_FIELDS.EBIT_RANGE_MIN}
              fieldMax={INVEST_PROFILE_FIELDS.EBIT_RANGE_MAX}
              valueMin={state.fields[INVEST_PROFILE_FIELDS.EBIT_RANGE_MIN.NAME]}
              valueMax={state.fields[INVEST_PROFILE_FIELDS.EBIT_RANGE_MAX.NAME]}
              disabled={disableAllFields}
              setFieldValue={state.setFieldValue}
              messages={state.validationFields.messages}
              showOnSubmitErrorState={state.onSubmitErrorState}
              wrapClass={'col'}
            />
            <Input
              field={INVEST_PROFILE_FIELDS.EBIT_MARGIN}
              value={state.fields[INVEST_PROFILE_FIELDS.EBIT_MARGIN.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              inputWrap={{ enable: true, className: 'col' }}
            />
          </div>
        </div>
      )}
      {state.tab === INVEST_PROFILES_VIEW_TABS.TRANSACTION_TYPES && (
        <div className="form-body form-row-layout">
          <div className="row">
            <MultiSelect
              field={INVEST_PROFILE_FIELDS.SITUATION}
              value={state.fields[INVEST_PROFILE_FIELDS.SITUATION.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              inputWrap={{ enable: true, className: 'col' }}
            />
            <MultiSelect
              field={INVEST_PROFILE_FIELDS.EQUITY}
              value={state.fields[INVEST_PROFILE_FIELDS.EQUITY.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              inputWrap={{ enable: true, className: 'col' }}
            />
          </div>
        </div>
      )}
      {state.tab === INVEST_PROFILES_VIEW_TABS.VALUATION && (
        <div className="form-body form-row-layout">
          <div className="row">
            <RangeInput
              fieldMin={INVEST_PROFILE_FIELDS.ENTERPRISE_RANGE_MIN}
              fieldMax={INVEST_PROFILE_FIELDS.ENTERPRISE_RANGE_MAX}
              valueMin={state.fields[INVEST_PROFILE_FIELDS.ENTERPRISE_RANGE_MIN.NAME]}
              valueMax={state.fields[INVEST_PROFILE_FIELDS.ENTERPRISE_RANGE_MAX.NAME]}
              disabled={disableAllFields}
              setFieldValue={state.setFieldValue}
              messages={state.validationFields.messages}
              showOnSubmitErrorState={state.onSubmitErrorState}
              wrapClass={'col'}
            />
            <RangeInput
              fieldMin={INVEST_PROFILE_FIELDS.EQUITY_RANGE_MIN}
              fieldMax={INVEST_PROFILE_FIELDS.EQUITY_RANGE_MAX}
              valueMin={state.fields[INVEST_PROFILE_FIELDS.EQUITY_RANGE_MIN.NAME]}
              valueMax={state.fields[INVEST_PROFILE_FIELDS.EQUITY_RANGE_MAX.NAME]}
              disabled={disableAllFields}
              setFieldValue={state.setFieldValue}
              messages={state.validationFields.messages}
              showOnSubmitErrorState={state.onSubmitErrorState}
              wrapClass={'col'}
            />
          </div>
        </div>
      )}
    </>
  );
});

export default InvestProfileView;
