import { observer } from 'mobx-react';
import IconButton, {
  ICON_BUTTON_ICONS,
  ICON_BUTTON_TYPES
} from '../../../components/buttons/IconButton';

const SearchBulkFormDesktop = observer(() => {
  return (
    <form onSubmit={() => {}} className="desktop">
      <div className="fields-area">
        <div className="fields-area-title with-border-bottom">Company bulk search</div>
        <div className="buttons-row">
          <IconButton
            type={ICON_BUTTON_TYPES.BLUE}
            icon={ICON_BUTTON_ICONS.ARROW_DOWN}
            outerText="Download template"
            disabled
          />
          <IconButton
            type={ICON_BUTTON_TYPES.BLUE}
            icon={ICON_BUTTON_ICONS.PAPERCLIP}
            outerText="Upload company name list"
            isPDF
            disabled
          />
        </div>
      </div>
      <div className="separator profiler by-bulk"></div>

      <div className="submit-area">
        <button type="submit" className="btn btn-primary disable-fade-primary" disabled={true}>
          Search
        </button>
      </div>
    </form>
  );
});

export default SearchBulkFormDesktop;
