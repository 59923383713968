import { observer, useLocalObservable } from 'mobx-react';
import { DETAILS_POUP_TABS } from '../constants/tabs';
import FundGeneralInformation from './FundGeneralInformation';
import PortfolioGeneralInformation from './PortfolioGeneralInformation';
import FundInformation from './FundInformation';
import PortfolioInformation from './PortfolioInformation';
import { UI_OPTIONS } from '../../../../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../../../../utils/constants/uiOptionKeys';
import InvestProfileGeneralInformation from './InvestProfileGeneralInformation';
import InvestProfileInformation from './InvestProfileInformation';
import { useEffect } from 'react';

const DetailsPopup = observer(
  ({
    searchType,
    detailsPopupDisplayed,
    detailsPopupSelectedTab,
    setDetailsPopupSelectedTab,
    displayDetailsPopup
  }) => {
    const state = useLocalObservable(() => ({
      overlayHeight: window.visualViewport.height + 2000,
      updateOverlayHeight: () => (state.overlayHeight = window.visualViewport.height + 2000)
    }));

    useEffect(() => {
      const onViewportResize = () => {
        state.updateOverlayHeight();
      };
      window.visualViewport.addEventListener('resize', onViewportResize);
      return () => {
        window.visualViewport.removeEventListener('resize', onViewportResize);
      };
    }, [state]);

    return (
      <div className="overlay popup-details-overlay" style={{ height: state.overlayHeight + 'px' }}>
        <div className="container popup-details">
          <div className="header">
            <div className="tabs">
              <div
                className={`tab ${
                  detailsPopupSelectedTab === DETAILS_POUP_TABS.GENERAL ? 'active' : ''
                }`}
                onClick={() => setDetailsPopupSelectedTab(DETAILS_POUP_TABS.GENERAL)}>
                General information
              </div>
              <div
                className={`tab ${
                  detailsPopupSelectedTab === DETAILS_POUP_TABS.INFORMATION ? 'active' : ''
                }`}
                onClick={() => setDetailsPopupSelectedTab(DETAILS_POUP_TABS.INFORMATION)}>
                {searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
                  ? 'Fund information'
                  : searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']
                  ? 'Portfolio information'
                  : 'Invest profile information'}
              </div>
            </div>
            <div
              className="close"
              onClick={() => {
                displayDetailsPopup(null);
                setDetailsPopupSelectedTab(DETAILS_POUP_TABS.GENERAL);
              }}>
              Close
            </div>
          </div>
          {detailsPopupSelectedTab === DETAILS_POUP_TABS.GENERAL &&
            (searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds ? (
              <FundGeneralInformation entry={detailsPopupDisplayed} />
            ) : searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'] ? (
              <PortfolioGeneralInformation entry={detailsPopupDisplayed} />
            ) : (
              <InvestProfileGeneralInformation entry={detailsPopupDisplayed} />
            ))}
          {detailsPopupSelectedTab === DETAILS_POUP_TABS.INFORMATION &&
            (searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds ? (
              <FundInformation entry={detailsPopupDisplayed} />
            ) : searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'] ? (
              <PortfolioInformation entry={detailsPopupDisplayed} />
            ) : (
              <InvestProfileInformation entry={detailsPopupDisplayed} />
            ))}
        </div>
      </div>
    );
  }
);

export default DetailsPopup;
