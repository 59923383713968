import React, { useEffect, useMemo } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import StaticPageLayout from '../../../components/layouts/StaticPageLayout';
import useHistory from '../../../hooks/useHistory';
import { encodeProjectsParams } from '../../Projects/constants';
import { decodeSharedProjectsParams, SHARED_PROJECT_TYPE_TABS } from './constants';
import { paths } from '../../../utils/constants/routes';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import GeneralError from '../../../components/errors/GeneralError';
import ProfilerSharedProjectsList from './ProfilerSharedProjectsList';
import ProfilerRequestTypeButton from './ProfilerRequestTypeButton';
import ProfilerFeedbackRequestView from './ProfilerFeedbackRequestView';

const ProfilerTeam = observer(() => {
  const { location, navigate } = useHistory();
  const { profilerProjectStore, authStore, utilsStore } = useStore();
  const state = useLocalObservable(() => ({
    isRendered: true,
    setIsRendered: (isRendered = false) => (state.isRendered = isRendered),
    params: {},
    setParams: (params = {}) => {
      state.params = params;
    },
    get layout() {
      return utilsStore.windowWidth < 1201 ? 'mobile' : 'desktop';
    }
  }));
  useEffect(() => {
    return () => {
      state.setIsRendered();
    };
  }, [state]);

  useEffect(() => {
    if (profilerProjectStore.isLoadingProjectsData) {
      return;
    }
    let [urlParams, params, shouldNavigate] = [null, {}, false];
    try {
      urlParams = new URLSearchParams(location.search);
      params = decodeSharedProjectsParams(urlParams.get('params'));
    } catch (ex) {
      params = { projectTypeTab: SHARED_PROJECT_TYPE_TABS.PENDING };
      shouldNavigate = true;
    }
    if (typeof params !== 'object' || params === null || params === undefined) {
      params = {};
    }

    if (!Object.values(SHARED_PROJECT_TYPE_TABS).includes(params.projectTypeTab)) {
      params.projectTypeTab = SHARED_PROJECT_TYPE_TABS.PENDING;
      shouldNavigate = true;
    }

    if (shouldNavigate) {
      navigate(paths.PROFILER_TEAM + '?params=' + encodeProjectsParams(params));
    }

    state.setParams(params);
  }, [
    state,
    profilerProjectStore,
    profilerProjectStore.allProjects,
    profilerProjectStore.isLoadingProjectsData,
    location.search
  ]);

  const projectList = useMemo(() => {
    if (state.params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.PENDING) {
      return profilerProjectStore.pendingSharedProjects
        .slice()
        .sort(
          (a, b) =>
            b.sharedUsers.find((u) => u.id === authStore.currentUserId).dateReceived -
            a.sharedUsers.find((u) => u.id === authStore.currentUserId).dateReceived
        );
    } else {
      return profilerProjectStore.resolvedSharedProjects
        .slice()
        .sort(
          (a, b) =>
            b.sharedUsers.find((u) => u.id === authStore.currentUserId).dateResolved -
            a.sharedUsers.find((u) => u.id === authStore.currentUserId).dateResolved
        );
    }
  }, [
    profilerProjectStore.projects,
    state.params,
    profilerProjectStore.pendingSharedProjects.length,
    profilerProjectStore.resolvedSharedProjects.length
  ]);

  const selectedProject = useMemo(() => {
    if (state.params.selectedProjectId) {
      const projectList =
        state.params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.PENDING
          ? profilerProjectStore.pendingSharedProjects
          : profilerProjectStore.resolvedSharedProjects;
      return projectList.find((project) => project.id === state.params.selectedProjectId);
    }
    return null;
  }, [profilerProjectStore.projects, state.params]);

  const showError =
    profilerProjectStore.loadProjectsError && !profilerProjectStore.allProjects.length;

  const showProjectList =
    !showError &&
    Object.values(SHARED_PROJECT_TYPE_TABS).includes(state.params.projectTypeTab) &&
    !state.params.selectedProjectId;
  const showFeedbackRequest = !showError && state.params.selectedProjectId && selectedProject;

  const scrollMobileTabIntoView = (tabId = '') => {
    const tabDOM = document.getElementById(tabId);
    if (tabDOM) {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
          tabDOM.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }, 500);
      }, 100);
    }
  };

  const mobileTabs = [
    {
      id: 'team_mobile_open_requests_tab',
      name: `Open requests (${profilerProjectStore.pendingSharedProjects.length})`,
      onClick: () => {
        if (state.params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.PENDING) {
          return;
        }
        navigate(
          paths.PROFILER_TEAM +
            '?params=' +
            encodeProjectsParams({ projectTypeTab: SHARED_PROJECT_TYPE_TABS.PENDING })
        );
        scrollMobileTabIntoView('team_mobile_open_requests_tab');
      },
      isActive: state.params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.PENDING
    },
    {
      id: 'team_mobile_resolved_requests_tab',
      name: `Resolved requests (${profilerProjectStore.resolvedSharedProjects.length})`,
      onClick: () => {
        if (state.params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.RESOLVED) {
          return;
        }
        navigate(
          paths.PROFILER_TEAM +
            '?params=' +
            encodeProjectsParams({ projectTypeTab: SHARED_PROJECT_TYPE_TABS.RESOLVED })
        );
        scrollMobileTabIntoView('team_mobile_resolved_requests_tab');
      },
      isActive: state.params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.RESOLVED
    }
  ];

  const hideMobileFooter = state.layout === 'mobile';

  if (showError) {
    return (
      <StaticPageLayout
        page="team-feedback"
        hideMobileFooter={hideMobileFooter}
        hideMobileFooter1200={hideMobileFooter}>
        {showError && (
          <GeneralError
            errorMessage={profilerProjectStore.loadProjectsError}
            actionMessage="You may want to:"
            withHomePageButton={true}
            actionButtontext="Try again"
            onActionButtonClick={profilerProjectStore.loadProjects}
          />
        )}
      </StaticPageLayout>
    );
  }
  if (
    profilerProjectStore.initialLoadProjects ||
    (profilerProjectStore.isLoadingProjects && !profilerProjectStore.allProjects.length)
  ) {
    return (
      <StaticPageLayout
        page="team-feedback"
        hideMobileFooter={hideMobileFooter}
        hideMobileFooter1200={hideMobileFooter}>
        <GeneralLoader />
      </StaticPageLayout>
    );
  }

  if (state.layout === 'mobile') {
    return (
      <StaticPageLayout page="team-feedback" hideMobileFooter={true} hideMobileFooter1200={true}>
        {showProjectList && (
          <>
            <div className="mobile-header-main">
              <div className="title">Team feedback</div>
              <div className="tabs-scrollable-wrap">
                <div className="tabs-wrap">
                  {mobileTabs.map((tab) => {
                    return (
                      <div
                        key={tab.id}
                        id={tab.id}
                        className={`noselect tab ${tab.isActive ? 'active' : ''}`}
                        onClick={tab.onClick}>
                        {tab.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <ProfilerSharedProjectsList projectList={projectList} params={state.params} />
          </>
        )}
        {showFeedbackRequest && (
          <ProfilerFeedbackRequestView project={selectedProject} params={state.params} />
        )}
      </StaticPageLayout>
    );
  }

  return (
    <StaticPageLayout page="team-feedback">
      {showProjectList && (
        <>
          <div className="layout-wrap">
            <div className="sections-wrap">
              <div className="title">Team feedback</div>
              <div className="scrollable-body">
                {Object.values(SHARED_PROJECT_TYPE_TABS).map((type) => (
                  <ProfilerRequestTypeButton key={type} type={type} params={state.params} />
                ))}
              </div>
            </div>
            <div className="right-container">
              <ProfilerSharedProjectsList projectList={projectList} params={state.params} />
            </div>
          </div>
        </>
      )}
      {showFeedbackRequest && (
        <ProfilerFeedbackRequestView project={selectedProject} params={state.params} />
      )}
    </StaticPageLayout>
  );
});

export default ProfilerTeam;
