import { SEARCH_CATEGORIES } from '../../../../utils/constants/searchCategories';
import { SEARCH_FIELDS } from '../../../../utils/constants/fields';
import MultiSelect from '../../../../components/inputs/MultiSelect';
import Input from '../../../../components/inputs/Input';
import RangeInput from '../../../../components/inputs/RangeInput';

export const getResultSearchFields = (state, isTypeSelected, uiFormState) => {
  const disableInputsByLoader = state.isLoading || state.isInitialLoading;

  return {
    [SEARCH_FIELDS.SEARCH_TYPE.ID]: (
      <MultiSelect
        field={SEARCH_FIELDS.SEARCH_TYPE}
        value={state.fields[SEARCH_FIELDS.SEARCH_TYPE.NAME]}
        setFieldValue={state.setFieldValue}
        disabled={disableInputsByLoader}
        inputWrap={{ enable: true, className: 'col' }}
        wrapClassname="form-group"
      />
    ),
    [SEARCH_CATEGORIES.GENERAL]: {
      [SEARCH_FIELDS.INDUSTRY.ID]: (
        <MultiSelect
          field={SEARCH_FIELDS.INDUSTRY}
          value={state.fields[SEARCH_FIELDS.INDUSTRY.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={!isTypeSelected || disableInputsByLoader}
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [SEARCH_FIELDS.HEADQUARTER.ID]: (
        <MultiSelect
          field={SEARCH_FIELDS.HEADQUARTER}
          value={state.fields[SEARCH_FIELDS.HEADQUARTER.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={!isTypeSelected || disableInputsByLoader}
          withSingleClear
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [SEARCH_FIELDS.GEOGRAPHICAL_FOCUS.ID]: (
        <MultiSelect
          field={SEARCH_FIELDS.GEOGRAPHICAL_FOCUS}
          value={state.fields[SEARCH_FIELDS.GEOGRAPHICAL_FOCUS.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={!isTypeSelected || disableInputsByLoader}
          inputWrap={{ enable: true, className: 'col' }}
        />
      )
    },
    [SEARCH_CATEGORIES.FINANCIALS]: {
      [SEARCH_FIELDS.REVENUE.ID]: (
        <Input
          field={SEARCH_FIELDS.REVENUE}
          value={state.fields[SEARCH_FIELDS.REVENUE.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={!isTypeSelected || disableInputsByLoader}
          inputWrap={{ enable: true, className: 'col' }}
          onAfterValueChange={uiFormState.onRevenueChange}
        />
      ),
      [SEARCH_FIELDS.EBITDA.ID]: (
        <Input
          field={SEARCH_FIELDS.EBITDA}
          value={state.fields[SEARCH_FIELDS.EBITDA.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={!isTypeSelected || disableInputsByLoader || uiFormState.isEBITDAFieldDisabled}
          inputWrap={{ enable: true, className: 'col' }}
          onFocus={uiFormState.onEBITDAFocus}
          onBlur={uiFormState.onEBITDABlur}
          onAfterValueChange={uiFormState.onEBITDAChange}
        />
      ),
      [SEARCH_FIELDS.EBITDA_MARGIN.ID]: (
        <Input
          field={SEARCH_FIELDS.EBITDA_MARGIN}
          value={state.fields[SEARCH_FIELDS.EBITDA_MARGIN.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={
            !isTypeSelected || disableInputsByLoader || uiFormState.isEBITDAMarginFieldDisabled
          }
          inputWrap={{ enable: true, className: 'col' }}
          onFocus={uiFormState.onEBITDAMarginFocus}
          onBlur={uiFormState.onEBITDAMarginBlur}
          onAfterValueChange={uiFormState.onEBITDAMarginChange}
        />
      )
    },
    [SEARCH_CATEGORIES.TRANSACTION]: {
      [SEARCH_FIELDS.SITUATION.ID]: (
        <MultiSelect
          field={SEARCH_FIELDS.SITUATION}
          value={state.fields[SEARCH_FIELDS.SITUATION.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={!isTypeSelected || disableInputsByLoader}
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [SEARCH_FIELDS.EQUITY_STAKE.ID]: (
        <MultiSelect
          field={SEARCH_FIELDS.EQUITY_STAKE}
          value={state.fields[SEARCH_FIELDS.EQUITY_STAKE.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={!isTypeSelected || disableInputsByLoader}
          inputWrap={{ enable: true, className: 'col' }}
        />
      )
    },
    [SEARCH_CATEGORIES.VALUATION]: {
      [SEARCH_FIELDS.ENTERPRISE_MIN.ID + SEARCH_FIELDS.ENTERPRISE_MAX.ID]: (
        <RangeInput
          fieldMin={SEARCH_FIELDS.ENTERPRISE_MIN}
          fieldMax={SEARCH_FIELDS.ENTERPRISE_MAX}
          valueMin={state.fields[SEARCH_FIELDS.ENTERPRISE_MIN.NAME]}
          valueMax={state.fields[SEARCH_FIELDS.ENTERPRISE_MAX.NAME]}
          disabled={!isTypeSelected || disableInputsByLoader}
          setFieldValue={state.setFieldValue}
          messages={state.validationFields.messages}
          showOnSubmitErrorState={state.onSubmitErrorState}
          wrapClass={'col'}
        />
      )
    }
  };
};
