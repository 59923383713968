import React, { useMemo } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { formatDateDDMMYYYY } from '../../../utils/utils';
import IconButton, { ICON_BUTTON_TYPES } from '../../../components/buttons/IconButton';
import { paths } from '../../../utils/constants/routes';
import { encodeProjectsParams } from '../../Projects/constants';
import useHistory from '../../../hooks/useHistory';
import { useStore } from '../../../store/store';
import { SHARED_PROJECT_TYPE_TABS } from './constants';
import { PROFILER_SEARCH_FIELDS } from '../../../utils/constants/fields';
const SEARCH_TYPES = {
  funds: 'Fund',
  portfolios: 'Portfolio company',
  investprofiles: 'Invest profile'
};

const ProfilerSharedProjectEntry = observer(({ project, params }) => {
  const { navigate } = useHistory();
  const { authStore, utilsStore } = useStore();
  const state = useLocalObservable(() => ({
    get layout() {
      return utilsStore.windowWidth < 1201 ? 'mobile' : 'desktop';
    }
  }));

  const onOpenRequestClick = () => {
    navigate(
      paths.PROFILER_TEAM +
        '?params=' +
        encodeProjectsParams({ ...params, selectedProjectId: project.id })
    );
  };

  const foundProjectUser = useMemo(() => {
    const foundUser = authStore.companyUsers.find((u) => u.id === project.ownerId);
    if (foundUser) {
      return `${foundUser.firstName} ${foundUser.lastName}`;
    }
    return 'unknown';
  }, [project, authStore]);

  const foundUser = useMemo(() => {
    return project.sharedUsers.find((user) => user.id === authStore.currentUserId);
  }, [project, authStore]);

  if (state.layout === 'mobile') {
    return (
      <div className="mobile-project-entry">
        <div className="project-name">{project.name}</div>
        <div className="project-property-row">
          <div className="project-property-field">Sender name</div>
          <div className="project-property-value">{foundProjectUser}</div>
        </div>
        <div className="project-property-row">
          <div className="project-property-field">
            {params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.PENDING
              ? 'Date received'
              : 'Date resolved'}
          </div>
          <div className="project-property-value">
            {formatDateDDMMYYYY(
              params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.PENDING
                ? foundUser?.dateReceived
                : foundUser?.dateResolved
            )}
          </div>
        </div>
        <div className="project-property-row">
          <div className="project-property-field">Industry</div>
          <div className="project-property-value">
            {project.fields[PROFILER_SEARCH_FIELDS.INDUSTRY.NAME]}
          </div>
        </div>
        <div className="project-property-row">
          <div className="project-property-field">Product portfolio</div>
          <div className="project-property-value">
            {project.fields[PROFILER_SEARCH_FIELDS.PRODUCT_PORTFOLIO.NAME]}
          </div>
        </div>
        <div className="project-actions-row">
          <IconButton
            type={ICON_BUTTON_TYPES.BLUE}
            innerText="Open Request"
            onClick={onOpenRequestClick}
            filled
            wrapClassName="open-feedback-request-btn"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="item">
        <div className="label">Sender Name</div>
        <div className="value">{foundProjectUser}</div>
      </div>
      <div className="item">
        <div className="label">Project name</div>
        <div className="value">{project.name}</div>
      </div>
      <div className="item">
        <div className="label">
          {params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.PENDING
            ? 'Date received'
            : 'Date resolved'}
        </div>
        <div className="value">
          {formatDateDDMMYYYY(
            params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.PENDING
              ? foundUser?.dateReceived
              : foundUser?.dateResolved
          )}
        </div>
      </div>
      <div className="item">
        <div className="label">Industry</div>
        <div className="value">{project.fields[PROFILER_SEARCH_FIELDS.INDUSTRY.NAME]}</div>
      </div>
      <div className="item product-portfolio-desktop">
        <div className="label">Product portfolio</div>
        <div className="value">{project.fields[PROFILER_SEARCH_FIELDS.PRODUCT_PORTFOLIO.NAME]}</div>
      </div>
      <div className="actions">
        <IconButton
          type={ICON_BUTTON_TYPES.BLUE}
          innerText="Open Request"
          onClick={onOpenRequestClick}
          filled
        />
      </div>
    </div>
  );
});

export default ProfilerSharedProjectEntry;
