import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import {
  PROJECT_TYPE_TABS,
  encodeProjectsParams,
  PROJECT_LIST_SORT_OPTIONS,
  PROJECT_LIST_SORT,
  projectTypePageSize
} from '../constants';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';
import MultiSelect from '../../../components/inputs/MultiSelect';
import { FIELDS_TYPES } from '../../../utils/constants/fields';
import ProjectListEntry from './ProjectListEntry';
import { useEffect, useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import DeletePopup from './DeletePopup';

const ProjectsList = observer(({ listType = PROJECT_TYPE_TABS.SAVED, params = {} }) => {
  const { projectStore, utilsStore } = useStore();
  const { navigate } = useHistory();
  const state = useLocalObservable(() => ({
    isDeletePopupOpen: false,
    toggleDeletePopup: () => {
      state.isDeletePopupOpen = !state.isDeletePopupOpen;
      utilsStore.lockScroll(state.isDeletePopupOpen);
    }
  }));
  useEffect(() => {
    return () => {
      if (state.isDeletePopupOpen) {
        state.toggleDeletePopup();
      }
    };
  }, [state]);

  const onSortChange = (_, [value]) => {
    navigate(
      paths.PROJECTS +
        '?params=' +
        encodeProjectsParams({ ...params, projectSort: value, projectTypePage: 1 })
    );
  };

  const projectTypeTabs = [
    {
      name: 'Saved projects (' + projectStore.projects.length + ')',
      onClick: () => {
        navigate(
          paths.PROJECTS +
            '?params=' +
            encodeProjectsParams({ projectTypeTab: PROJECT_TYPE_TABS.SAVED, projectTypePage: 1 })
        );
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      isActive: params.projectTypeTab === PROJECT_TYPE_TABS.SAVED
    },
    {
      name: 'Archived projects (' + projectStore.archivedProjects.length + ')',
      onClick: () => {
        navigate(
          paths.PROJECTS +
            '?params=' +
            encodeProjectsParams({ projectTypeTab: PROJECT_TYPE_TABS.ARCHIVED, projectTypePage: 1 })
        );
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      isActive: params.projectTypeTab === PROJECT_TYPE_TABS.ARCHIVED
    }
  ];

  const sort = useMemo(() => {
    return params.projectSort || PROJECT_LIST_SORT.DATE_DESC;
  }, [params.projectSort]);

  const [isActive, isArchived] = useMemo(() => {
    return [listType === PROJECT_TYPE_TABS.SAVED, listType === PROJECT_TYPE_TABS.ARCHIVED];
  }, [listType]);

  const list = useMemo(() => {
    const projects = (
      isActive ? projectStore.projects : isArchived ? projectStore.archivedProjects : []
    ).slice();

    const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

    if (sort === PROJECT_LIST_SORT.DATE_ASC) {
      projects.sort((a, b) => a.dateSaved - b.dateSaved);
    } else if (sort === PROJECT_LIST_SORT.DATE_DESC) {
      projects.sort((a, b) => b.dateSaved - a.dateSaved);
    } else if (sort === PROJECT_LIST_SORT.TYPE_ASC) {
      projects.sort((a, b) => collator.compare(a.searchType, b.searchType));
    } else if (sort === PROJECT_LIST_SORT.TYPE_DESC) {
      projects.sort((a, b) => collator.compare(b.searchType, a.searchType));
    }

    return projects;
  }, [isActive, isArchived, projectStore.projects, projectStore.archivedProjects, sort]);

  const showPaging = list.length > projectTypePageSize;
  const projectTypePages = Math.ceil(list.length / projectTypePageSize) || 1;

  return (
    <>
      {state.isDeletePopupOpen && (
        <DeletePopup archivedProjects={list} toggle={state.toggleDeletePopup} />
      )}
      <div className="tabs">
        {projectTypeTabs.map((tab) => {
          return (
            <div key={tab.name} onClick={tab.onClick} className={`tab ${tab.isActive && 'active'}`}>
              {tab.name}
            </div>
          );
        })}
      </div>
      <div className="table-responsive">
        {list.length === 0 ? (
          <div className="no-data">No items for this tab</div>
        ) : (
          <>
            <div className="header">
              <div className="item project-name">Project name</div>
              <div className="item">Date saved</div>
              <div className="item text-align-right-desktop">Number of results</div>
              <div className="item">Search type</div>
              <div className="project-sort-wrapper">
                <MultiSelect
                  field={{ NAME: 'sort', ID: 'plt-sort', TYPE: FIELDS_TYPES.TYPE_SELECT }}
                  value={[sort]}
                  values={PROJECT_LIST_SORT_OPTIONS}
                  setFieldValue={onSortChange}
                />
              </div>
            </div>
            <MultiSelect
              field={{ NAME: 'sort', ID: 'plt-sort-mobile', TYPE: FIELDS_TYPES.TYPE_SELECT }}
              value={[sort]}
              values={PROJECT_LIST_SORT_OPTIONS}
              setFieldValue={onSortChange}
              inputWrap={{ enable: true, className: 'plt-sort-mobile mobile-only' }}
            />
            {list
              .slice(
                (params.projectTypePage - 1) * projectTypePageSize,
                params.projectTypePage * projectTypePageSize
              )
              .map((p) => {
                return <ProjectListEntry key={p.id} project={p} params={params} />;
              })}
          </>
        )}
      </div>
      {/* MMV2-229 Deactivate deletion for projects */}
      {/* {isArchived && list.length > 0 && (
        <div className="delete-text" onClick={state.toggleDeletePopup}>
          Delete Projects
        </div>
      )} */}
      {showPaging && (
        <ReactPaginate
          onPageChange={({ selected }) => {
            navigate(
              paths.PROJECTS +
                '?params=' +
                encodeProjectsParams({ ...params, projectTypePage: selected + 1 })
            );
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          forcePage={params.projectTypePage - 1}
          pageCount={projectTypePages}
          pageRangeDisplayed={utilsStore.windowWidth < 481 ? 1 : projectTypePageSize}
          marginPagesDisplayed={utilsStore.windowWidth < 481 ? 1 : projectTypePageSize}
          renderOnZeroPageCount={null}
          breakLabel="..."
          nextLabel="Next"
          previousLabel="Prev"
          containerClassName="pagination-container"
          pageClassName="page"
          pageLinkClassName="pageLink"
          nextClassName="next"
          nextLinkClassName="nextLink"
          previousClassName="prev"
          previousLinkClassName="prevLink"
          breakClassName="break"
          breakLinkClassName="breakLink"
        />
      )}
    </>
  );
});

export default ProjectsList;
