import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import IconButton, {
  ICON_BUTTON_TYPES,
  ICON_BUTTON_ICONS
} from '../../../components/buttons/IconButton';
import { SHARED_PROJECT_TYPE_TABS } from './constants';
import { useCallback, useEffect } from 'react';
import { formatNumber } from '../../../utils/utils';

const ProfilerFeedbackSearchResultsTabEntry = observer(
  ({
    entry = {},
    feedbacks = {},
    project = {},
    onChangeFeedback,
    params,
    displayDetails = () => {}
  }) => {
    const { utilsStore } = useStore();

    const state = useLocalObservable(() => ({
      entryFeedback: [],
      get layout() {
        return utilsStore.windowWidth < 481 ? 'mobile' : 'desktop';
      }
    }));

    useEffect(() => {
      return () => {
        if (state.isDetailsPopupOpen) {
          state.toggleDetailsPopup();
        }
      };
    }, [state, entry.id]);

    useEffect(() => {
      state.feedbacks = project.feedbacks.find((project) => project.resultId === entry.id);
    }, [entry, project]);

    const isFeedbackMarked = useCallback(
      (resultId, feedback) => {
        return feedbacks.find((fb) => fb.resultId === resultId && fb.feedback === feedback);
      },
      [feedbacks]
    );

    const readOnly = params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.RESOLVED;

    return (
      <div className="row profiler-search-results-tab-entry-row">
        {state.layout === 'mobile' && (
          <div className="item feedback-item">
            <IconButton
              type={ICON_BUTTON_TYPES.GREEN}
              icon={ICON_BUTTON_ICONS.HAND_THUMBS_UP}
              onClick={() => !readOnly && onChangeFeedback(entry.id, 'up')}
              filled={isFeedbackMarked(entry.id, 'up')}
            />
            <IconButton
              type={ICON_BUTTON_TYPES.YELLOW}
              icon={ICON_BUTTON_ICONS.QUESTION_LG}
              onClick={() => !readOnly && onChangeFeedback(entry.id, 'question')}
              filled={isFeedbackMarked(entry.id, 'question')}
            />
            <IconButton
              type={ICON_BUTTON_TYPES.RED}
              icon={ICON_BUTTON_ICONS.HAND_THUMBS_DOWN}
              onClick={() => !readOnly && onChangeFeedback(entry.id, 'down')}
              filled={isFeedbackMarked(entry.id, 'down')}
            />
          </div>
        )}
        <div className="item profiler-company-name-item">
          <div className="label">Company name</div>
          <div className="value">{entry.name || 'N/A'}</div>
        </div>
        <div className="item">
          <div className="label">Company HQ</div>
          <div className="value">{entry.headquarters || 'N/A'}</div>
        </div>
        <div className="item">
          <div className="label">Industry</div>
          <div className="value">
            {entry.industries && entry.industries.length
              ? utilsStore.shortenList(entry.industries, 3).join(', ')
              : 'N/A'}
          </div>
        </div>
        <div className="item">
          <div className="label">Value chain</div>
          <div className="value">
            {entry.valueChain && entry.valueChain.length
              ? utilsStore.shortenList(entry.valueChain, 3).join(', ')
              : 'N/A'}
          </div>
        </div>
        <div className="item">
          <div className="label">Products</div>
          <div className="value">
            {entry.products && entry.products.length
              ? utilsStore.shortenList(entry.products, 3).join(', ')
              : 'N/A'}
          </div>
        </div>
        <div className="item profiler-revenue-item">
          <div className="label">Revenue (€M)</div>
          <div className="value right-align-desktop">
            {entry.revenue ? (
              <>
                {entry.revenue}
                <span className="year">
                  &lsquo;
                  {entry.revenueYear?.toString?.()?.slice?.(-2) || 'n/a'}
                </span>
              </>
            ) : (
              'N/A'
            )}
          </div>
        </div>
        <div className="item profiler-employees-item">
          <div className="label">Employees</div>
          <div className="value right-align-desktop">
            {!isNaN(entry.employees) ? (
              <>
                {formatNumber((+entry.employees).toFixed(2))}
                <span className="year">
                  &lsquo;
                  {entry.employeesYear?.toString?.()?.slice?.(-2) || 'n/a'}
                </span>
              </>
            ) : (
              'N/A'
            )}
          </div>
        </div>

        {state.layout === 'desktop' && (
          <div className="item feedback-item">
            <IconButton
              type={ICON_BUTTON_TYPES.GREEN}
              icon={ICON_BUTTON_ICONS.HAND_THUMBS_UP}
              onClick={() => !readOnly && onChangeFeedback(entry.id, 'up')}
              filled={isFeedbackMarked(entry.id, 'up')}
              wrapClassName={readOnly ? 'force-default-cursor' : ''}
            />
            <IconButton
              type={ICON_BUTTON_TYPES.YELLOW}
              icon={ICON_BUTTON_ICONS.QUESTION_LG}
              onClick={() => !readOnly && onChangeFeedback(entry.id, 'question')}
              filled={isFeedbackMarked(entry.id, 'question')}
              wrapClassName={readOnly ? 'force-default-cursor' : ''}
            />
            <IconButton
              type={ICON_BUTTON_TYPES.RED}
              icon={ICON_BUTTON_ICONS.HAND_THUMBS_DOWN}
              onClick={() => !readOnly && onChangeFeedback(entry.id, 'down')}
              filled={isFeedbackMarked(entry.id, 'down')}
              wrapClassName={readOnly ? 'force-default-cursor' : ''}
            />
          </div>
        )}
        <div className="actions">
          <IconButton
            innerText={'View details'}
            onClick={() => displayDetails(entry)}
            type={ICON_BUTTON_TYPES.BLUE}
            filled
          />
        </div>
      </div>
    );
  }
);

export default ProfilerFeedbackSearchResultsTabEntry;
