import { observer } from 'mobx-react';
import { INV_TYPE_TABS, encodeAllInvestmentsParams, invTypePageSize } from '../constants';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';
import { useStore } from '../../../store/store';
import ReactPaginate from 'react-paginate';
import AllInvestmentsListEntry from './AllInvestmentsListEntry';
import { useEffect } from 'react';

const AllInvestmentsList = observer(({ list = [], params = {} }) => {
  const { fundsStore, authStore, utilsStore } = useStore();
  const { navigate } = useHistory();

  useEffect(() => {
    authStore.loadCompanyUsers();
    fundsStore.loadCompanyFunds();
  }, [authStore]);

  const type = params.tab;

  const nonCoraporateTabs = [
    {
      name: 'Funds (' + fundsStore.allFunds.length + ')',
      isActive: type === INV_TYPE_TABS.FUNDS,
      onClick: () =>
        navigate(
          paths.ALL_INVESTMENTS +
            '?params=' +
            encodeAllInvestmentsParams({ ...params, tab: INV_TYPE_TABS.FUNDS, page: 1 })
        )
    },
    {
      name: 'Portfolio companies (' + fundsStore.allPortfolios.length + ')',
      isActive: type === INV_TYPE_TABS.PORTFOLIOS,
      onClick: () =>
        navigate(
          paths.ALL_INVESTMENTS +
            '?params=' +
            encodeAllInvestmentsParams({ ...params, tab: INV_TYPE_TABS.PORTFOLIOS, page: 1 })
        )
    }
  ];

  const corporateTabs = [
    {
      name: 'Invest profiles (' + fundsStore.allInvestProfiles.length + ')',
      onClick: () =>
        navigate(
          paths.ALL_INVESTMENTS +
            '?params=' +
            encodeAllInvestmentsParams({ ...params, tab: INV_TYPE_TABS.INVEST_PROFILES, page: 1 })
        ),
      noCursor: true
    }
  ];

  const tabs = authStore.isCoraporatesInvestor ? corporateTabs : nonCoraporateTabs;
  const showPaging = list.length > invTypePageSize;
  const pages = Math.ceil(list.length / invTypePageSize) || 1;

  return (
    <>
      <div className="tabs">
        {tabs.map((tab) => {
          const classNames = ['tab'];
          if (tab.isActive) {
            classNames.push('active');
          }
          if (tab.noCursor) {
            classNames.push('no-cursor');
          }
          return (
            <div className={classNames.join(' ')} key={tab.name} onClick={tab.onClick}>
              {tab.name}
            </div>
          );
        })}
      </div>
      <div className="table-responsive">
        {!!list.length && (
          <div className="header">
            <div className="item">
              {type === INV_TYPE_TABS.FUNDS
                ? 'Fund name'
                : type === INV_TYPE_TABS.PORTFOLIOS
                ? 'Portfolio name'
                : 'Invest name'}
            </div>
            <div className="item">Industry focus</div>
            <div className="item">Geo focus</div>
            <div className="item">Enterprise value (€M)</div>
            <div className="item">Equity value (€M)</div>
            <div className="item">Created by</div>
            <div className="item">Latest change</div>
          </div>
        )}
        {list
          .slice((params.page - 1) * invTypePageSize, params.page * invTypePageSize)
          .map((entry) => (
            <AllInvestmentsListEntry key={entry.id} entry={entry} type={type} />
          ))}
      </div>
      {showPaging && (
        <ReactPaginate
          onPageChange={({ selected }) => {
            navigate(
              paths.ALL_INVESTMENTS +
                '?params=' +
                encodeAllInvestmentsParams({ ...params, page: selected + 1 })
            );
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          forcePage={params.page - 1}
          pageCount={pages}
          pageRangeDisplayed={utilsStore.windowWidth < 481 ? 1 : invTypePageSize}
          marginPagesDisplayed={utilsStore.windowWidth < 481 ? 1 : invTypePageSize}
          renderOnZeroPageCount={null}
          breakLabel="..."
          nextLabel="Next"
          previousLabel="Prev"
          containerClassName="pagination-container"
          pageClassName="page"
          pageLinkClassName="pageLink"
          nextClassName="next"
          nextLinkClassName="nextLink"
          previousClassName="prev"
          previousLinkClassName="prevLink"
          breakClassName="break"
          breakLinkClassName="breakLink"
        />
      )}
    </>
  );
});

export default AllInvestmentsList;
