import { ICON_BUTTON_ICONS } from '../../../../components/buttons/IconButton';
import { UI_OPTION_KEYS } from '../../../../utils/constants/uiOptionKeys';
import { UI_OPTIONS } from '../../../../utils/constants/uiOptions';

export default [
  {
    name: 'Relevance (default)',
    shortName: 'Relevance (default)',
    value: 'relevance'
  },
  {
    name: 'Fund name (a → z)',
    shortName: 'Fund name',
    value: 'name-asc',
    icon: ICON_BUTTON_ICONS.SORT_ALPHA_UP_ALT,
    onlyForSearchType: UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
  },
  {
    name: 'Fund name (z → a)',
    shortName: 'Fund name',
    value: 'name-desc',
    icon: ICON_BUTTON_ICONS.SORT_ALPHA_DOWN_ALT,
    onlyForSearchType: UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
  },
  {
    name: 'Portfolio name (a → z)',
    shortName: 'Portfolio name',
    value: 'name-asc',
    icon: ICON_BUTTON_ICONS.SORT_ALPHA_UP_ALT,
    onlyForSearchType: UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']
  },
  {
    name: 'Portfolio name (z → a)',
    shortName: 'Portfolio name',
    value: 'name-desc',
    icon: ICON_BUTTON_ICONS.SORT_ALPHA_DOWN_ALT,
    onlyForSearchType: UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']
  },
  {
    name: 'Invest profile name (a → z)',
    shortName: 'Invest profile name',
    value: 'name-asc',
    icon: ICON_BUTTON_ICONS.SORT_ALPHA_UP_ALT,
    onlyForSearchType: UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Corporates
  },
  {
    name: 'Invest profile name (z → a)',
    shortName: 'Invest profile name',
    value: 'name-desc',
    icon: ICON_BUTTON_ICONS.SORT_ALPHA_DOWN_ALT,
    onlyForSearchType: UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Corporates
  },
  {
    name: 'Acquisition date ↑',
    shortName: 'Acquisition date',
    value: 'acquisitionDate-asc',
    icon: ICON_BUTTON_ICONS.SORT_NUMERIC_UP_ALT,
    onlyForSearchType: UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']
  },
  {
    name: 'Acquisition date ↓',
    shortName: 'Acquisition date',
    value: 'acquisitionDate-desc',
    icon: ICON_BUTTON_ICONS.SORT_NUMERIC_DOWN_ALT,
    onlyForSearchType: UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']
  },
  {
    name: 'Revenue (min) ↑',
    shortName: 'Revenue (min)',
    value: 'revenueMin-asc',
    icon: ICON_BUTTON_ICONS.SORT_NUMERIC_UP_ALT
  },
  {
    name: 'Revenue (max) ↓',
    shortName: 'Revenue (max)',
    value: 'revenueMax-desc',
    icon: ICON_BUTTON_ICONS.SORT_NUMERIC_DOWN_ALT
  },
  {
    name: 'EBITDA margin ↑',
    shortName: 'EBITDA margin',
    value: 'ebitdaMargin-asc',
    icon: ICON_BUTTON_ICONS.SORT_NUMERIC_UP_ALT
  },
  {
    name: 'EBITDA margin ↓',
    shortName: 'EBITDA margin',
    value: 'ebitdaMargin-desc',
    icon: ICON_BUTTON_ICONS.SORT_NUMERIC_DOWN_ALT
  },
  {
    name: 'EBITDA (min) ↑',
    shortName: 'EBITDA (min)',
    value: 'ebitdaMin-asc',
    icon: ICON_BUTTON_ICONS.SORT_NUMERIC_UP_ALT
  },
  {
    name: 'EBITDA (max) ↓',
    shortName: 'EBITDA (max)',
    value: 'ebitdaMax-desc',
    icon: ICON_BUTTON_ICONS.SORT_NUMERIC_DOWN_ALT
  },
  {
    name: 'Enterprise Value (min) ↑',
    shortName: 'Enterprise Value (min)',
    value: 'enterpriseMin-asc',
    icon: ICON_BUTTON_ICONS.SORT_NUMERIC_UP_ALT
  },
  {
    name: 'Enterprise Value (max) ↓',
    shortName: 'Enterprise Value (max)',
    value: 'enterpriseMax-desc',
    icon: ICON_BUTTON_ICONS.SORT_NUMERIC_DOWN_ALT
  }
];
