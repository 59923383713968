import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { selectedProjectTabPageSize, encodeProjectsParams } from '../constants';
import ReactPaginate from 'react-paginate';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';
import CommentsTabEntry from './CommentsTabEntry';
import { useStore } from '../../../store/store';

const CommentsTabView = observer(({ project = {}, params = {} }) => {
  const { utilsStore } = useStore();
  const { navigate } = useHistory();

  const list = useMemo(() => {
    return project.teamComments.slice().sort((a, b) => b.date - a.date);
  }, [project, project.teamComments, project.teamComments.length]);

  const showPaing = list.length > selectedProjectTabPageSize;
  const pages = Math.ceil(list.length / selectedProjectTabPageSize) || 1;
  const page = (params.projectTypePage || 1) - 1;

  return (
    <div className="comment-table-responsive">
      {list.length === 0 ? (
        <div className="no-data">No items for this tab</div>
      ) : (
        <>
          <div className="header">
            <div className="item name-item">Team member name</div>
            <div className="item">Comment date</div>
            <div className="item comment-item">Feedback comment</div>
          </div>

          {list
            .slice(
              (params.selectedProjectTabPage - 1) * selectedProjectTabPageSize,
              params.selectedProjectTabPage * selectedProjectTabPageSize
            )
            .map((comment, idx) => (
              <CommentsTabEntry key={idx} comment={comment} />
            ))}

          {showPaing && (
            <ReactPaginate
              onPageChange={({ selected }) => {
                navigate(
                  paths.PROJECTS +
                    '?params=' +
                    encodeProjectsParams({ ...params, selectedProjectTabPage: selected + 1 })
                );
                setTimeout(() => {
                  const el = document.getElementById('project-view-tab-1');
                  const header = document.querySelector('header');
                  if (el) {
                    const headerHeight = header?.clientHeight || 0;
                    window.scrollTo({ top: el.offsetTop - headerHeight, behavior: 'smooth' });
                  }
                }, 100);
              }}
              forcePage={page}
              pageCount={pages}
              pageRangeDisplayed={utilsStore.windowWidth < 481 ? 1 : selectedProjectTabPageSize}
              marginPagesDisplayed={utilsStore.windowWidth < 481 ? 1 : selectedProjectTabPageSize}
              renderOnZeroPageCount={null}
              breakLabel="..."
              nextLabel="Next"
              previousLabel="Prev"
              containerClassName="pagination-container"
              pageClassName="page"
              pageLinkClassName="pageLink"
              nextClassName="next"
              nextLinkClassName="nextLink"
              previousClassName="prev"
              previousLinkClassName="prevLink"
              breakClassName="break"
              breakLinkClassName="breakLink"
            />
          )}
        </>
      )}
    </div>
  );
});

export default CommentsTabView;
