import { observer } from 'mobx-react';
import { PROFILER_SEARCH_FIELDS } from '../../../utils/constants/fields';
import Input from '../../../components/inputs/Input';
import RangeInput from '../../../components/inputs/RangeInput';

const SearchFormMobile = observer(
  ({ fields, setFieldValue, onSubmitErrorState, validationFields, isSearchDisabled, onSearch }) => {
    return (
      <form onSubmit={onSearch} className="mobile">
        <div className="search-form-header">Search by using your key investment criteria</div>
        <Input
          field={PROFILER_SEARCH_FIELDS.INDUSTRY}
          value={fields[PROFILER_SEARCH_FIELDS.INDUSTRY.NAME]}
          setFieldValue={setFieldValue}
          showOnSubmitErrorState={onSubmitErrorState}
          messages={validationFields.messages}
          //   inputWrap={{ enable: true, className: 'col' }}
        />
        <Input
          field={PROFILER_SEARCH_FIELDS.VALUE_CHAIN}
          value={fields[PROFILER_SEARCH_FIELDS.VALUE_CHAIN.NAME]}
          setFieldValue={setFieldValue}
          showOnSubmitErrorState={onSubmitErrorState}
          messages={validationFields.messages}
          //   inputWrap={{ enable: true, className: 'col' }}
        />
        <Input
          field={PROFILER_SEARCH_FIELDS.PRODUCT_PORTFOLIO}
          value={fields[PROFILER_SEARCH_FIELDS.PRODUCT_PORTFOLIO.NAME]}
          setFieldValue={setFieldValue}
          showOnSubmitErrorState={onSubmitErrorState}
          messages={validationFields.messages}
          //   inputWrap={{ enable: true, className: 'col' }}
        />
        <RangeInput
          fieldMin={PROFILER_SEARCH_FIELDS.REVENUE_MIN}
          fieldMax={PROFILER_SEARCH_FIELDS.REVENUE_MAX}
          valueMin={fields[PROFILER_SEARCH_FIELDS.REVENUE_MIN.NAME]}
          valueMax={fields[PROFILER_SEARCH_FIELDS.REVENUE_MAX.NAME]}
          setFieldValue={setFieldValue}
          messages={validationFields.messages}
          showOnSubmitErrorState={onSubmitErrorState}
        />
        <RangeInput
          fieldMin={PROFILER_SEARCH_FIELDS.EMPLOYEES_MIN}
          fieldMax={PROFILER_SEARCH_FIELDS.EMPLOYEES_MAX}
          valueMin={fields[PROFILER_SEARCH_FIELDS.EMPLOYEES_MIN.NAME]}
          valueMax={fields[PROFILER_SEARCH_FIELDS.EMPLOYEES_MAX.NAME]}
          setFieldValue={setFieldValue}
          messages={validationFields.messages}
          showOnSubmitErrorState={onSubmitErrorState}
        />
        {/* <RangeInput
              fieldMin={PROFILER_SEARCH_FIELDS.EBITDA_MIN}
              fieldMax={PROFILER_SEARCH_FIELDS.EBITDA_MAX}
              valueMin={fields[PROFILER_SEARCH_FIELDS.EBITDA_MIN.NAME]}
              valueMax={fields[PROFILER_SEARCH_FIELDS.EBITDA_MAX.NAME]}
              setFieldValue={setFieldValue}
              messages={validationFields.messages}
              showOnSubmitErrorState={onSubmitErrorState}
              wrapClass={'col'}
            />
            <Input
              field={PROFILER_SEARCH_FIELDS.EBITDA_MARGIN}
              value={fields[PROFILER_SEARCH_FIELDS.EBITDA_MARGIN.NAME]}
              setFieldValue={setFieldValue}
              showOnSubmitErrorState={onSubmitErrorState}
              messages={validationFields.messages}
              inputWrap={{ enable: true, className: 'col' }}
            /> */}
        <button
          type="submit"
          className="btn btn-primary disable-fade-primary"
          disabled={isSearchDisabled}>
          Search
        </button>
      </form>
    );
  }
);

export default SearchFormMobile;
