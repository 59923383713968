import { observer, useLocalObservable } from 'mobx-react';
import {
  OUTREACH_FIELDS,
  getErrorFields,
  FUND_FIELDS,
  PORTFOLIO_FIELDS,
  trimStateFields,
  INVEST_PROFILE_FIELDS,
  OUTREACH_CONTACT_FIELDS
} from '../../../utils/constants/fields';
import Input from '../../../components/inputs/Input';
import TextArea from '../../../components/inputs/TextArea';
import { mapFieldsToState } from '../../../utils/utils';
import { useEffect } from 'react';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import { useStore } from '../../../store/store';
import { UI_OPTIONS } from '../../../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../../../utils/constants/uiOptionKeys';
import { eventOutreachSearch } from '../../../ga4/ga4';
import { HEADER_MESSAGE_TYPES } from '../../../utils/constants/header';
import IconButton, {
  ICON_BUTTON_TYPES,
  ICON_BUTTON_ICONS
} from '../../../components/buttons/IconButton';
import { API_ENDPOINTS } from '../../../api/endpoints';
import { REQ_METHODS } from '../../../api/methods';
import { runInAction } from 'mobx';
import { CONTENT_TYPES } from '../../../api/contentTypes';
import PDFList from '../../../components/shared/PDFList';
import { Link } from 'react-router-dom';
import { paths } from '../../../utils/constants/routes';

const ProjectMessagePopup = observer(
  ({ messagePopupDisplayed, displayMessagePopup, submitMessage, forSearchType }) => {
    const { utilsStore, makeRequest, outreachStore } = useStore();
    const state = useLocalObservable(() => ({
      isRendered: false,
      setIsRendered: (value = false) => (state.isRendered = value),
      entry: messagePopupDisplayed,
      get hasContact() {
        return (
          !!state.entry.customContactInfo?.firstName ||
          !!state.entry.contactInfo?.firstName ||
          !!state.entry.importedContactInfo?.firstName
        );
      },
      get contactName() {
        if (state.entry.customContactInfo?.firstName) {
          return (
            state.entry.customContactInfo?.firstName + ' ' + state.entry.customContactInfo.lastName
          );
        }

        if (state.entry.contactInfo?.firstName) {
          return `${state.entry.contactInfo?.firstName} ${state.entry.contactInfo?.lastName}`;
        }

        return (
          state.entry.importedContactInfo?.firstName +
          ' ' +
          state.entry.importedContactInfo?.lastName
        );
      },
      get isFund() {
        return forSearchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds;
      },
      get isPortfolio() {
        return forSearchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'];
      },
      get isProfile() {
        return forSearchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Corporates;
      },
      get entryName() {
        let name = '';
        if (state.isProfile) {
          name =
            (state.entry.companyName ? `${state.entry.companyName} ` : '') +
            state.entry[INVEST_PROFILE_FIELDS.NAME.NAME];
        }

        name = state.entry[(state.isFund ? FUND_FIELDS : PORTFOLIO_FIELDS).NAME.NAME];
        return name || this.entry.companyName;
      },
      fields: mapFieldsToState(OUTREACH_FIELDS),
      setFieldValue: (field = {}, value) => {
        state.fields[field.NAME] = value;
      },
      documents: [],
      onSubmitErrorState: false,
      setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
      get validationFields() {
        return getErrorFields(Object.values(OUTREACH_FIELDS), state.fields);
      },
      get isSendDisabled() {
        return (
          outreachStore.isSendingOutreach ||
          !state.hasContact ||
          state.isEditingContact ||
          !!state.documents.find((d) => d.isUploading) ||
          state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
          (state.onSubmitErrorState && state.validationFields.invalidFields.length)
        );
      },
      isMessageSent: false,
      messageError: null,
      sendMessage: () => {
        trimStateFields(state.fields);
        if (state.validationFields.invalidFields.length) {
          if (!state.onSubmitErrorState) {
            state.setOnSubmitErrorState(true);
          }
          return;
        }

        if (outreachStore.isSendingOutreach) {
          return;
        }

        state.messageError = null;
        submitMessage(
          state.entry.id,
          state.fields[OUTREACH_FIELDS.SUBJECT.NAME],
          state.fields[OUTREACH_FIELDS.MESSAGE.NAME],
          state.documents.map((d) => d.key),
          () => {
            if (!state.isRendered) {
              utilsStore.setHeaderMessage(
                state.hasContact
                  ? `Outreach to ${state.contactName}, ${state.entryName} initiated.`
                  : `Message was sent to ${state.contactName} for ${state.entryName}`
              );
              return;
            }
            state.isMessageSent = true;
            eventOutreachSearch();
          },
          (errorMessage) => {
            if (!state.isRendered) {
              utilsStore.setHeaderMessage(
                state.hasContact
                  ? `Failed to send message to investor ${state.contactName} regarding ${state.entryName}!`
                  : `Failed to send message to ${state.contactName} for ${state.entryName}`,
                HEADER_MESSAGE_TYPES.ERROR
              );
              return;
            }
            state.messageError = errorMessage || 'Failed to send message to investor';
          }
        );
      },
      canEditContact: true,
      get disabledContactInputs() {
        return state.isBringingBackDefaultContact || state.isSavingContact;
      },
      editContactFields: mapFieldsToState(OUTREACH_CONTACT_FIELDS),
      setContactFieldValue: (field = {}, value) => {
        state.editContactFields[field.NAME] = value;
      },
      isEditingContact: false,
      onEditContactBtnClick: () => {
        state.editContactFields[OUTREACH_CONTACT_FIELDS.FIRST_NAME.NAME] = '';
        state.editContactFields[OUTREACH_CONTACT_FIELDS.LAST_NAME.NAME] = '';
        state.editContactFields[OUTREACH_CONTACT_FIELDS.EMAIL.NAME] = '';
        state.isEditingContact = true;
        state.saveContactError = null;
      },
      onDiscardContactBtnClick: () => {
        state.isEditingContact = false;
      },
      get showBringBackDefaultContactBtn() {
        return !!state.entry.customContactInfo?.firstName;
      },
      get disabledBringBackDefaultContactBtn() {
        return state.isBringingBackDefaultContact;
      },
      deleteAnimation: false,
      isBringingBackDefaultContact: false,
      onBringBackDefaultContactBtnClick: () => {
        state.isBringingBackDefaultContact = true;
        state.saveContactError = null;
        makeRequest({
          endpoint: API_ENDPOINTS.DELETE_MATCHMAKER_CONTACT,
          body: { entryId: state.entry.id },
          onSuccess: () => {
            state.entry.customContactInfo = null;
            state.isEditingContact = false;
            state.deleteAnimation = true;
            setTimeout(() => {
              state.deleteAnimation = false;
            }, 500);
          },
          onError: (errorMessage) => {
            state.saveContactError = errorMessage || 'Failed to bring back default contact.';
          },
          onFinally: () => {
            state.isBringingBackDefaultContact = false;
          }
        });
      },
      isSavingContact: false,
      saveContactError: null,
      get disableDiscardContactBtn() {
        return state.isSavingContact || state.isBringingBackDefaultContact;
      },
      get disableSaveContactBtn() {
        return (
          state.isSavingContact ||
          state.isBringingBackDefaultContact ||
          !state.editContactFields[OUTREACH_CONTACT_FIELDS.FIRST_NAME.NAME].trim() ||
          !state.editContactFields[OUTREACH_CONTACT_FIELDS.LAST_NAME.NAME].trim() ||
          !state.editContactFields[OUTREACH_CONTACT_FIELDS.EMAIL.NAME].trim()
        );
      },
      saveAnimation: false,
      saveContactChange: () => {
        state.isSavingContact = true;
        state.saveContactError = null;
        trimStateFields(state.editContactFields);
        makeRequest({
          endpoint: API_ENDPOINTS.SAVE_MATCHMAKER_CONTACT,
          body: {
            firstName: state.editContactFields[OUTREACH_CONTACT_FIELDS.FIRST_NAME.NAME],
            lastName: state.editContactFields[OUTREACH_CONTACT_FIELDS.LAST_NAME.NAME],
            email: state.editContactFields[OUTREACH_CONTACT_FIELDS.EMAIL.NAME],
            searchType: forSearchType,
            entryId: state.entry.id
          },
          onSuccess: () => {
            state.entry.customContactInfo = {
              firstName: state.editContactFields[OUTREACH_CONTACT_FIELDS.FIRST_NAME.NAME],
              lastName: state.editContactFields[OUTREACH_CONTACT_FIELDS.LAST_NAME.NAME],
              email: state.editContactFields[OUTREACH_CONTACT_FIELDS.EMAIL.NAME]
            };
            state.isEditingContact = false;
            state.saveAnimation = true;
            setTimeout(() => {
              state.saveAnimation = false;
            }, 500);
          },
          onError: (errorMessage) => {
            state.saveContactError = errorMessage || 'Failed to save contact changes.';
          },
          onFinally: () => {
            state.isSavingContact = false;
          }
        });
      },
      overlayHeight: window.visualViewport.height + 2000,
      updateOverlayHeight: () => (state.overlayHeight = window.visualViewport.height + 2000),
      get layout() {
        return utilsStore.windowWidth < 481 ? 'mobile' : 'desktop';
      }
    }));

    useEffect(() => {
      state.setIsRendered(true);
      const onViewportResize = () => {
        state.updateOverlayHeight();
      };
      window.visualViewport.addEventListener('resize', onViewportResize);
      return () => {
        state.setIsRendered(false);
        window.visualViewport.removeEventListener('resize', onViewportResize);
      };
    }, [state]);

    const isLoading = outreachStore.isSendingOutreach;

    return (
      <div className="overlay" style={{ height: state.overlayHeight + 'px' }}>
        <div className="container popup-message">
          {isLoading ? (
            <>
              <div className="header message">
                <div className="text"></div>
                <div className="close" onClick={() => displayMessagePopup(null)}>
                  Close
                </div>
              </div>
              <div className="content">
                <div className="success">
                  <GeneralLoader />
                </div>
              </div>
            </>
          ) : state.isMessageSent ? (
            <>
              <div className="header message">
                <div className="text"></div>
                <div className="close" onClick={() => displayMessagePopup(null)}>
                  Close
                </div>
              </div>
              <div className="content">
                <div className="success">
                  <img src="/icons/check-circle-blue-40.svg" alt="Success" />
                  <div className="text">
                    Outreach to <span className="names"> {state.contactName}</span>,{' '}
                    {state.entryName} initiated.
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={`header message single`}>
                <div className="text">
                  <label style={state.layout === 'desktop' ? { paddingTop: '3px' } : {}}>
                    Contact
                  </label>
                  <div className="value">
                    <div
                      style={{
                        ...(state.layout === 'desktop' && state.isEditingContact
                          ? { lineHeight: '28px' }
                          : {})
                      }}
                      className={
                        state.saveAnimation
                          ? 'save-custom-contact'
                          : state.deleteAnimation
                          ? 'delete-custom-contact'
                          : ''
                      }>
                      {state.contactName || 'N/A'}, {state.entryName || ''}
                    </div>
                    {!state.isEditingContact && (
                      <IconButton
                        type={ICON_BUTTON_TYPES.BLUE}
                        icon={ICON_BUTTON_ICONS.PEN}
                        tooltipText="Edit contact"
                        onClick={state.onEditContactBtnClick}
                      />
                    )}
                  </div>
                </div>
                <div className="close" onClick={() => displayMessagePopup(null)}>
                  Close
                </div>
              </div>
              {state.isEditingContact && (
                <div className="custom-contact-wrap">
                  <div className="instructions">
                    Please enter a name and email to replace with your preferred contact
                  </div>
                  <div className="contact-inputs-wrap">
                    <div className="left">
                      <Input
                        field={OUTREACH_CONTACT_FIELDS.FIRST_NAME}
                        value={state.editContactFields[OUTREACH_CONTACT_FIELDS.FIRST_NAME.NAME]}
                        setFieldValue={state.setContactFieldValue}
                        disabled={state.disabledContactInputs}
                      />
                    </div>
                    <div className="right">
                      <Input
                        field={OUTREACH_CONTACT_FIELDS.LAST_NAME}
                        value={state.editContactFields[OUTREACH_CONTACT_FIELDS.LAST_NAME.NAME]}
                        setFieldValue={state.setContactFieldValue}
                        disabled={state.disabledContactInputs}
                      />
                    </div>
                  </div>
                  <div className="contact-inputs-wrap email-wrap">
                    <div className="left">
                      <Input
                        field={OUTREACH_CONTACT_FIELDS.EMAIL}
                        value={state.editContactFields[OUTREACH_CONTACT_FIELDS.EMAIL.NAME]}
                        setFieldValue={state.setContactFieldValue}
                        disabled={state.disabledContactInputs}
                      />
                    </div>
                    <div className="right">
                      <div className="email-domain">{'@' + (state.entry.domain || '')}</div>
                    </div>
                  </div>
                  <div className="contact-actions-wrap">
                    <div className="left">
                      {state.showBringBackDefaultContactBtn && (
                        <IconButton
                          innerText="Bring back default contact"
                          disabled={state.disabledBringBackDefaultContactBtn}
                          onClick={state.onBringBackDefaultContactBtnClick}
                          icon={ICON_BUTTON_ICONS.ARROW_COUNTERCLOCKWISE}
                          withBorder={false}
                        />
                      )}
                    </div>
                    <div className="right">
                      <IconButton
                        type={ICON_BUTTON_TYPES.RED}
                        innerText="Discard"
                        disabled={state.disableDiscardContactBtn}
                        onClick={state.onDiscardContactBtnClick}
                      />
                      <IconButton
                        type={ICON_BUTTON_TYPES.BLUE}
                        innerText="Save changes"
                        disabled={state.disableSaveContactBtn}
                        onClick={state.saveContactChange}
                        filled
                      />
                    </div>
                  </div>
                  {!!state.saveContactError && (
                    <div className="errors">{state.saveContactError}</div>
                  )}
                </div>
              )}
              <div className="separator"></div>
              <div className="content">
                <Input
                  field={OUTREACH_FIELDS.SUBJECT}
                  value={state.fields[OUTREACH_FIELDS.SUBJECT.NAME]}
                  setFieldValue={state.setFieldValue}
                  disabled={isLoading}
                  messages={state.validationFields.messages}
                  showOnSubmitErrorState={true}
                  inputWrap={{ enable: true, className: 'field' }}
                />
                <TextArea
                  field={OUTREACH_FIELDS.MESSAGE}
                  value={state.fields[OUTREACH_FIELDS.MESSAGE.NAME]}
                  setFieldValue={state.setFieldValue}
                  disabled={isLoading}
                  messages={state.validationFields.messages}
                  showOnSubmitErrorState={true}
                  inputWrap={{ enable: true, className: 'field no-margin-bottom' }}
                />
                <PDFList documents={state.documents} />
                <div className="info">
                  Outreach implies adherence to transact.digital{' '}
                  <Link
                    to={paths.TERMS_N_CONDITIONS}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="terms-link">
                    terms
                  </Link>
                  .
                </div>
                <div className="actions outreach">
                  <div className="pdf-action">
                    <IconButton
                      type={ICON_BUTTON_TYPES.BLUE}
                      icon={ICON_BUTTON_ICONS.PAPERCLIP}
                      isPDF
                      onPDF={(file) => {
                        const size = file.size;
                        const sizeInMB = '(' + (size / (1024 * 1024)).toFixed(2) + 'mb)';
                        let key = (Math.random() + 1).toString(36).substring(7);
                        runInAction(() => {
                          state.documents.push({
                            key,
                            isUploading: true,
                            name: file.name,
                            size: sizeInMB
                          });
                        });

                        makeRequest({
                          endpoint: API_ENDPOINTS.DOCUMENT_PRESIGN,
                          body: { filename: file.name, size },
                          onSuccess: (response) => {
                            if (!state.isRendered) {
                              return;
                            }

                            const foundIndex = state.documents.findIndex((d) => d.key === key);
                            if (foundIndex !== -1) {
                              state.documents.splice(foundIndex, 1);
                            }

                            const { presignedUrl } = response;
                            key = response.key;

                            state.documents.splice(foundIndex, 0, {
                              key,
                              isUploading: true,
                              name: file.name,
                              size: sizeInMB
                            });

                            (async () => {
                              try {
                                await fetch(presignedUrl, {
                                  method: REQ_METHODS.PUT,
                                  headers: {
                                    'Content-Type': CONTENT_TYPES.TEXT_PLAIN
                                  },
                                  body: file
                                });

                                if (!state.isRendered) {
                                  return;
                                }

                                const foundIndex = state.documents.findIndex((d) => d.key === key);
                                if (foundIndex !== -1) {
                                  runInAction(() => {
                                    state.documents[foundIndex].isUploading = false;
                                  });
                                }
                              } catch (error) {
                                if (!state.isRendered) {
                                  return;
                                }

                                const foundIndex = state.documents.findIndex((d) => d.key === key);
                                if (foundIndex !== -1) {
                                  runInAction(() => {
                                    state.documents.splice(foundIndex, 1);
                                  });
                                }

                                console.log('Error from ', presignedUrl);
                                console.log('error', error);
                              }
                            })();
                          },
                          onError: (errorMessage) => {
                            const foundIndex = state.documents.findIndex((d) => d.key === key);
                            if (foundIndex !== -1) {
                              state.documents.splice(foundIndex, 1);
                            }
                            console.log('Error from /documents-presign-url');
                            console.log('errorMessage', errorMessage);
                          }
                        });
                      }}
                    />
                    <div className="pdf-action-info">pdf format only</div>
                  </div>
                  <IconButton
                    type={ICON_BUTTON_TYPES.BLUE}
                    innerText="Send message"
                    disabled={state.isSendDisabled}
                    onClick={state.sendMessage}
                    filled
                  />
                </div>
                {!!state.messageError && <div className="errors">{state.messageError}</div>}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
);

export default ProjectMessagePopup;
