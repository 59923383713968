import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { formatDateDDMMYYYY, mapFieldsToState } from '../../../utils/utils';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';
import {
  SEARCH_FIELDS,
  PROJECT_FIELDS,
  getErrorFields,
  trimStateFields
} from '../../../utils/constants/fields';
import { encodeProjectsParams } from '../constants';
import ArchivePopup from './ArchivePopup';
import Input from '../../../components/inputs/Input';
import { useEffect } from 'react';
import IconButton, {
  ICON_BUTTON_TYPES,
  ICON_BUTTON_ICONS
} from '../../../components/buttons/IconButton';

const SEARCH_TYPES = {
  funds: 'Fund',
  portfolios: 'Portfolio company',
  investprofiles: 'Invest profile'
};

const ProjectListEntry = observer(({ project = {}, params = {} }) => {
  const { projectStore, utilsStore } = useStore();
  const { isArchivingProject, isChangingProjectName } = projectStore;
  const { navigate } = useHistory();
  const state = useLocalObservable(() => ({
    isArchivePopupOpen: false,
    toggleArchivePopup: (isOpen = false) => {
      state.isArchivePopupOpen = isOpen;
      utilsStore.lockScroll(isOpen);
    },
    isChangeNameToggled: false,
    toggleChangeName: () =>
      ([state.isChangeNameToggled, state.fields[PROJECT_FIELDS.NAME.NAME]] = [
        !state.isChangeNameToggled,
        project.name
      ]),
    fields: mapFieldsToState(PROJECT_FIELDS),
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    onSubmitErrorState: false,
    setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
    get validationFields() {
      return getErrorFields([PROJECT_FIELDS.NAME], state.fields);
    },
    get isSaveNameDisabled() {
      return (
        projectStore.isChangingProjectName ||
        state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
        (state.onSubmitErrorState && state.validationFields.invalidFields.length) ||
        state.fields[PROJECT_FIELDS.NAME.NAME] === project[PROJECT_FIELDS.NAME.NAME]
      );
    }
  }));

  useEffect(() => {
    return () => {
      if (state.isArchivePopupOpen) {
        state.toggleArchivePopup(false);
      }
    };
  }, [state]);

  const onViewDetailsClick = () => {
    navigate(
      paths.PROJECTS +
        '?params=' +
        encodeProjectsParams({ ...params, selectedProjectId: project.id })
    );
  };

  const onReExecuteSearchClick = () => {
    const initial = mapFieldsToState(SEARCH_FIELDS);
    const searchFields = {};
    Object.entries(initial).forEach(([fieldName, initialValue]) => {
      searchFields[fieldName] = project?.fields?.[fieldName] || initialValue;
    });
    const fieldsParams = Object.entries(searchFields)
      .filter((field) => field[1]?.length)
      .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
      .join('&');

    navigate(paths.SEARCH_RESULTS + `?page=1&${fieldsParams}`);
  };

  const onArchiveClick = () => {
    state.toggleArchivePopup(true);
  };

  const onSaveNameClick = () => {
    trimStateFields(state.fields);
    if (state.validationFields.invalidFields.length) {
      if (!state.onSubmitErrorState) {
        state.setOnSubmitErrorState(true);
      }
      return;
    }

    projectStore.changeProjectName(
      project.id,
      state.fields[PROJECT_FIELDS.NAME.NAME],
      state.toggleChangeName
    );
  };

  const hasNewFeedback = projectStore.hasProjectNewFeedback(project);

  return (
    <div className="row">
      {state.isArchivePopupOpen && (
        <ArchivePopup project={project} toggle={() => state.toggleArchivePopup(false)} />
      )}
      {!state.isChangeNameToggled ? (
        <div className="item project-name">
          <div className="label">Project name</div>
          <div className="value">
            {project[PROJECT_FIELDS.NAME.NAME]}
            <IconButton
              key="edit-project-name"
              icon={ICON_BUTTON_ICONS.PEN}
              disabled={isChangingProjectName}
              onClick={state.toggleChangeName}
              withBorder={false}
            />
          </div>
        </div>
      ) : (
        <div className="item project-change-name">
          <Input
            field={PROJECT_FIELDS.NAME}
            value={state.fields[PROJECT_FIELDS.NAME.NAME]}
            setFieldValue={state.setFieldValue}
            disabled={isChangingProjectName}
            messages={state.validationFields.messages}
            showOnSubmitErrorState
            hideLabel
          />
          <div className="icons">
            <IconButton
              key="save-change-project-name"
              icon={ICON_BUTTON_ICONS.CHECK_LG}
              type={ICON_BUTTON_TYPES.GREEN}
              onClick={onSaveNameClick}
              disabled={state.isSaveNameDisabled}
              withBorder={false}
            />
            <IconButton
              key="cancel-change-project-name"
              icon={ICON_BUTTON_ICONS.X_LG}
              type={ICON_BUTTON_TYPES.RED}
              onClick={state.toggleChangeName}
              disabled={projectStore.isChangingProjectName}
              withBorder={false}
            />
          </div>
        </div>
      )}
      <div className="item">
        <div className="label">Date saved</div>
        <div className="value">{formatDateDDMMYYYY(project.dateSaved)}</div>
      </div>
      <div className="item">
        <div className="label">Number of results</div>
        <div className="value text-align-right-desktop">{project.resultsCount}</div>
      </div>
      <div className="item">
        <div className="label">Search type</div>
        <div className="value">{SEARCH_TYPES[project.searchType]}</div>
      </div>
      <div className="actions desktop-only">
        <IconButton
          type={ICON_BUTTON_TYPES.BLUE}
          innerText="View details"
          onClick={onViewDetailsClick}
          filled
        />
        <IconButton onClick={onReExecuteSearchClick} innerText="Re-execute" />
        <IconButton
          type={ICON_BUTTON_TYPES.BLUE}
          icon={
            project.status === 'ACTIVE' ? ICON_BUTTON_ICONS.ARCHIVE : ICON_BUTTON_ICONS.ARROW_REPEAT
          }
          disabled={isArchivingProject}
          tooltipText={
            isArchivingProject
              ? ''
              : project.status === 'ACTIVE'
              ? `Archive ${project.name}`
              : `Unarchive ${project.name}`
          }
          onClick={onArchiveClick}
        />
      </div>
      <div className="actions-mobile mobile-only">
        <div className="actions-stretch">
          <IconButton
            type={ICON_BUTTON_TYPES.BLUE}
            innerText="View details"
            onClick={onViewDetailsClick}
            filled
          />
          <IconButton onClick={onReExecuteSearchClick} innerText="Re-execute" />
        </div>
        <IconButton
          type={ICON_BUTTON_TYPES.BLUE}
          icon={
            project.status === 'ACTIVE' ? ICON_BUTTON_ICONS.ARCHIVE : ICON_BUTTON_ICONS.ARROW_REPEAT
          }
          disabled={isArchivingProject}
          tooltipText={
            isArchivingProject
              ? ''
              : project.status === 'ACTIVE'
              ? `Archive ${project.name}`
              : `Unarchive ${project.name}`
          }
          onClick={onArchiveClick}
        />
      </div>
      {hasNewFeedback && <div className="project-notif-dot" />}
    </div>
  );
});

export default ProjectListEntry;
