import { observer } from 'mobx-react';
import { FIELDS_TYPES } from '../../../utils/constants/fields';
import Input from '../../../components/inputs/Input';

const SearchNameFormDesktop = observer(() => {
  return (
    <form onSubmit={() => {}} className="desktop">
      <div className="fields-area">
        <div className="fields-area-title with-border-bottom">Search by company name</div>
        <div className="row">
          <Input
            field={{
              NAME: 'companyName',
              ID: 'profiler_company_name',
              PLACEHOLDER: 'Provide company name & legal form',
              REQUIRED: true,
              TYPE: FIELDS_TYPES.TYPE_TEXT
            }}
            value={''}
            setFieldValue={() => {}}
            showOnSubmitErrorState={true}
            messages={[]}
            disabled
          />
        </div>
      </div>
      <div className="separator profiler by-name"></div>

      <div className="submit-area">
        <button type="submit" className="btn btn-primary disable-fade-primary" disabled={true}>
          Search
        </button>
      </div>
    </form>
  );
});

export default SearchNameFormDesktop;
