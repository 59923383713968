import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import IconButton, { ICON_BUTTON_TYPES } from '../../../components/buttons/IconButton';
import { FEEDBACK_FIELDS, getErrorFields } from '../../../utils/constants/fields';
import TextArea from '../../../components/inputs/TextArea';
import MultiSelect from '../../../components/inputs/MultiSelect';
import { mapFieldsToState } from '../../../utils/utils';
import { useEffect } from 'react';

const FeedbackPopup = observer(({ toggle = () => {}, projectId = '' }) => {
  const { projectStore, authStore } = useStore();
  const state = useLocalObservable(() => ({
    rendered: true,
    unrender: () => (state.rendered = false),
    fields: mapFieldsToState(FEEDBACK_FIELDS),
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    get validationFields() {
      return getErrorFields(Object.values(FEEDBACK_FIELDS), state.fields);
    },
    get usersList() {
      const users = authStore.companyUsers
        .filter((u) => u.id !== authStore.currentUserId)
        .map((u) => ({
          name: u.firstName + ' ' + u.lastName + ' • ' + u.jobPosition,
          value: u.id
        }));

      for (let i = 0; i < users.length; i++) {
        for (let j = i + 1; j < users.length; j++) {
          if (users[j].name === users[i].name) {
            users[j].name += ' ';
          }
        }
      }

      return users;
    },
    overlayHeight: window.visualViewport.height + 2000,
    updateOverlayHeight: () => (state.overlayHeight = window.visualViewport.height + 2000)
  }));

  useEffect(() => {
    const onViewportResize = () => {
      state.updateOverlayHeight();
    };
    window.visualViewport.addEventListener('resize', onViewportResize);
    return () => {
      state.unrender();
      window.visualViewport.removeEventListener('resize', onViewportResize);
    };
  }, [state]);

  const requestFeedback = () => {
    projectStore.requestProjectFeedback(
      projectId,
      state.fields[FEEDBACK_FIELDS.COLLEAGUES.NAME],
      state.fields[FEEDBACK_FIELDS.NOTE.NAME],
      () => {
        if (state.rendered) {
          toggle();
        }
      }
    );
  };

  const isSendButtonDisabled =
    state.fields[FEEDBACK_FIELDS.COLLEAGUES.NAME].length === 0 ||
    state.fields[FEEDBACK_FIELDS.NOTE.NAME] === '' ||
    projectStore.isRequestingProjectFeedback;

  return (
    <div className="overlay" style={{ height: state.overlayHeight + 'px' }}>
      <div className="container popup-feedback">
        <div className="header feedback">
          <div className="text">Request feedback from your team</div>
          <div className="close" onClick={toggle}>
            Close
          </div>
        </div>
        <div className="separator"></div>
        <div className="content">
          <MultiSelect
            field={FEEDBACK_FIELDS.COLLEAGUES}
            value={state.fields[FEEDBACK_FIELDS.COLLEAGUES.NAME]}
            values={state.usersList}
            setFieldValue={state.setFieldValue}
            disabled={!!projectStore.isRequestingProjectFeedback}
            inputWrap={{ enable: true, className: 'field' }}
          />
          <TextArea
            field={FEEDBACK_FIELDS.NOTE}
            value={state.fields[FEEDBACK_FIELDS.NOTE.NAME]}
            setFieldValue={state.setFieldValue}
            disabled={!!projectStore.isRequestingProjectFeedback}
            messages={state.validationFields.messages}
            showOnSubmitErrorState={true}
            inputWrap={{ enable: true, className: 'field' }}
          />
          <div className="info">
            Feedback requests are sent individually. Only the requestor can view consolidated
            feedbacks.
          </div>
          <div className="actions">
            <IconButton
              type={ICON_BUTTON_TYPES.BLUE}
              innerText="Send"
              disabled={isSendButtonDisabled}
              onClick={requestFeedback}
              filled
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default FeedbackPopup;
