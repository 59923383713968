import { observer } from 'mobx-react';
import * as Portal from '@radix-ui/react-portal';
import IconButton, { ICON_BUTTON_TYPES } from '../buttons/IconButton';
import useHistory from '../../hooks/useHistory';
import { paths } from '../../utils/constants/routes';
import { encodeOutreachParams } from '../../pages/Outreach/desktop/constants';

const ExistingOutreachPopup = observer(({ onClose = () => {}, outreach = {} }) => {
  const { navigate } = useHistory();
  return (
    <Portal.Root>
      <div className="existing-outreach-popup-closer" onClick={onClose} />
      <div className="existing-outreach-popup">
        <div className="title">The outreach message has already been sent.</div>
        <div className="input-wrap">
          <IconButton innerText="Cancel" onClick={onClose} />
          <IconButton
            innerText="Open outreach"
            type={ICON_BUTTON_TYPES.BLUE}
            filled
            onClick={() =>
              navigate(
                paths.OUTREACH +
                  '?params=' +
                  encodeOutreachParams({ selected: 'recent', type: null, outreachId: outreach.id })
              )
            }
          />
        </div>
      </div>
    </Portal.Root>
  );
});

export default ExistingOutreachPopup;
