import { REQ_METHODS } from './methods';
import { formatPercent, formatNumber } from '../utils/utils';
import { mapCompanyEntry } from './dataMappers';
import { path } from 'd3';

export const API_ENDPOINTS = {
  GET_WEBSOCKET_TOKEN: {
    path: '/get-websocket-token',
    method: REQ_METHODS.GET,
    withCognitoToken: true
  },
  GET_PUBLIC_OPTIONS: {
    path: '/public/nomenclature',
    method: REQ_METHODS.GET
  },
  GET_ACCOUNT_VERIFICATION_PROGRESS: {
    path: '/account-verification-progress',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  GET_ACCOUNT_TYPE: {
    path: '/account-type',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  GET_USER_INFO: {
    path: '/get-user-info',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  EDIT_USER_INFO: {
    path: '/update-user-info',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
  },
  DEACTIVATE_USER_ACCOUNT: {
    path: '/deactivate-user',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  GET_PROFILE_COMPANY: {
    path: '/get-profile-company',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  EDIT_PROFILE_COMPANY: {
    path: '/update-profile-company',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
  },
  GET_SEARCH_RESULTS: {
    path: '/get-search-results',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  GET_PROJECTS: {
    path: '/list-projects',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  GET_PROJECT_RESULTS: {
    path: '/get-project-results',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  SAVE_PROJECT: {
    path: '/create-project',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  UPDATE_PROJECT_NAME: {
    path: '/change-project-name',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
  },
  EXCLUDE_PROJECT_RESULT: {
    path: '/exclude-project-result',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  REQUEST_PROJECT_FEEDBACK: {
    path: '/request-project-feedback',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  RESOLVE_PROJECT_FEEDBACK: {
    path: '/resolve-project-feedback',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  UPDATE_PROJECT_LAST_CHECKED_DATE: {
    path: '/update-project-owner-last-checked-date',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  ARCHIVE_PROJECT: {
    path: '/archive-project',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
  },
  DELETE_PROJECTS: {
    path: '/delete-projects',
    withCognitoToken: true,
    method: REQ_METHODS.DELETE
  },
  GET_SELF_COMPANY_FUNDS: {
    path: '/get-self-company-funds',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  GET_SELF_COMPANY_PORTFOLIOS: {
    path: '/get-self-company-portfolios',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  GET_SELF_COMPANY_INVEST_PROFILES: {
    path: '/get-self-company-invest-profiles',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  GET_SELF_COMPANY_USERS: {
    path: '/get-self-company-users',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  GET_FUNDS: {
    path: '/get-funds',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  EDIT_FUND: {
    path: '/update-funds',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
  },
  CREATE_FUND: {
    path: '/create-funds',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  DELETE_FUND: {
    path: '/delete-funds',
    withCognitoToken: true,
    method: REQ_METHODS.DELETE
  },
  GET_PORTFOLIO_COMPANIES: {
    path: '/get-portfolio-companies',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  EDIT_PORTFOLIO_COMPANY: {
    path: '/update-portfolio-companies',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
  },
  CREATE_PORTFOLIO_COMPANY: {
    path: '/create-portfolio-companies',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  DELETE_PORTFOLIO_COMPANY: {
    path: '/delete-portfolio-companies',
    withCognitoToken: true,
    method: REQ_METHODS.DELETE
  },
  GET_INVEST_PROFILES: {
    path: '/get-invest-profiles',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  EDIT_INVEST_PROFILE: {
    path: '/update-invest-profiles',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
  },
  CREATE_INVEST_PROFILE: {
    path: '/create-invest-profiles',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  DELETE_INVEST_PROFILE: {
    path: '/delete-invest-profiles',
    withCognitoToken: true,
    method: REQ_METHODS.DELETE
  },
  DOCUMENT_PRESIGN: {
    path: '/documents-presign-url',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  POST_OUTREACH_MESSAGE: {
    path: '/send-outreach',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  GET_OUTREACHES: {
    path: '/get-outreaches',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  GET_OUTREACH_MESSAGES: {
    path: '/get-outreaches-messages',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  SEND_OUTREACH_MESSAGE: {
    path: '/send-outreach-message',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  UPDATE_OUTREACH_LAST_CHECKED_MESSAGE: {
    path: '/update-outreach-user-last-checked-message',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  ARCHIVE_OUTREACH: {
    path: '/archive-outreach',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  FORWARD_OUTREACH: {
    path: '/forward-outreach',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  REQUEST_OUTREACH_FEEDBACK: {
    path: '/request-outreach-feedback',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  EDIT_OUTREACH_MESSAGE: {
    path: '/edit-outreach-message',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  DELETE_OUTREACH_MESSAGE: {
    path: '/delete-outreach-message',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  GET_DOCUMENT_DOWNLOAD_URL: {
    path: '/document-download',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  RATE_OUTREACH: {
    path: '/rate-outreach',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  MARK_OUTREACH_UNREAD: {
    path: '/mark-outreach-unread',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  ADMIN_LIST_USERS: {
    path: '/admin-list-users',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  ADMIN_UPDATE_USER: {
    path: '/admin-update-user',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
  },
  ADMIN_LIST_FPI: {
    path: '/admin-list-fpi',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  GET_PROFILER_SEARCH_RESULTS: {
    path: '/get-profiler-results',
    withCognitoToken: true,
    method: REQ_METHODS.POST,
    dataMapper: (data) => {
      (data.results || []).forEach(mapCompanyEntry);
    }
  },
  GET_PROFILER_PROJECTS: {
    path: '/list-profiler-projects',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  GET_PROFILER_PROJECT_RESULTS: {
    path: '/get-profiler-project-results',
    withCognitoToken: true,
    method: REQ_METHODS.POST,
    dataMapper: (data) => {
      (data.results || []).forEach(mapCompanyEntry);
    }
  },
  SAVE_PROFILER_PROJECT: {
    path: '/create-profiler-project',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  UPDATE_PROFILER_PROJECT_NAME: {
    path: '/change-profiler-project-name',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
  },
  EXCLUDE_PROFILER_PROJECT_RESULT: {
    path: '/exclude-profiler-project-result',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  REQUEST_PROFILER_PROJECT_FEEDBACK: {
    path: '/request-profiler-project-feedback',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  RESOLVE_PROFILER_PROJECT_FEEDBACK: {
    path: '/resolve-profiler-project-feedback',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  UPDATE_PROFILER_PROJECT_LAST_CHECKED_DATE: {
    path: '/update-profiler-project-owner-last-checked-date',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  ARCHIVE_PROFILER_PROJECT: {
    path: '/archive-profiler-project',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
  },
  DELETE_PROFILER_PROJECTS: {
    path: '/delete-profiler-projects',
    withCognitoToken: true,
    method: REQ_METHODS.DELETE
  },
  GET_PROFILER_WATCHLIST: {
    path: '/get-profiler-watch-list',
    withCognitoToken: true,
    method: REQ_METHODS.GET,
    dataMapper: (data) => {
      (data || []).forEach(mapCompanyEntry);
    }
  },
  ADD_TO_PROFILER_WATCHLIST: {
    path: '/add-to-profiler-watch-list',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  UPDATE_PROFILER_WATCHLIST_NEWS_CHECKED: {
    path: '/set-profiler-news-checked',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  GET_COMPANY_LOGO: {
    path: '/get-company-logo',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  GET_SIMILAR_COMPANIES: {
    path: '/get-similar-companies',
    withCognitoToken: true,
    method: REQ_METHODS.POST,
    dataMapper: (data) => {
      (data || []).forEach(mapCompanyEntry);
    }
  },
  GET_PROFILER_DETAILS: {
    path: '/get-profiler-details',
    withCognitoToken: true,
    method: REQ_METHODS.POST,
    dataMapper: (data) => {
      data.general = {};
      let years = [
        ...new Set(data.financial_overview.filter((ntry) => ntry.year).map((ntry) => ntry.year))
      ];
      const [minYear, maxYear] = [Math.min(...years), Math.max(...years)];
      years = [];
      for (let y = minYear; y <= maxYear; y++) {
        years.push(y);
      }

      const [revenues, grossProfit, netIncome, totalAssets, cashAndEquivalents] = [
        [],
        [],
        [],
        [],
        []
      ];

      const _formatValueToEM = (ntry) => {
        if (ntry.value && !isNaN(ntry.value)) {
          if (ntry.value === 0 || ntry.value === '0') {
            ntry.value = '0';
            return;
          }

          ntry.value = formatNumber((Number(ntry.value) / 1000000).toFixed(2));
          if (ntry.value === '0') {
            //
          }
        }
      };

      const _formatPercent = (ntry) => {
        if (ntry.value && !isNaN(ntry.percentage)) {
          ntry.percentage = formatPercent((Number(ntry.percentage) * 100).toFixed(2));
        }
      };

      data.financial_overview.forEach((ntry) => {
        const parent = {
          sales_revenue: { group: revenues, formatters: [_formatValueToEM, _formatPercent] },
          gross_profit: { group: grossProfit, formatters: [_formatValueToEM, _formatPercent] },
          net_income: { group: netIncome, formatters: [_formatValueToEM, _formatPercent] },
          total_assets: { group: totalAssets, formatters: [_formatValueToEM] },
          cash_and_liquid_assets: { group: cashAndEquivalents, formatters: [_formatValueToEM] }
        }[ntry.overview_type];

        if (!parent) {
          return;
        }

        parent.group.push(ntry);
        parent.formatters?.forEach?.((formatter) => {
          formatter(ntry);
        });
      });

      data.general.years = years;
      data.general.revenues = revenues;
      data.general.grossProfit = grossProfit;
      data.general.netIncome = netIncome;
      data.general.totalAssets = totalAssets;
      data.general.cashAndEquivalents = cashAndEquivalents;

      const groups = [
        {
          id: 'profitAndLoss',
          title: 'Profit & loss statement',
          statement_types: ['profit_and_loss'],
          items: [],
          levels: new Set()
        },
        {
          id: 'balanceSheet',
          title: 'Balance sheet',
          statement_types: ['liability', 'asset'],
          items: [],
          levels: new Set()
        }
      ];

      const nonFormattableValuesKeys = ['number of employees'];

      let detailsYears = [
        ...new Set(data.financial_statements.filter((ntry) => ntry.year).map((ntry) => ntry.year))
      ];
      const [minDetailsYear, maxDetailsYear] = [
        Math.min(...detailsYears),
        Math.max(...detailsYears)
      ];
      detailsYears = [];
      for (let y = minDetailsYear; y <= maxDetailsYear; y++) {
        detailsYears.push(y);
      }

      data.financial_statements.forEach((ntry) => {
        for (let i = 0; i < groups.length; i++) {
          if (groups[i].statement_types.includes(ntry.statement_type)) {
            const foundItem = groups[i].items.find((item) => item.name === ntry.item_key);
            if (foundItem) {
              foundItem.entries.push(ntry);
            } else {
              const newItem = {
                name: ntry.item_key,
                entries: [ntry],
                priority: ntry.priority || 10000,
                level: !ntry.item_group_level ? 1 : ntry.item_group_level / 10
              };
              groups[i].items.push(newItem);
              groups[i].levels.add(newItem.level);
            }

            if (!nonFormattableValuesKeys.includes(ntry.item_key.toLowerCase())) {
              _formatValueToEM(ntry);
            }

            break;
          }
        }
      });

      groups.forEach((group) => {
        group.items.sort((a, b) => a.priority - b.priority);
        group.levels = [...group.levels].sort((a, b) => a - b);
        group.reliability = {};
        const allEntries = group.items.reduce((acc, item) => {
          return [...acc, ...item.entries];
        }, []);
        detailsYears.forEach((year) => {
          const foundReliability = allEntries.find(
            (ntry) => ntry.year === year && ntry.reliability
          )?.reliability;
          if (foundReliability) {
            group.reliability[year] = foundReliability;
          }
        });
      });

      data.details = { groups, years: detailsYears };

      data.keyContacts = data.key_contacts;

      data.shareholdersStructure = {
        nodes: data.shareholders,
        links: data.relationships
      };

      data.shareholdersStructure.nodes.forEach((node) => {
        if (node.target) {
          node.type = 'target';
        }

        node.id = +node.member_id;
      });

      data.shareholdersStructure.links.forEach((edge) => {
        [edge.source_id, edge.target_id] = [edge.target_id, edge.source_id];
        edge.id = edge.source_id + '' + edge.target_id;
        edge.source = +edge.source_id;
        edge.target = +edge.target_id;
        edge.value = edge.share || 'N/A';
      });
    }
  },
  SAVE_MATCHMAKER_CONTACT: {
    path: '/create-custom-contact',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  DELETE_MATCHMAKER_CONTACT: {
    path: '/delete-custom-contact',
    withCognitoToken: true,
    method: REQ_METHODS.DELETE
  },
  PROFILER_EXPORT_FINANCIALS: {
    path: '/export-profiler-financials',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  }
};
