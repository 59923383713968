import { observer } from 'mobx-react';
import { useStore } from '../../../../store/store';
import { PORTFOLIO_FIELDS } from '../../../../utils/constants/fields';
import { formatNumber } from '../../../../utils/utils';
import { PUNCTUATION } from '../../../../utils/constants/notation';

const PortfolioGeneralInformation = observer(({ entry }) => {
  const { utilsStore } = useStore();

  return (
    <div className="content">
      {!!entry.companyName && (
        <div className="row">
          <div className="bullet"></div>
          <div className="label">Private equity firm</div>
          <div className="value">{entry.companyName}</div>
        </div>
      )}
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{PORTFOLIO_FIELDS.NAME.LABEL}</div>
        <div className="value">{entry[PORTFOLIO_FIELDS.NAME.NAME] || PUNCTUATION.EMPTY_VALUE}</div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{PORTFOLIO_FIELDS.AFFILIATED_FUND.LABEL}</div>
        <div className="value">{entry.affiliateFundName || PUNCTUATION.EMPTY_VALUE}</div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{PORTFOLIO_FIELDS.ACQUISITION_DATE.LABEL}</div>
        <div className="value">
          {entry[PORTFOLIO_FIELDS.ACQUISITION_DATE.NAME] || PUNCTUATION.EMPTY_VALUE}
        </div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{PORTFOLIO_FIELDS.REVENUE.LABEL} (€M)</div>
        <div className="value">{formatNumber(entry[PORTFOLIO_FIELDS.REVENUE.NAME])}</div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{PORTFOLIO_FIELDS.EBITDA.LABEL} (€M)</div>
        <div className="value">{formatNumber(entry[PORTFOLIO_FIELDS.EBITDA.NAME])}</div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{PORTFOLIO_FIELDS.DESCRIPTION.LABEL}</div>
        <div className="value">{entry[PORTFOLIO_FIELDS.DESCRIPTION.NAME]}</div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{PORTFOLIO_FIELDS.INDUSTRIES.LABEL}</div>
        <div className="value">
          {utilsStore.getOptionName(entry, PORTFOLIO_FIELDS.INDUSTRIES).join(', ')}
        </div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{PORTFOLIO_FIELDS.GEOGRAPHIES.LABEL}</div>
        <div className="value">
          {utilsStore.getOptionName(entry, PORTFOLIO_FIELDS.GEOGRAPHIES).join(', ')}
        </div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{PORTFOLIO_FIELDS.SITUATION.LABEL}</div>
        <div className="value">
          {utilsStore.getOptionName(entry, PORTFOLIO_FIELDS.SITUATION).join(', ')}
        </div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{PORTFOLIO_FIELDS.EQUITY.LABEL}</div>
        <div className="value">
          {utilsStore.getOptionName(entry, PORTFOLIO_FIELDS.EQUITY).join(', ')}
        </div>
      </div>
    </div>
  );
});

export default PortfolioGeneralInformation;
