import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { initGA } from './ga4/ga4';
import ConnectionError from './components/errors/ConnectionError';

const isSecure = window.location.protocol === 'https:';
if (
  !isSecure &&
  window.location.hostname !== 'localhost' &&
  process.env.REACT_APP_ENVIRONMENT !== 'dev'
) {
  // removed the https url check for dev because it's preventing testing of features on real device in local network setup
  // eg. 192.168.2.203:3003
  const targetURL = window.location.href.replace('http:', 'https:');
  window.location.replace(targetURL);
}

window.initialPage = { pathname: window.location.pathname, search: window.location.search };

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // Inits for dev
} else {
  Sentry.init({
    dsn: 'https://5dcab0e4381c4168bd14ddb04bbe2694@o4505356879724544.ingest.sentry.io/4505356882739200',
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [],
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new Sentry.Replay()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

// Google Analytics / Google Tag Manager
initGA();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);

const rootConnection = ReactDOM.createRoot(document.getElementById('root-connection'));
rootConnection.render(<ConnectionError />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
