import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../store/store';
import { ACCOUNT_TYPES, ACCOUNT_VERIF_TYPES } from '../../utils/constants/auth';
import { paths, routes } from '../../utils/constants/routes';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import MobileAppRefresher from '../loaders/MobileAppRefresher';
import { matchPath } from '../../utils/utils';
import useHistory from '../../hooks/useHistory';
import { Tooltip } from 'react-tooltip';

const MobileNavigation = observer(() => {
  const { utilsStore, authStore, outreachStore, projectStore, profilerProjectStore } = useStore();
  const { location } = useHistory();

  const { userAccountType, user, logout, userVerificationStatus, hasUserProfileCompany, userInfo } =
    authStore;

  const state = useLocalObservable(() => ({
    isLogoutConfirmationOpen: false,
    setIsLogoutConfirmationOpen: (value = false) => (state.isLogoutConfirmationOpen = value)
  }));

  const LOGOUT_ITEMS = [
    { href: paths.TERMS_N_CONDITIONS, name: 'Terms & conditions' },
    // { href: paths.COOKIE_POLICY, name: 'Cookie Policy' },
    { href: paths.PRIVACY_POLICY, name: 'Privacy Policy' },
    { href: paths.IMPRINT, name: 'Imprint' }
  ];

  const LOGIN_ITEMS = [
    { href: paths.HOME_FULL, name: 'Home', isActive: matchPath([paths.HOME_FULL], location) },
    {
      href: paths.USER_PROFILE,
      name: 'My account',
      isActive: matchPath([routes.USER_PROFILE_PATH], location)
    },
    {
      href: paths.COMPANY_PROFILE,
      name: 'My company',
      isActive: matchPath([paths.COMPANY_PROFILE], location)
    },
    {
      href: paths.PROFILER,
      name: 'Profiler',
      investorOnly: true,
      profilerOnly: true,
      isActive: matchPath([paths.PROFILER], location)
    },
    {
      href: paths.PROFILER_PROJECTS,
      name: 'Projects',
      investorOnly: true,
      profilerOnly: true,
      isActive: matchPath([paths.PROFILER_PROJECTS], location),
      errorNotificationDot:
        !profilerProjectStore.allProjects.length && !!profilerProjectStore.loadProjectsError,
      notificationDot: profilerProjectStore.hasNewProjectFeedback
    },
    {
      href: paths.PROFILER_WATCH_LIST,
      name: 'Watchlist',
      investorOnly: true,
      profilerOnly: true,
      isActive: matchPath([paths.PROFILER_WATCH_LIST], location),
      errorNotificationDot:
        !matchPath([paths.PROFILER_WATCH_LIST], location) &&
        !profilerProjectStore.watchlist.length &&
        !!profilerProjectStore.loadWatchlistError
    },
    {
      href: paths.PROFILER_TEAM,
      name: 'Team',
      investorOnly: true,
      profilerOnly: true,
      isActive: matchPath([paths.PROFILER_TEAM], location),
      notificationDot: profilerProjectStore.pendingSharedProjects.length > 0,
      errorNotificationDot:
        !profilerProjectStore.allProjects.length && !!profilerProjectStore.loadProjectsError
    },
    {
      href: paths.FUNDS,
      name: 'My funds & portfolios',
      investorOnly: true,
      nonCorporatesOnly: true,
      isActive: matchPath([routes.FUNDS_PATH, routes.PORTFOLIO_COMPANIES_PATH], location)
    },
    {
      href: paths.INVEST_PROFILES,
      name: 'My invest profiles',
      investorOnly: true,
      corporatesOnly: true,
      isActive: matchPath([routes.INVEST_PROFILES_PATH], location)
    },
    {
      href: paths.ALL_INVESTMENTS,
      name: 'All investments',
      investorOnly: true,
      masterOnly: true,
      isActive: matchPath([paths.ALL_INVESTMENTS], location)
    },
    {
      href: paths.PROJECTS,
      name: 'Projects',
      agentOnly: true,
      isActive: matchPath([paths.PROJECTS], location),
      notificationsCount: projectStore.projects
        .concat(projectStore.archivedProjects)
        .filter((p) => projectStore.hasProjectNewFeedback(p)).length
    },
    {
      href: paths.TEAM,
      name: 'Team',
      agentOnly: true,
      isActive: matchPath([paths.TEAM], location),
      notificationsCount: projectStore.pendingSharedProjects.length
    },
    {
      href: paths.OUTREACH,
      name: 'Outreach',
      isActive: matchPath([paths.OUTREACH], location),
      notificationsCount: outreachStore.totalOutreachMessagesCount,
      errorNotificationDot: !outreachStore.outreaches.length && !!outreachStore.loadOutreachesError
    },
    {
      href: paths.ADMIN_DASHBOARD,
      name: 'Admin dashboard',
      adminOnly: true,
      isActive: matchPath([paths.ADMIN_DASHBOARD], location)
    }
  ];

  useEffect(() => {
    if (utilsStore.isMobileHamburgerOpen && state.isLogoutConfirmationOpen) {
      state.setIsLogoutConfirmationOpen();
    }
  }, [utilsStore.isMobileHamburgerOpen]);

  const renderMenuItem = (
    {
      href,
      name,
      investorOnly,
      agentOnly,
      masterOnly,
      profilerOnly,
      corporatesOnly,
      nonCorporatesOnly,
      adminOnly,
      isActive,
      notificationsCount,
      notificationDot,
      errorNotificationDot
    },
    idx
  ) => {
    let className = isActive ? 'selected' : '';
    let disabled = false;

    if (investorOnly && userAccountType !== ACCOUNT_TYPES.INVESTOR) {
      className += ' hidden';
    }
    if (agentOnly && userAccountType !== ACCOUNT_TYPES.AGENT) {
      className += ' hidden';
    }
    if (masterOnly && !userInfo?.isMasterUser) {
      className += ' hidden';
    }
    if (corporatesOnly && !authStore.isCoraporatesInvestor) {
      className += ' hidden';
    }
    if (nonCorporatesOnly && authStore.isCoraporatesInvestor) {
      className += ' hidden';
    }
    if (adminOnly && !authStore.userInfo?.isCognitoAdmin) {
      className += ' hidden';
    }
    if (profilerOnly && !authStore.userInfo?.isCognitoProfiler) {
      className += ' disabled';
      disabled = true;
    }

    const listItemId = `mn-${idx}`;

    return (
      <li key={idx} className={className}>
        {disabled && (
          <div className="pseudo-link disabled" id={listItemId}>
            {name}{' '}
          </div>
        )}
        {!disabled && (
          <Link id={listItemId} to={href} onClick={() => utilsStore.setIsMobileHamburgerOpen()}>
            {name}{' '}
            {!!notificationsCount && (
              <div className="notifications-count">{notificationsCount}</div>
            )}
            {!notificationsCount && !!notificationDot && <div className="notifications-count" />}
            {!notificationsCount && !notificationDot && errorNotificationDot && (
              <div className="notifications-count error" />
            )}
          </Link>
        )}
        {disabled && !!profilerOnly && (
          <Tooltip
            anchorSelect={`#${listItemId}`}
            content="Please contact us for further information: info@transact.digital"
            multiline={true}
            variant="light"
            place="bottom-end"
            opacity={1}
            noArrow
          />
        )}
      </li>
    );
  };

  const showLoginItems =
    userVerificationStatus === ACCOUNT_VERIF_TYPES.APPROVED &&
    (userInfo?.isMasterUser ? hasUserProfileCompany : true);

  return (
    <nav className={`${utilsStore.isMobileHamburgerOpen ? 'opened' : 'closed'}`}>
      {state.isLogoutConfirmationOpen ? (
        <div className="confirm-logout">
          <MobileAppRefresher isFromMobileNav />
          <div className="confirmation-dialog-wrap">
            <div className="text">Do you really want to log out?</div>
            <div className="actions">
              <a
                to="#"
                className="btn btn-primary"
                onClick={(e) =>
                  e.preventDefault() &
                  state.setIsLogoutConfirmationOpen() &
                  window.scrollTo({ top: 0 })
                }>
                No, stay logged in
              </a>
              <a
                className="btn btn-outline"
                onClick={() =>
                  logout() &
                  state.setIsLogoutConfirmationOpen() &
                  utilsStore.setIsMobileHamburgerOpen()
                }>
                Yes, log me out
              </a>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div>
            <MobileAppRefresher isFromMobileNav />
            <ul>
              {showLoginItems && LOGIN_ITEMS.map(renderMenuItem)}
              {!showLoginItems && LOGOUT_ITEMS.map(renderMenuItem)}
            </ul>
          </div>
          {user && (
            <div className="logout">
              <a
                href="#"
                onClick={(e) =>
                  e.preventDefault() &
                  state.setIsLogoutConfirmationOpen(true) &
                  window.scrollTo({ top: 0 })
                }
                // id="logout-menu-button"
              >
                Log out
              </a>
            </div>
          )}
        </>
      )}
    </nav>
  );
});

export default MobileNavigation;
